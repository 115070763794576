<template>
  <div class="appraise">
    <div class="content">
      <div class="content-box">
        <p class="content-title">技能人才评价服务管理平台</p>
        <div class="content-box-inset">
          <img src="../assets/蒙版分组.png" alt="" />
          <div class="content-box-inset-right">
            技能人才评价服务管理平台，该平台可形成从评价信息公示、评价过程管理、评价结果数据交互、相关工作信息（文件）保存的全流程可追溯信息化数据链，并与省市监管系统进行数据交互，实现技能等级认定全过程来源可查、去向可追、责任可究。机构还可根据自身规模与管理需要，实现模块定制开发。
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-box-left">
          <!-- <img src="../assets/01.png" alt="" /> -->
          <p class="number">01</p>
          <p class="p1">考生在线服务平台</p>
          <p class="p2">
            为机构提供技能人才评价门户服务网站，用于机构面向考生提供：评价范围公示、评价计划公告、考生在线报名、考前在线复习、信息在线查询、考评结果公示、在线留言反馈、监督举报查询等相关服务功能。
          </p>
        </div>
        <img class="content-box-right" src="../assets/图层_2.png" alt="" />
      </div>
    </div>
    <div class="content content1">
      <div class="content-box">
        <img class="content-box-left1" src="../assets/02.png" alt="" />
        <div class="content-box-right1">
          <p class="number">02</p>
          <p class="p1">技能等级认定工作管理系统</p>
          <p class="p2">
            为机构规范开展职业技能等级认定工作提供工作管理、数据管理、信息化支持服务，提供职业技能等级认定工作一体化在线管理平台，实现评价计划编排、评价过程管理、评价结果数据提交、职业技能等级认定相关工作信息保存，形成可追溯信息化数据链，实现技能等级认定全过程来源可查、去向可追、责任可究。
          </p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-box-left2">
          <!-- <img src="../assets/01.png" alt="" /> -->
          <p class="number">03</p>
          <p class="p1">专项能力考核工作管理系统</p>
          <p class="p2">
            为机构规范开展专项职业能力考核工作提供工作管理、数据管理、信息化支持服务，提供专项能力考核工作一体化在线管理平台，实现考核计划编排、考核过程管理、考核结果数据提交、相关工作信息保存，形成可追溯信息化数据链，实现专项能力考核全过程来源可查、去向可追、责任可究。
          </p>
        </div>
        <img class="content-box-right2" src="../assets/03.png" alt="" />
      </div>
    </div>
    <div class="content content1">
      <div class="content-box content-box1 content-box2">
        <div class="content-box-box content-box-box1">
          <div class="content-box-left3-inset">
            <p class="number">04</p>
            <p class="p1">理论知识题库管理系统</p>
            <p class="p2">
              为提高机构理论知识题库规范水平和社会公信力，按照技能人才评价国家题库开发技术指导建立统一规范的技能人才评价理论知识题库管理系统，系统遵照国家职业技能标准或评价规范，科学整合理论知识鉴定要素，从而确保题库质量，保障技能人才评价工作的正常进行。
            </p>
            <img class="img1" src="../assets/04-主要功能.png" alt="" />
          </div>
        </div>
        <div class="content-box-box">
          <div class="content-box-left3-inset">
            <p class="number">05</p>
            <p class="p1">技能操作题库管理系统</p>
            <p class="p2">
              为提高机构技能操作题库规范水平和社会公信力，按照技能人才评价国家题库开发技术指导建立统一规范的技能人才评价技能操作题库管理系统，系统遵照国家职业技能标准或评价规范，科学整合技能操作鉴定要素，从而确保题库质量，保障技能人才评价工作的正常进行。
            </p>
            <img class="img2" src="../assets/05-主要功能.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-box-left">
          <!-- <img src="../assets/01.png" alt="" /> -->
          <p class="number">06</p>
          <p class="p1">理论知识在线考试系统</p>
          <p class="p2">
            为机构提供高效、安全、稳定的标准化全功能在线考试系统，助力于机构在确保评价工作质量的前提下，对于互联网、数字经济领域的职业（工种）以及其他知识技能型职业（工种），采取理论知识智能化机考，探索“互联网+人才评价”的新模式，为更多行业的服务标准化和人才评价体系建设注入新的活力，促进技能人才评价体系健康蓬勃发展。支持考生通过电脑、平板、手机等多终端设备参加考试，同时融合人脸识别、视频监控、AI等技术实现多样化的防作弊机制，对考生考试行为进行全面、智能化监控，从而提高技能人才评价工作效率和质量。
          </p>
        </div>
        <img class="content-box-right3" src="../assets/06.png" alt="" />
      </div>
    </div>
    <div class="content content1">
      <div class="content-box">
        <div class="content-box-msg">
          <p class="number">07</p>
          <p class="p1">技能操作考核在线评分系统</p>
          <p class="p2">
            技能操作考核在线评分系统是一款专为职业技能考核设计的软件系统，采用专业的从考核内容到要点定义的评分表并配合分档评分、结果评分、手动打分等多种方式。
          </p>
          <img class="img1" src="../assets/分组 33.png" alt="" />
          <img class="img2" src="../assets/分组 26-1.png" alt="" />
          <img class="img2" src="../assets/分组 26-2.png" alt="" />
          <img class="img2" src="../assets/分组 26-3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <img class="content-box-left4" src="../assets/08.png" alt="" />
        <div class="content-box-box">
          <div class="content-box-left3-inset">
            <p class="number">08</p>
            <p class="p1">理论知识考核在线阅卷系统</p>
            <p class="p2">
              用于机构面向阅卷老师提供理论知识上机考核主观题（简答题、论述题等）在线阅卷评分系统。
            </p>
            <img class="img3" src="../assets/08-表格.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-box-left">
          <!-- <img src="../assets/01.png" alt="" /> -->
          <p class="number">09</p>
          <p class="p1">远程在线监考系统</p>
          <p class="p2">
            远程在线监考系统是一款专为各种考试而设计的智能化远程监考系统，无论是传统学校的笔试，各种职业资格证书的机考，社会教师资格证一类的面试等均可实现监考，功能强大。具有视频监控墙，人脸识别防代考，视频处理，远程巡考、考试动态点播回放等功能。旨在为每场考试提供公平，公开，公正的考试环境，它简单易用，一部手机即可随时随地轻松完成监考任务。+人才评价”的新模式，为更多行业的服务标准化和人才评价体系建设注入新的活力，促进技能人才评价体系健康蓬勃发展。支持考生通过电脑、平板、手机等多终端设备参加考试，同时融合人脸识别、视频监控、AI等技术实现多样化的防作弊机制，对考生考试行为进行全面、智能化监控，从而提高技能人才评价工作效率和质量。
          </p>
        </div>
        <img class="content-box-right4" src="../assets/09.png" alt="" />
      </div>
    </div>
    <div class="content content2">
      <div class="content-box">
        <div class="content-box-msg content-box-msg1">
          <p class="number">10</p>
          <p class="p1">身份信息在线核验系统</p>
          <p class="p2">
            为提高技能人才评价工作质量，面向考生及考评员提供身份信息在线核验系统，核验记录系统将自动记录并同步至考务平台中。
          </p>
          <img class="img4" src="../assets/分组 35.png" alt="" />
          <img class="img5" src="../assets/10-2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Appraise",
};
</script>
<style scoped>
.content {
  /* width: 100%; */
  /* padding: 0 10%; */
  min-width: 1280px;
}
.content .content-box {
  /* width: 100%;
  min-width: 860px; */
  width: 1280px;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.content .content-box1 {
  width: 100%;
}
.content .content-box2 {
  padding: 0;
  margin: 60px 0;
}
.content .content-box .content-title {
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
}
.jianjie-title {
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
  color: rgba(211, 26, 26, 1);
}
.content .content-box .content-box-inset {
  display: flex;
}
.content .content-box .content-box-inset img {
  width: 580px;
  height: 380px;
}
.content .content-box .content-box-inset .content-box-inset-right {
  padding-top: 17px;
  width: 580px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-indent: 2em;
  line-height: 28px;
  margin-left: 40px;
}
.content-box-left {
  width: 580px;
  position: relative;
  /* margin-right: 40px; */
  margin-bottom: 50px;
}
.content-box-left img {
  position: absolute;
  left: 0;
  top: 0;
  width: 113px;
  height: 100px;
}
.number {
  font-size: 100px;
  font-weight: 700;
  color: transparent;
  background: linear-gradient(
    180deg,
    rgba(34, 101, 231, 0.3) 0%,
    rgba(35, 101, 232, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  position: absolute;
  left: 0;
  top: 0;
}
.content-box .p1 {
  font-size: 36px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  margin: 30px 0 50px 0;
}
.content-box .p2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
}
.content-box-right {
  margin-top: 100px;
  width: 580px;
  height: 200px;
}
.content-box-left1 {
  width: 580px;
  height: 380px;
  /* margin-right: 40px; */
}
.content-box-right1 {
  width: 580px;
  position: relative;
  margin-left: 40px;
}
.content1 {
  background: rgba(247, 250, 255, 1);
}
.content-box-left2 {
  width: 580px;
  position: relative;
  /* margin-right: 40px; */
}
.content-box-right2 {
  width: 580px;
  height: 460px;
  margin-left: 40px;
}
.content-box-box {
  width: calc(50% - 120px);
  display: flex;
  justify-content: flex-start;
  padding: 60px 60px;
}
.content-box-box1 {
  background: url(../assets/分组\ 25.png) no-repeat;
  background-size: 100% 600px;
  justify-content: flex-end;
}
.content-box-box1 .p1,
.content-box-box1 .p2 {
  color: #fff;
}
.content-box-box1 .number {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.content-box-box .content-box-left3-inset {
  width: 580px;
  position: relative;
}
.content-box-box .content-box-left3-inset .img1 {
  width: 500px;
  height: 183px;
  margin-top: 20px;
}
.content-box-box .content-box-left3-inset .img2 {
  width: 500px;
  height: 141px;
  margin-top: 20px;
}
.content-box-right3 {
  width: 580px;
  height: 289px;
}
.content-box-msg {
  width: 1000px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.content-box-msg .img1 {
  width: 1000px;
  height: 150px;
  margin-top: 30px;
}
.content-box-msg .img2 {
  width: 1000px;
  height: 80px;
  margin-top: 30px;
}
.content-box-msg .p1 {
  width: 100%;
  text-align: left;
}
.content-box-msg .p2 {
  width: 100%;
  text-align: left;
}
.content-box-msg .p2 {
  white-space: nowrap;
}
.content-box-left4 {
  width: 580px;
  height: 320px;
}
.img3 {
  width: 500px;
  height: 150px;
  margin-top: 20px;
}
.content-box-right4 {
  width: 580px;
  height: 360px;
}
.content2 {
  background: url(../assets/10-bg.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-msg1 {
  position: relative;
  padding-bottom: 160px;
}
.img4 {
  width: 1000px;
  height: 370px;
  margin-top: 60px;
}
.img5 {
  position: absolute;
  bottom: 0;
  right: -50px;
  width: 458px;
  height: 340px;
}
</style>
