<template>
  <div class="mobileProduct">
    <div class="content content-margin">
      <p class="jianjie-title">技能人才评价服务管理平台</p>
      <img class="content-img" src="../assets/技能人才评价.png" alt="" />
      <p class="titile-p2 title-margin">
        技能人才评价服务管理平台，该平台可形成从评价信息公示、评价过程管理、评价结果数据交互、相关工作信息（文件）保存的全流程可追溯信息化数据链，并与省市监管系统进行数据交互，实现技能等级认定全过程来源可查、去向可追、责任可究。机构还可根据自身规模与管理需要，实现模块定制开发。
      </p>
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">01</p>
        <p class="num-title1">考生在线服务平台</p>
      </div>
      <p class="num-title2">
        为机构提供技能人才评价门户服务网站，用于机构面向考生提供：评价范围公示、评价计划公告、考生在线报名、考前在线复习、信息在线查询、考评结果公示、在线留言反馈、监督举报查询等相关服务功能。
      </p>
      <img class="num-img" src="../assets/image.png" alt="" />
    </div>
    <div class="content content-margin content3">
      <div class="content-num">
        <p class="number">02</p>
        <p class="num-title1">技能等级认定工作管理系统</p>
      </div>
      <p class="num-title2">
        为机构规范开展职业技能等级认定工作提供工作管理、数据管理、信息化支持服务，提供职业技能等级认定工作一体化在线管理平台，实现评价计划编排、评价过程管理、评价结果数据提交、职业技能等级认定相关工作信息保存，形成可追溯信息化数据链，实现技能等级认定全过程来源可查、去向可追、责任可究。
      </p>
      <img class="num-img1" src="../assets/image1.png" alt="" />
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">03</p>
        <p class="num-title1">专项能力考核工作管理系统</p>
      </div>
      <p class="num-title2">
        为机构规范开展专项职业能力考核工作提供工作管理、数据管理、信息化支持服务，提供专项能力考核工作一体化在线管理平台，实现考核计划编排、考核过程管理、考核结果数据提交、相关工作信息保存，形成可追溯信息化数据链，实现专项能力考核全过程来源可查、去向可追、责任可究。
      </p>
      <img class="num-img2" src="../assets/image2.png" alt="" />
    </div>
    <div class="content content-margin content4">
      <div class="content-num">
        <p class="number">04</p>
        <p class="num-title1">理论知识题库管理系统</p>
      </div>
      <p class="num-title2">
        为提高机构理论知识题库规范水平和社会公信力，按照技能人才评价国家题库开发技术指导建立统一规范的技能人才评价理论知识题库管理系统，系统遵照国家职业技能标准或评价规范，科学整合理论知识鉴定要素，从而确保题库质量，保障技能人才评价工作的正常进行。
      </p>
      <img class="num-img3" src="../assets/image4.png" alt="" />
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">05</p>
        <p class="num-title1">技能操作题库管理系统</p>
      </div>
      <p class="num-title2">
        为提高机构技能操作题库规范水平和社会公信力，按照技能人才评价国家题库开发技术指导建立统一规范的技能人才评价技能操作题库管理系统，系统遵照国家职业技能标准或评价规范，科学整合技能操作鉴定要素，从而确保题库质量，保障技能人才评价工作的正常进行。
      </p>
      <img class="num-img3" src="../assets/image5.png" alt="" />
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">06</p>
        <p class="num-title1">理论知识在线考试系统</p>
      </div>
      <p class="num-title2">
        为机构提供高效、安全、稳定的标准化全功能在线考试系统，助力于机构在确保评价工作质量的前提下，对于互联网、数字经济领域的职业（工种）以及其他知识技能型职业（工种），采取理论知识智能化机考，探索“互联网+人才评价”的新模式，为更多行业的服务标准化和人才评价体系建设注入新的活力，促进技能人才评价体系健康蓬勃发展。支持考生通过电脑、平板、手机等多终端设备参加考试，同时融合人脸识别、视频监控、AI等技术实现多样化的防作弊机制，对考生考试行为进行全面、智能化监控，从而提高技能人才评价工作效率和质量。
      </p>
      <img class="num-img4" src="../assets/image6.png" alt="" />
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">07</p>
        <p class="num-title1">技能操作考核在线评分系统</p>
      </div>
      <p class="num-title2">
        技能操作考核在线评分系统是一款专为职业技能考核设计的软件系统，采用专业的从考核内容到要点定义的评分表并配合分档评分、结果评分、手动打分等多种方式。
      </p>
      <img class="num-img5" src="../assets/image7.png" alt="" />
      <div class="content-num-flex">
        <div class="content-num-flex-box">
          <div class="content-num-flex-box-top content-num-flex-box-top1">
            <img src="../assets/可靠.png" alt="" />
            <p>更可靠</p>
          </div>
          <div class="content-num-flex-box-bottom">
            评分更可靠，灵活定义考评组和考评任务，可实时监控任务进度、查看考察结果，各端协同工作；
          </div>
        </div>
        <div class="content-num-flex-box">
          <div class="content-num-flex-box-top content-num-flex-box-top2">
            <img src="../assets/公平.png" alt="" />
            <p>更公平</p>
          </div>
          <div class="content-num-flex-box-bottom">
            评分更公平，可手动设定各端评分差值，超出差值范围退回重评；
          </div>
        </div>
        <div class="content-num-flex-box">
          <div class="content-num-flex-box-top content-num-flex-box-top3">
            <img src="../assets/智能.png" alt="" />
            <p>更智能</p>
          </div>
          <div class="content-num-flex-box-bottom">
            <div class="content-num-flex-box-bottom-text">
              评分更智能，考后智能结果分析，配套现场考务app和手机评分app，清晰考生各个考核点分值差值，实时通知考评任务，随时随地参与测评，助力于机构探索全程在线开展评价认定的新模式。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">08</p>
        <p class="num-title1">理论知识考核在线阅卷系统</p>
      </div>
      <p class="num-title2">
        用于机构面向阅卷老师提供理论知识上机考核主观题（简答题、论述题等）在线阅卷评分系统。
      </p>
      <img class="num-img6" src="../assets/image8.png" alt="" />
      <img class="num-img7" src="../assets/image9.png" alt="" />
    </div>
    <div class="content content-margin">
      <div class="content-num">
        <p class="number">09</p>
        <p class="num-title1">远程在线监考系统</p>
      </div>
      <p class="num-title2">
        远程在线监考系统是一款专为各种考试而设计的智能化远程监考系统，无论是传统学校的笔试，各种职业资格证书的机考，社会教师资格证一类的面试等均可实现监考，功能强大。具有视频监控墙，人脸识别防代考，视频处理，远程巡考、考试动态点播回放等功能。旨在为每场考试提供公平，公开，公正的考试环境，它简单易用，一部手机即可随时随地轻松完成监考任务。
      </p>
      <img class="num-img8" src="../assets/image10.png" alt="" />
    </div>
    <div class="content content-margin content5">
      <div class="content-num">
        <p class="number">10</p>
        <p class="num-title1">身份信息在线核验系统</p>
      </div>
      <p class="num-title2">
        为提高技能人才评价工作质量，面向考生及考评员提供身份信息在线核验系统，核验记录系统将自动记录并同步至考务平台中。
      </p>
      <img class="num-img9" src="../assets/image11.png" alt="" />
      <img class="num-img10" src="../assets/image12.png" alt="" />
      <img class="num-img11" src="../assets/image14.png" alt="" />
    </div>
  </div>
</template>
<script>
import { GetConnection } from "../api/home";
export default {
  name: "MobileAppraise",
  data() {
    return {
      active: 0,
      active1: 0,
      activeName: "1",
      active2: 0,
      scrollList: [],
      scrollList1: [],
      tabActive: true,
      activeName1: "1",
      activeName2: "1",
    };
  },
  methods: {
    goToProduct() {
      this.$router.push("/mobileProduct?active=" + this.active);
    },
    onClick(name, title) {
      this.activeName = "1";
    },
    getData() {
      GetConnection()
        .then((res) => {
          this.scrollList = res;
          // this.scrollList1 = res
        })
        .catch((err) => {});
    },
  },
  mounted() {
    if (this.$route.query.active) {
      this.active = Number(this.$route.query.active);
    }
    this.getData();
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
    defaultOption1() {
      return {
        step: 1,
        limitMoveNum: this.scrollList1.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
.header-bg {
  width: 100%;
  height: 200px;
  background: url(../assets/产品服务-bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}
.top >>> .van-tabs__nav--card {
  /* height: 40px; */
  margin: 0;
  border: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab {
  background: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border-right: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab.van-tab--active {
  background: rgba(227, 25, 25, 1);
}
.content {
  /* width: 100%; */
  padding: 0 16px;
}
.content .content-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin: 20px 0;
}
.content .content-title1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  margin-bottom: 30px;
  line-height: 28px;
}
.content .content-title2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0 0 10px 0;
}
.content .content-img {
  width: 100%;
  height: 225px;
  margin-bottom: 10px;
}
.content .content-title3 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 21px;
  margin: 0;
}
.content .titile-p1 {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin: 20px 0;
}
.jianjie-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
  color: rgba(211, 26, 26, 1);
}
.content .titile-p2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  text-indent: 2em;
}
.tab-content {
  width: 100%;
  height: 176px;
  position: relative;
}
.tab-content .img {
  position: absolute;
  width: 100%;
  /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
  z-index: 1;
}
.tab-content .img1 {
  position: absolute;
  width: 100%;
  /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
  z-index: 2;
}
.tab-content .tab-content-title {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
  color: #fff;
}
.tab-content .tab-content-title .p1 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.tab-content .tab-content-title .tab-content-msg {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  margin-right: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out .point-inset {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
.hexin >>> .van-cell__title div {
  display: flex;
  align-items: center;
}
.hexin >>> .van-cell__title div img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.gongneng >>> .van-tabs__line {
  width: 100px;
}
.content-flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-flex .content-flex-box {
  width: calc(48% - 20px);
  /* width: 145px; */
  height: 200px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #ccc;
}
.content-flex .content-flex-box img {
  width: 70px;
  height: 70px;
}
.content-flex .content-flex-box .p1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-flex .content-flex-box .p2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content1 {
  height: 200px;
  padding: 30px 16px;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-line {
  width: calc(100% - 32px);
  margin: 0 auto;
  height: 110px;
  background: #fff;
  margin-top: -55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-line-inset {
  /* width: calc(100% - 160px);
    height: 260px; */
  width: calc(100% - 24px);
  height: 65%;
}
.content-box {
  width: calc(100% - 32px);
  margin: 0 auto;
}
.content-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-box .content-box-inset {
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
}
.content-box .content-box-inset img {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.content-box .content-box-inset .content-box-inset-right {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-box .content-box-inset .content-box-inset-right .p1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-box .content-box-inset .content-box-inset-right .p2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
}
.content2 {
  /* height: 500px; */
  padding: 30px 16px;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 10px 0;
  background: #fff;
}
.content-tab .content-tab-box {
  width: 33.33%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-tab .content-tab-box1 {
  width: 50%;
}
.content-tab .content-tab-box img {
  width: 45px;
  height: 45px;
}
.content-tab .content-tab-box .p1 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-top: 14px;
  margin-bottom: 10px;
}
.content-tab .content-tab-box .p2 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content-tab2 {
  /* height: 260px; */
  padding: 30px 16px 10px 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content-tab2 .content-tab-box {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-tab2 .content-tab-box img {
  width: 18px;
  height: 18px;
  margin-right: 16px;
}
.content-tab2 .content-tab-box .p3 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.baozhang >>> .van-tabs__nav {
  background-color: rgba(0, 0, 0, 0);
}
.baozhang >>> .van-tab__text {
  font-size: 14px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}
.baozhang >>> .van-tabs__line {
  width: 60px;
  background-color: #fff;
}
.scroll {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  min-width: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  width: 132px;
  /* height: 80px; */
  height: 98px;
  position: relative;
  margin-right: 10px;
}
.partner-box .partner-box-flex .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 132px;
  height: 73px;
  z-index: 1;
}
.partner-box .partner-box-flex .img1 {
  position: absolute;
  top: 5px;
  left: 10px;
  /* width: 110px;
    height: 40px; */
  width: 112px;
  height: 63px;
  z-index: 2;
}
.partner-box .partner-box-flex p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.content-num {
  position: relative;
}
.title-margin {
  margin-bottom: 20px;
}
.content-margin {
  margin-top: 30px;
}
.content .num-title1 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 70px;
}
.content .num-title2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
}
.content-num .number {
  font-size: 60px;
  font-weight: 700;
  color: transparent;
  background: linear-gradient(
    180deg,
    rgba(34, 101, 231, 0.3) 0%,
    rgba(35, 101, 232, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  position: absolute;
  left: 0;
  top: 0;
}
.content .num-img {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content3 {
  background: rgba(247, 250, 255, 1);
  padding: 20px 16px;
}
.content .num-img1 {
  width: 100%;
  height: 225px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img2 {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img3 {
  width: 100%;
  height: 135px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content4 {
  padding: 20px 16px;
  background: url(../assets/image3.png) no-repeat;
  background-size: 100% 100%;
}
.content4 .number {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.content4 .num-title1,
.content4 .num-title2 {
  color: #fff;
}
.content .num-img4 {
  width: 100%;
  height: 170px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img5 {
  width: 100%;
  height: 55px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content-num-flex {
  margin-bottom: 10px;
  height: 240px;
  display: flex;
  justify-content: space-between;
}
.content-num-flex .content-num-flex-box {
  width: 30%;
  height: 240px;
  box-shadow: 0px 0px 6px 0px rgba(56, 128, 195, 0.25);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top img {
  width: 30px;
  height: 30px;
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top1 {
  background: rgba(144, 189, 53, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top2 {
  background: rgba(222, 161, 49, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top3 {
  background: rgba(67, 184, 164, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-bottom {
  width: calc(100% - 20px);
  height: 140px;
  padding: 10px;
}
.content-num-flex
  .content-num-flex-box
  .content-num-flex-box-bottom
  .content-num-flex-box-bottom-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content .num-img6 {
  width: 100%;
  height: 110px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img7 {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img8 {
  width: 100%;
  height: 220px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img9 {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img10 {
  width: 100%;
  height: 260px;
  margin-top: 10px;
}
.content .num-img11 {
  width: 100%;
  height: 130px;
  margin-top: -100px;
}
.content5 {
  padding: 20px 16px 0 16px;
  background: url(../assets/image13.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 5px);
  height: 60px;
  /* margin-right: 10px; */
  border-radius: 8px;
  background: rgba(247, 250, 255, 1);
  margin-bottom: 40px;
}
.content-box-tab:hover {
  cursor: pointer;
}
.content-box-tab img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.content-box-tab p {
  font-size: 16px;
  font-weight: 700;
  color: rgba(102, 102, 102, 1);
}
.content-box-tab-active {
  position: relative;
  background: url(../assets/蒙版分组-选中.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab-active p {
  color: #fff;
}
.content-box-tab-active:before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  content: "";
  border-color: rgba(136, 183, 213, 0);
}
.content-box-tab-active.up:before {
  border-top-color: rgba(7, 79, 224, 1);
  border-width: 12px;
  left: 49%;
  margin-left: -10px;
  top: 100%;
}
.content .content-box2 {
  width: 100%;
  padding-top: 20px;
  background: rgba(247, 250, 255, 1);
}
.content .content-box2 img {
  width: 100%;
  height: 250px;
  margin-top: 10px;
}
.content .content-box2 .content-box-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .content-box2 .content-box-right .content-box-right-tag {
  background: rgba(34, 101, 231, 1);
  width: 90px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.content .content-box2 .content-box-right .content-box-right-title {
  margin: 10px 0 15px 0;
  font-size: 24px;
  font-weight: 500;
  color: rgba(34, 101, 231, 1);
}
.content .content-box2 .content-box-right .content-box-right-msg {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 28px;
}
.tab-content1 {
  width: calc(100% - 24px);
  height: 260px;
  padding: 20px 12px;
  background: url(../assets/监管平台-bg.png) no-repeat;
  background-size: 100% 100%;
}
.tab-content1 .tab-content1-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tab-content1 .tab-content1-title img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.tab-content1 .tab-content1-title p {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.tab-content1 .tab-content1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.content6 {
  margin-top: 30px;
  margin-bottom: 100px;
  width: 100%;
  height: 540px;
  background: url(../assets/jianguan.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab1 {
  padding: 20px;
}
.content-tab1 .content-tab1-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.content-tab1 .content-tab1-title img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.content-tab1 .content-tab1-title p {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.content-tab1 .content-tab1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.positionImg {
  width: 100%;
  height: 300px;
  margin-top: 50px;
}
.positionImg1 {
  width: 100%;
  height: 300px;
  margin-top: 50px;
}
.positionImg2 {
  width: 100%;
  height: 300px;
}
.content .img-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
}
.content .img-box .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content .img-box .img-box-inset img {
  width: 50px;
  height: 50px;
}
.content .img-box .img-box-inset p {
  font-size: 16px;
  line-height: 56px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
}
.content .img-box1 .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  /* align-items: center; */
  /* width: 165px; */
  width: 48%;
  height: 214px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.content .img-box1 .img-box-inset img {
  /* width: 165px; */
  width: 100%;
  height: 134px;
}
.content .img-box1 .img-box-inset .p {
  padding: 0 12px;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box1 .img-box-inset .p1 {
  padding: 0 12px;
  font-size: 10px;
  color: rgba(153, 153, 153, 1);
}
.content .img-box1 .img-box-inset .mark {
  display: flex;
  padding: 0 12px;
  margin: 10px 0;
  align-items: center;
}
.content .img-box1 .img-box-inset .mark .mark-left {
  width: 15px;
  height: 3px;
  background: rgba(228, 30, 30, 1);
}
.content .img-box1 .img-box-inset .mark .mark-right {
  width: 15px;
  height: 3px;
  background: rgba(51, 51, 51, 1);
}
.shixun-box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shixun-box .shixun-img {
  width: 343px;
  height: 184px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.shixun-box .shixun-img1 {
  width: 343px;
  height: 240px;
}
.shixun-box .shixun-inset .shixun-top {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.shixun-box .shixun-inset .shixun-middle {
  font-size: 14px;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  margin: 15px 0;
}
.shixun-box .shixun-inset .shixun-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box {
  width: 108px;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(255, 240, 240, 1) 0%,
    rgba(255, 240, 240, 0.01) 100%
  );
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box img {
  width: 14px;
  height: 14px;
  margin: 6px;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box p {
  font-size: 12px;
  color: rgba(51, 51, 51, 1);
}
.flex-box {
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.flex-box .flex-box-inset {
  margin-top: 20px;
  padding-bottom: 12px;
  width: 48%;
  height: 204px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-box .flex-box-inset .p1 {
  align-self: start;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
  color: rgba(51, 51, 51, 1);
}
.flex-box .flex-box-inset .p2 {
  align-self: start;
  padding: 0 12px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
}
.flex-box .flex-box-inset img {
  width: calc(100% - 24px);
  height: 113px;
}
</style>
