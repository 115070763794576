export default {
  init: function () {
    console.log("初始化百度地图第三方组件脚本...");
    const BMapLib_URL =
      "http://api.map.baidu.com/library/SearchInfoWindow/1.5/src/SearchInfoWindow_min.js";
    return new Promise((resolve, reject) => {
      if (typeof BMapLib !== "undefined") {
        resolve(BMapLib);
        return true;
      }

      // 插入script脚本
      let scriptNode = document.createElement("script");
      scriptNode.setAttribute("type", "text/javascript");
      scriptNode.setAttribute("src", BMapLib_URL);
      document.body.appendChild(scriptNode);

      // 等待页面加载完毕回调
      let timeout = 0;
      let interval = setInterval(() => {
        // 超时10秒加载失败
        if (timeout >= 20) {
          reject();
          clearInterval(interval);
          console.error("百度地图第三方组件脚本初始化失败...");
        }
        // 加载成功
        if (typeof BMapLib !== "undefined") {
          resolve(BMapLib);
          clearInterval(interval);
          console.log("百度地图第三方组件脚本初始化成功...");
        }
        timeout += 1;
      }, 500);
    });
  },
};
