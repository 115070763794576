<template>
  <div class="mobile">
    <div class="logo-box">
      <img class="logo-img" src="../assets/logo.png" alt="" />
      <img
        v-if="!show"
        class="logo-img1"
        src="../assets/导航列表.png"
        @click="show = true"
        alt=""
      />
    </div>
    <template v-if="$route.path == '/mobile'">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in slide" :key="index">
          <!-- <div class="logo-title">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
          </div> -->
          <img :src="item.img" />
        </van-swipe-item>
      </van-swipe>
      <div class="content">
        <div class="content-title">
          <p class="p1">资讯动态</p>
          <p class="p2">
            专注于职业技能领域数字化信息服务，致力于成为一流的职业技能领域数字信息化综合服务商
          </p>
        </div>
        <div
          class="content-box"
          v-for="(item, index) in zixunData"
          :key="index"
          @click="goToNewsDetail(item)"
        >
          <img
            v-if="item.newsPict"
            :src="$path.path + '/src/newsInfoUploadAddress/' + item.newsPict"
            alt=""
          />
          <img v-else src="../assets/资讯动态.png" alt="" />
          <p class="p1">
            {{ item.newsTitle }}
          </p>
          <p class="p2">{{ item.newsTime }}</p>
          <p class="p3">
            {{ item.newsdisc }}
          </p>
        </div>
        <div class="content-btn" @click="$router.push('/mobileNews')">
          全部资讯
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <p class="p1">客户案例</p>
          <p class="p2">给客户带去价值、为员工搭建平台、为社会促进发展</p>
        </div>
        <div
          class="content-box content-box1"
          v-for="(item, index) in kehuData"
          :key="index"
          @click="goToCustomDetail(item)"
        >
          <img
            v-if="item.cainPict"
            :src="
              $path.path + '/src/customerEtcFile/' + item.cainPict.split(',')[0]
            "
            alt=""
          />
          <img v-else src="../assets/客户案例默认图片.png" alt="" />
          <p class="p1">{{ item.cainTitle }}</p>
          <p class="p4">
            {{ item.cainContent }}
          </p>
        </div>
        <div class="content-btn" @click="$router.push('/mobileCustom')">
          全部案例
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <p class="p1">产品服务</p>
          <p class="p2">专注、聚焦、深耕</p>
        </div>
        <div class="content-flex">
          <div
            class="content-flex-box"
            ref="animationP11"
            v-for="(item, index) in chanpinData"
            :key="index"
          >
            <img
              :src="$path.path + '/src/productPhoto/' + item.prodPhoto"
              alt=""
            />
            <div class="mark">
              <div class="mark-title">{{ item.prodName }}</div>
              <div class="mark-content">
                {{ item.prodContent }}
              </div>
            </div>
          </div>
          <!-- <div class="content-flex-box" ref="animationP11">
            <div class="mark">
              <div class="mark-title">在线报名缴费系统</div>
              <div class="mark-content">
                为参加培训考试的学员和机构提供在线报名通道，提供网上报名、网上资格审核和网上缴费等服务，让报名工作更加方便快捷、信息更加准确无误。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box1" ref="animationP12">
            <div class="mark">
              <div class="mark-title">在线培训学习系统</div>
              <div class="mark-content">
                线上学习平台帮助机构融合线上+线下，打造测-学-练-考-评的学习闭环，创新高效的学习模式，加强学员对知识的沉淀、管理、传播和创新。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box2" ref="animationP13">
            <div class="mark">
              <div class="mark-title">考务工作管理系统</div>
              <div class="mark-content">
                通过规范管理考前、考中、考后相关工作和流程，从而减轻管理人员工作负担，降低考务管理成本，提高考务工作的整体信息化管理水平。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box3" ref="animationP14">
            <div class="mark">
              <div class="mark-title">在线题库管理系统</div>
              <div class="mark-content">
                按照国家题库开发规程和各职业技能标准的要求，通过测量学原理，建立理论知识和技能操作考试资源库，对职业方向、知识结构、考题内容和组卷模型全方位的管理与命题服务，同时满足无纸化考试和传统考试的需求。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box4" ref="animationP15">
            <div class="mark">
              <div class="mark-title">智能化在线考试系统</div>
              <div class="mark-content">
                提供跨平台、跨系统的智能化考试服务，能进行PC客户端考试、浏览器考试和手机考试等多种模式，同时提供人脸识别、摄像监考、锁屏防切屏、打乱题序和选项等一系列防舞弊方案让考试更安全，满足各种常见考试需求。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box5" ref="animationP16">
            <div class="mark">
              <div class="mark-title">在线监考控制系统</div>
              <div class="mark-content">
                基于移动智能摄像头实现移动监考，只需要一个摄像头，现场配置网络即可实现视频监考，考试组织方通过监控墙，监考App即可实时监控考试过程，并对缺考，舞弊等考试情况进行记载，拍照，并在考试过程中控制摄像头云台，景深等更好地监控考试现场，保障考试公平公正。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box6" ref="animationP17">
            <div class="mark">
              <div class="mark-title">在线阅卷评分系统</div>
              <div class="mark-content">
                通过在线阅卷系统建立阅卷专家组，分配专家组阅卷任务，监控阅卷专家组阅卷进度以及评卷结果，指定阅卷评分规则、超出差值许可的处理方式以及计算阅卷最终成绩的多种计算规则，让评卷结果公平，公正，可追溯。
              </div>
            </div>
          </div>
          <div class="content-flex-box content-flex-box7" ref="animationP18">
            <div class="mark">
              <div class="mark-title">数据分析预警系统</div>
              <div class="mark-content">
                依托大屏展示设备，通过大屏的可视化展示，将相关业务分析结果数据进行抽取并利用
                GIS、图表等技术进行直观、动态的大屏可视化展现，为领导分析决策、指挥调度提供可视化、智能化提供支持。
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <p class="p1">关于我们</p>
          <p class="p2">
            专注于职业技能领域数字信息化服务，立志于成为一流的职业技能领域数字信息化综合服务商、最大限度激发和释放人才活力。
          </p>
        </div>
        <div class="about-box">
          <!-- <img
            v-if="detail.infoLogo"
            ref="scaleImg"
            :src="$path.path + '/src/sysImage/' + detail.infoLogo"
            alt=""
          /> -->
          <img ref="scaleImg" src="../assets/公司简介.png" alt="" />
          <p class="p1">
            <!-- 重庆鸿新在线信息技术有限公司 -->
            {{ detail.infoName }}
          </p>
          <div class="content-info-right-mark"></div>
          <!-- 重庆鸿新在线信息技术有限公司是国内领先的职业技能领域数字信息化服务提供商，公司创立于2021年，是一家拥有自主知识产权的软件研发企业，公司致力于为重庆鸿新在线信息技术有限公司是国内领先的职业技能领域数字信息化服务提供商，公司创立于2021年，是一家拥有自主知识产权的软件研发企业，公司致力于为 -->
          <div class="p2" v-html="detail.infoProfile"></div>
        </div>
        <div class="content-btn" @click="$router.push('/mobileAbout')">
          了解更多
        </div>
      </div>
      <div class="content content1">
        <div class="content-title">
          <p class="p1">行业领先的职业技能领域数字信息化综合服务商</p>
        </div>
        <div class="contact-box">
          <div class="contact-box-inset">
            <div class="contact-box-inset-title">500+</div>
            <div class="contact-box-inset-title1">服务机构</div>
          </div>
          <div class="contact-box-inset">
            <div class="contact-box-inset-title">5000+<span>场次</span></div>
            <div class="contact-box-inset-title1">累计考试</div>
          </div>
          <div class="contact-box-inset">
            <div class="contact-box-inset-title">500000+<span>人次</span></div>
            <div class="contact-box-inset-title1">累计服务</div>
          </div>
          <div class="content-btn" @click="$router.push('/mobileContact')">
            联系我们
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <p class="p1">合作伙伴</p>
          <p class="p2">客户第一、团队合作、敬业创新、乐于分享</p>
        </div>
        <vue-seamless-scroll
          v-if="scrollList.length >= 1"
          :data="scrollList"
          :class-option="defaultOption"
          class="scroll"
        >
          <ul class="partner-box">
            <li
              class="partner-box-flex"
              v-for="(item, index) in scrollList"
              :key="index"
            >
              <img class="img" src="../assets/矩形 1.png" alt="" />
              <img
                class="img1"
                v-if="item.connLogo"
                :src="$path.path + '/src/customerFile/' + item.connLogo"
                alt=""
              />
              <p>{{ item.connName }}</p>
            </li>
          </ul>
        </vue-seamless-scroll>
        <!-- <vue-seamless-scroll
          v-if="scrollList.length >= 1"
          :data="scrollList"
          :class-option="defaultOption"
          class="scroll"
        >
          <ul class="partner-box">
            <li
              class="partner-box-flex"
              v-for="(item, index) in scrollList1"
              :key="index"
            >
              <img class="img" src="../assets/矩形 1.png" alt="" />
              <img
                class="img1"
                v-if="item.connLogo"
                :src="$path.path + '/src/customerFile/' + item.connLogo"
                alt=""
              />
              <p>{{ item.connName }}</p>
            </li>
          </ul>
        </vue-seamless-scroll> -->
      </div>
    </template>
    <router-view v-else />
    <div class="content content2 bottom-content">
      <div class="content-box">
        <div class="footer-box">
          <div class="footer-box-left">
            <div class="footer-box-left-box">
              <img src="../assets/地址.png" alt="" />
              <p>联系地址：{{ detail.infoAddress }}</p>
            </div>
            <div class="footer-box-left-box">
              <img src="../assets/热线.png" alt="" />
              <p>联系热线：{{ detail.infoPhone }}</p>
            </div>
            <div class="footer-box-left-box">
              <img src="../assets/邮箱.png" alt="" />
              <p>联系邮箱：{{ detail.infoEmail }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-box1">
        <p>备案号： {{ detail.infoRecord }}</p>
        <p>
          <img
            src="../assets/渝公网安备.png"
            alt=""
          />渝公网安备：50010702504858号
        </p>
      </div>
    </div>
    <van-popup
      v-model="show"
      closeable
      position="right"
      :style="{ height: '100%', width: '60%' }"
    >
      <div class="nav-list">
        <div class="nav-box" @click="goToPage('/mobile')">首页</div>
        <div class="nav-box" @click="goToPage('/mobileNews')">资讯动态</div>
        <!-- <div class="nav-box" @click="goToPage('/mobileProduct')">产品服务</div> -->
        <van-collapse class="nav" v-model="activeNames">
          <van-collapse-item title="产品服务" name="1">
            <div class="nav-box" @click="goToPage('/mobileCultivate')">
              技能培训
            </div>
            <div class="nav-box" @click="goToPage('/mobileAppraise')">
              考务平台
            </div>
            <div class="nav-box" @click="goToPage('/mobileSupervise')">
              监管平台
            </div>
            <div class="nav-box" @click="goToPage('/mobileKaowuFuwu')">
              考务服务
            </div>
            <div class="nav-box" @click="goToPage('/mobileYingjian')">
              硬件及耗材
            </div>
            <div class="nav-box" @click="goToPage('/mobileShixun')">
              智能实训
            </div>
          </van-collapse-item>
        </van-collapse>
        <div class="nav-box" @click="goToPage('/mobileCustom')">客户案例</div>
        <div class="nav-box" @click="goToPage('/mobileAbout')">关于我们</div>
        <div class="nav-box" @click="goToPage('/mobileContact')">联系我们</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  SelectPublicityPictures,
  GetNewsInfo,
  GetCaseInfo,
  GetConnection,
  GetProductList,
  GetSystemManger,
  UpdateNewsInfoTotal,
  UpdateCaseInfoTotal,
} from "../api/home";
export default {
  name: "Mobile",
  data() {
    return {
      show: false,
      activeNames: [],
      slide: [],
      scrollList: [],
      scrollList1: [],
      zixunData: [],
      kehuData: [],
      chanpinData: [],
      detail: {},
    };
  },
  methods: {
    goToPage(path) {
      this.show = false;
      this.$router.push(path);
    },
    goToNewsDetail(item) {
      let data = {
        newsIdJson: this.$path.Encrypt(item.newsId),
      };
      UpdateNewsInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/mobileNewsDetail",
            query: { newsId: item.newsId },
          });
        })
        .catch((err) => {});
    },
    goToCustomDetail(item) {
      let data = {
        cainIdJson: this.$path.Encrypt(item.cainId),
      };
      UpdateCaseInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/mobileCustomDetail",
            query: { cainId: item.cainId },
          });
        })
        .catch((err) => {});
    },
    getData() {
      SelectPublicityPictures()
        .then((res) => {
          // this.slide = [
          //   {
          //     title: "技能人才评价工作服务管理平台",
          //     content: "为评价机构和考生提供一站式数字信息化服务",
          //     img: require("../assets/banner1.png"),
          //   },
          //   {
          //     title: "技能人才评价工作监管服务平台",
          //     content: "为监管单位和评价机构提供全流程数字化监管服务",
          //     img: require("../assets/banner2.png"),
          //   },
          //   {
          //     title: "职业技能提升在线学习平台",
          //     content:
          //       "为培训机构打造测 — 学 · 练 · 考 · 评 — 的闭环在线学习平台",
          //     img: require("../assets/banner3.png"),
          //   },
          // ];
          let arr = [];
          res.forEach((e) => {
						if(e.pictScence == '移动端'){
							let obj = {
							  title: e.pictName,
							  content: e.pictRemarks,
							  img: this.$path.path + "/src/publicitypictures/" + e.pictSource,
							};
							arr.push(obj);
						}
          });
          this.slide = this.slide.concat(arr);
        })
        .catch((err) => {});
      let params = {
        page: 1,
        limit: 2,
      };
      GetNewsInfo(params)
        .then((res) => {
          this.zixunData = res.list;
        })
        .catch((err) => {});
      GetCaseInfo(params)
        .then((res) => {
          this.kehuData = res.list;
        })
        .catch((err) => {});
      GetConnection()
        .then((res) => {
          this.scrollList = res;
          // this.scrollList1 = res
        })
        .catch((err) => {});
      GetProductList()
        .then((res) => {
          this.chanpinData = res;
        })
        .catch((err) => {});
      GetSystemManger()
        .then((res) => {
          this.detail = res[0];
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
    defaultOption1() {
      return {
        step: 1,
        limitMoveNum: this.scrollList1.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
.mobile {
  padding-top: 60px;
}
.logo-box {
  z-index: 99998;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.logo-box .logo-img {
  width: 144.75px;
  height: 40px;
}
.logo-box .logo-img1 {
  width: 24px;
  height: 19.37px;
}
.my-swipe {
  position: relative;
	min-height: 100px;
  /* height: 200px; */
  /* height: 230px; */
}
.my-swipe img {
  width: 100%;
  height: 100%;
}
.logo-title {
  position: absolute;
  top: 90px;
  left: 10%;
  z-index: 99999;
}
.logo-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 25px;
}
.logo-title p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.van-popup {
  z-index: 99999 !important;
  /* background-color: rgba(0, 0, 0, 0); */
}
/deep/ .van-overlay {
  z-index: 99999 !important;
}
.nav-list {
  margin-top: 60px;
  border-top: 1px solid #fff;
}
.nav-list .nav-box {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 24px;
  /* color: #fff; */
  color: rgba(51, 51, 51, 1);
  border-bottom: 1px solid #fff;
  /* background: rgba(227, 25, 25, 0.7); */
  /* text-align: center; */
}
.nav >>> .van-cell {
  /* background-color: rgba(227, 25, 25, 0.7); */
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  /* color: #fff; */
  color: rgba(51, 51, 51, 1);
  border: none;
  /* border-top: 1px solid #fff; */
  border-bottom: 1px solid #fff;
  /* text-align: center; */
  padding: 10px 24px;
}
.nav >>> .van-collapse-item__content {
  padding: 0;
  background: rgba(0, 0, 0, 0);
  /* border-bottom: 1px solid #fff; */
}
.nav >>> .van-cell__right-icon {
  /* color: #fff; */
}
.nav >>> .van-collapse-item__content .nav-box {
  padding: 10px 0 10px 32px;
  background: rgba(245, 245, 245, 1);
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  font-weight: 500;
}
.content {
  padding: 30px 16px;
}
.content1 {
  background: url(../assets/联系我们-bg.png) no-repeat;
  background-size: 100% 100%;
}
.content .content-title .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin-bottom: 16px;
}
.content .content-title .p2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  margin-bottom: 30px;
  text-align: center;
}
.content .content-box {
  margin-bottom: 30px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 12px;
}
.content .content-box img {
  width: 100%;
  height: 192px;
  margin-bottom: 14px;
}
.content .content-box1 {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.content .content-box1 img {
  width: 100%;
  height: 192px;
  /* height: 228px; */
  margin-bottom: 14px;
}
.content .content-box1 .p1 {
  padding: 0 12px;
}
.content .content-box1 .p4 {
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content .content-box .p1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  padding: 0 12px;
}
.content .content-box .p2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin-bottom: 12px;
  padding: 0 12px;
}
.content .content-box .p3 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 12px;
}
.content .content-btn {
  margin: 0 auto;
  text-align: center;
  width: 160px;
  height: 44px;
  line-height: 44px;
  border-radius: 8px;
  border: 2px solid #e31919;
  font-size: 18px;
  font-weight: 700;
  color: rgba(227, 25, 25, 1);
}
.content-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.content-flex .content-flex-box {
  position: relative;
  width: 49%;
  height: 205px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url(../assets/产品服务1.png.png) no-repeat; */
  background-size: 100% 100%;
}
.content-flex .content-flex-box1 {
  background: url(../assets/产品服务2.png.png) no-repeat;
}
.content-flex .content-flex-box2 {
  background: url(../assets/产品服务3.png.png) no-repeat;
}
.content-flex .content-flex-box3 {
  background: url(../assets/产品服务4.png.png) no-repeat;
}
.content-flex .content-flex-box4 {
  background: url(../assets/产品服务5.png.png) no-repeat;
}
.content-flex .content-flex-box5 {
  background: url(../assets/产品服务6.png.png) no-repeat;
}
.content-flex .content-flex-box6 {
  background: url(../assets/产品服务7.png.png) no-repeat;
}
.content-flex .content-flex-box7 {
  background: url(../assets/产品服务8.png.png) no-repeat;
}
.content-flex .content-flex-box img {
  width: 100%;
  height: 100%;
}
.mark {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(227, 25, 25, 0.8);
  color: #fff;
  padding: 0 12px;
}
.mark .mark-title {
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0;
  text-align: center;
}
.mark .mark-content {
  font-size: 12px;
  font-weight: 400;
}
.about-box img {
  width: 100%;
  height: 246px;
  margin-bottom: 20px;
}
.about-box .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.about-box .content-info-right-mark {
  width: 30px;
  height: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: rgba(51, 51, 51, 1);
}
.about-box .p2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 30px;
}
.about-box .p2 ::v-deep img {
  width: 100%;
}
.content1 .content-title .p1 {
  color: #fff;
  margin-bottom: 30px;
}
.contact-box {
  width: 100%;
  height: 200px;
  background: rgba(113, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contact-box .contact-box-inset {
  width: 33%;
  text-align: center;
}
.contact-box .contact-box-inset .contact-box-inset-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.contact-box .contact-box-inset .contact-box-inset-title1 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.contact-box .contact-box-inset .contact-box-inset-title span {
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 0;
}
.content2 {
  padding: 20px 16px 0 16px;
  background: rgba(33, 30, 30, 1);
}
.footer-box {
  width: 100%;
}
.footer-box .footer-box-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}
.footer-box .footer-box-left .footer-box-left-box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.footer-box .footer-box-left .footer-box-left-box img {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  margin-bottom: 0;
}
.footer-box .footer-box-left .footer-box-left-box p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(156, 156, 156, 1);
}
.footer-box1 {
  width: 100%;
  padding: 10px 0;
  /* height: 50px; */
  /* line-height: 50px; */
  border-top: 1px solid #453f3f;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.footer-box1 p {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-box1 img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.scroll {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  min-width: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  width: 132px;
  /* height: 80px; */
  height: 98px;
  position: relative;
  margin-right: 10px;
}
.partner-box .partner-box-flex .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 132px;
  height: 73px;
  z-index: 1;
}
.partner-box .partner-box-flex .img1 {
  position: absolute;
  top: 5px;
  left: 10px;
  /* width: 110px;
  height: 45px; */
  width: 112px;
  height: 63px;
  z-index: 2;
}
.partner-box .partner-box-flex p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
