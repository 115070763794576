<template>
  <div class="yingjian">
    <div class="content">
      <div class="content-title">技能人才评价设备服务</div>
      <div class="content-msg">
        公司与大华、华为、联想、中控智慧等国内多家高科技企业保持长久合作，就考场设施设备和信息化建设方面提供全套解决方案。如报名信息自动录入的身份证读卡器、人脸识别签到中控智慧屏、理论在线考试的电脑、实操模拟考试机、考试在线评分平板、数据资料储存盘、远程监考摄像头等，通过我公司提供的硬件设施设备服务，实现技能人才评价全流程电子化。
      </div>
      <div class="img-box">
        <div class="img-box-inset">
          <p class="p">证书耗材</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Certificate consumables</p>
          <img src="@/assets/证书耗材.png" alt="" />
        </div>
        <div class="img-box-inset">
          <p class="p">考试电脑</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Examination computer</p>
          <img src="@/assets/电脑.png" alt="" />
        </div>
        <div class="img-box-inset">
          <p class="p">平板</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Flat plate</p>
          <img src="@/assets/平板.png" alt="" />
        </div>
        <div class="img-box-inset">
          <p class="p">摄像头</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">camera</p>
          <img src="@/assets/摄像头.png" alt="" />
        </div>
      </div>
      <div class="img-box">
        <div class="img-box-inset">
          <p class="p">无线路由器</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Wireless router</p>
          <img src="@/assets/无线路由器.png" alt="" />
        </div>
        <div class="img-box-inset">
          <p class="p">人脸识别机</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Face recognition machine</p>
          <img src="@/assets/人脸识别机.png" alt="" />
        </div>
        <div class="img-box-inset">
          <p class="p">人脸识别闸机</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Face recognition gate</p>
          <img src="@/assets/实操设备.png" alt="" />
        </div>
        <div class="img-box-inset">
          <p class="p">平板充电柜</p>
          <div class="mark">
            <div class="mark-left"></div>
            <div class="mark-right"></div>
          </div>
          <p class="p1">Flat charging cabinet</p>
          <img src="@/assets/实训中心设备.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Yingjian",
};
</script>
<style scoped>
.content {
  /* padding: 0 10%; */
  width: 1280px;
  margin: 0 auto;
}
.content .content-title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin: 60px 0;
}
.jianjie-title {
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: rgba(211, 26, 26, 1);
  margin: 60px 0;
}
.content .content-msg {
  text-indent: 2em;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.content .img-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
}
.content .img-box .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 270px;
  height: 350px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.content .img-box .img-box-inset img {
  width: 270px;
  height: 220px;
}
.content .img-box .img-box-inset .p {
  padding: 0 24px;
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box .img-box-inset .p1 {
  padding: 0 24px;
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
}
.content .img-box .img-box-inset .mark {
  display: flex;
  padding: 0 24px;
  margin: 20px 0;
  align-items: center;
}
.content .img-box .img-box-inset .mark .mark-left {
  width: 15px;
  height: 3px;
  background: rgba(228, 30, 30, 1);
}
.content .img-box .img-box-inset .mark .mark-right {
  width: 15px;
  height: 3px;
  background: rgba(51, 51, 51, 1);
}
</style>
