<template>
  <div class="home">
    <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      mode="horizontal"
      router
      @select="handleSelect"
      active-text-color="#FFFFFF"
    >
      <el-menu-item index="/">首页</el-menu-item>
      <!--  v-if="newsTab.length == 0" -->
      <el-menu-item index="/news">资讯动态</el-menu-item>
      <!-- <el-submenu v-else index="/news">
        <template slot="title">资讯动态</template>
        <el-menu-item
          v-for="(item, index) in newsTab"
          :key="index"
          :index="'/news' + '?diccId=' + item.diccId"
          >{{ item.diccName }}</el-menu-item
        >
      </el-submenu> -->
      <!--  @click.native="$router.push('/cultivate')" -->
      <el-submenu index="/product">
        <template slot="title">产品服务</template>
        <!-- <el-menu-item index="/cultivate">互联网+职业技能培训</el-menu-item> -->
        <el-submenu index="">
          <template slot="title">技能人才评价一站式服务</template>
          <el-menu-item index="/appraise">考务平台</el-menu-item>
          <el-menu-item index="/supervise">监管平台</el-menu-item>
          <el-menu-item index="/kaowuFuwu">考务服务</el-menu-item>
          <el-menu-item index="/yingjian">硬件及耗材</el-menu-item>
          <el-menu-item index="/shixun">智能实训</el-menu-item>
        </el-submenu>
        <el-menu-item index="/cultivate">技能培训</el-menu-item>
        <el-menu-item index="" @click.native="goToHXY">鸿新云</el-menu-item>
      </el-submenu>
      <el-menu-item index="/custom">客户案例</el-menu-item>
      <el-menu-item index="/about">关于我们</el-menu-item>
      <el-menu-item index="/contact">联系我们</el-menu-item>
    </el-menu>
    <div
      class="logo-box"
      v-if="
        $route.path == '/' ||
        $route.path == '/news' ||
        $route.path == '/cultivate' ||
        $route.path == '/appraise' ||
        $route.path == '/supervise' ||
        $route.path == '/custom' ||
        $route.path == '/about' ||
        $route.path == '/contact' ||
        $route.path == '/kaowuFuwu' ||
        $route.path == '/yingjian' ||
        $route.path == '/shixun'
      "
    >
      <img ref="logoImg" class="logo-img" src="../assets/logo.png" alt="" />
      <p ref="logoP">专注职业技能领域数字信息化服务</p>
    </div>
    <template v-if="$route.path == '/'">
      <!-- <div
        class="swiper-container"
        id="swiper-container"
        v-if="slide.length > 0"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in slide" :key="index">
            <div class="logo-title">
              <h3>{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
            <img
              :id="'swiper-img' + index"
              class="swiper-img"
              :src="item.img"
              alt=""
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div> -->
      <!-- 轮播图 -->
      <div class="wrapper">
        <swiper ref="mySwiper" :options="swiperOptions" v-if="slide.length > 0">
          <swiper-slide
            v-for="(item, index) in slide"
            :key="index"
            style="min-height: 300px"
          >
            <img
              :src="item.img"
              style="display: block; width: 100%; height: 100%"
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div
          class="swiper-button-prev swiper-button-prevsp"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next swiper-button-nextsp"
          slot="button-next"
        ></div>
      </div>

      <!-- <div class="pics_wrap"> -->
      <!-- <div id="htmlStr"></div> -->
      <!-- <div id="html5zoo-1">
        <ul class="html5zoo-slides" style="display: none">
          <li class="position-li" v-for="(item, index) in slide" :key="index">
            <div class="logo-title">
              <h3>{{ item.title }}</h3>
              <p>{{ item.content }}</p>
            </div>
            <img :src="item.img" />
          </li>
        </ul>
      </div> -->
      <!-- </div> -->
      <!-- 轮播图结束 -->
      <div class="content content1" v-if="zixunData.length >= 1">
        <div class="content-box">
          <div class="content-title">
            <p ref="animationP" class="p1">资讯动态</p>
            <p ref="animationP1" class="p2">
              专注于职业技能领域数字化信息服务，致力于成为一流的职业技能领域数字信息化综合服务商
            </p>
          </div>
          <div class="content-box-inset-box" ref="opacityBox">
            <div
              class="content-box-inset content-box-shadow"
              @click="goToNewsDetail(zixunData[0])"
            >
              <img
                v-if="zixunData[0].newsPict"
                :src="
                  $path.path +
                  '/src/newsInfoUploadAddress/' +
                  zixunData[0].newsPict
                "
                alt=""
              />
              <img v-else src="../assets/资讯动态.png" alt="" />
              <div class="content-box-inset-right">
                <p class="p1">
                  {{ zixunData[0].newsTitle }}
                </p>
                <p class="p2">{{ zixunData[0].newsTime }}</p>
                <p class="p3">
                  {{ zixunData[0].newsdisc }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="content-box-inset1"
            ref="opacityBox1"
            v-if="zixunData.length >= 2"
          >
            <div class="content-box-inset-box1">
              <div
                class="content-box-inset1-box content-box-shadow"
                @click="goToNewsDetail(zixunData[1])"
              >
                <img
                  v-if="zixunData[1].newsPict"
                  :src="
                    $path.path +
                    '/src/newsInfoUploadAddress/' +
                    zixunData[1].newsPict
                  "
                  alt=""
                />
                <img v-else src="../assets/资讯动态.png" alt="" />
                <div class="content-box-inset1-box-right">
                  <p class="p1">
                    {{ zixunData[1].newsTitle }}
                  </p>
                  <p class="p2">{{ zixunData[1].newsTime }}</p>
                  <p class="p3">
                    {{ zixunData[1].newsdisc }}
                  </p>
                </div>
              </div>
            </div>
            <div class="content-box-inset-box1">
              <div class="content-box-inset1-box content-box-shadow">
                <template
                  v-if="zixunData.length >= 3"
                  @click="goToNewsDetail(zixunData[2])"
                >
                  <img
                    v-if="zixunData[2].newsPict"
                    :src="
                      $path.path +
                      '/src/newsInfoUploadAddress/' +
                      zixunData[2].newsPict
                    "
                    alt=""
                  />
                  <img v-else src="../assets/资讯动态.png" alt="" />
                  <div class="content-box-inset1-box-right">
                    <p class="p1">
                      {{ zixunData[2].newsTitle }}
                    </p>
                    <p class="p2">{{ zixunData[2].newsTime }}</p>
                    <p class="p3">
                      {{ zixunData[2].newsdisc }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="content-btn-box" @click="$router.push('/news')">
            <div class="content-btn">全部资讯</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-box">
          <div class="content-title">
            <p class="p1" ref="animationP2">客户案例</p>
            <p class="p2" ref="animationP3">
              给客户带去价值、为员工搭建平台、为社会促进发展
            </p>
          </div>
          <div
            v-if="slide1.length == 1"
            style="display: flex; justify-content: space-between"
          >
            <div
              class="slide-box"
              v-for="(item, index) in slide1"
              :key="index"
              @click="goToCustomDetail(item)"
            >
              <img
                v-if="item.cainPict"
                :src="
                  $path.path +
                  '/src/customerEtcFile/' +
                  item.cainPict.split(',')[0]
                "
                alt=""
              />
              <img v-else src="../assets/客户案例默认图片.png" alt="" />
              <div class="slide-box-bottom">
                <p class="p1">{{ item.cainTitle }}</p>
                <p class="p2">
                  {{ item.cainContent }}
                </p>
              </div>
            </div>
            <div
              style="width: 540px; margin-left: 50px; margin-right: 50px"
            ></div>
          </div>
          <div
            v-else-if="slide1.length == 2"
            style="display: flex; justify-content: space-between"
          >
            <div
              class="slide-box"
              v-for="(item, index) in slide1"
              :key="index"
              @click="goToCustomDetail(item)"
            >
              <img
                v-if="item.cainPict"
                :src="
                  $path.path +
                  '/src/customerEtcFile/' +
                  item.cainPict.split(',')[0]
                "
                alt=""
              />
              <img v-else src="../assets/客户案例默认图片.png" alt="" />
              <div class="slide-box-bottom">
                <p class="p1">{{ item.cainTitle }}</p>
                <p class="p2">
                  {{ item.cainContent }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else
            class="SwiperBox"
            ref="SwiperBox"
            @mouseenter="MouseFun('移入')"
            @mouseleave="MouseFun('移出')"
          >
            <!-- 图片 -->
            <div
              class="imgBox"
              :style="{ left: `-${leftVal}px`, transition: `${ition}s` }"
            >
              <div
                class="slide-box"
                v-for="(item, index) in slide1"
                :key="index"
                @click="goToCustomDetail(item)"
              >
                <img
                  v-if="item.cainPict"
                  :src="
                    $path.path +
                    '/src/customerEtcFile/' +
                    item.cainPict.split(',')[0]
                  "
                  alt=""
                />
                <img v-else src="../assets/客户案例默认图片.png" alt="" />
                <div class="slide-box-bottom">
                  <p class="p1">{{ item.cainTitle }}</p>
                  <p class="p2">
                    {{ item.cainContent }}
                  </p>
                </div>
              </div>
              <!-- 复制前两个放到最后,以实现无缝无线循环滚动效果 -->
              <!-- <div class="slide-box" v-if="slide1.length >= 1">
                <img
                  v-if="slide1[0].cainPict"
                  :src="
                    $path.path +
                    '/src/customerEtcFile/' +
                    slide1[0].cainPict.split(',')[0]
                  "
                  alt=""
                />
                <img v-else src="../assets/客户案例默认图片.png" alt="" />
                <div class="slide-box-bottom">
                  <p class="p1">{{ slide1[0].cainTitle }}</p>
                  <p class="p2">
                    {{ slide1[0].cainContent }}
                  </p>
                </div>
              </div>
              <div class="slide-box" v-if="slide1.length >= 2">
                <img
                  v-if="slide1[1].cainPict"
                  :src="
                    $path.path +
                    '/src/customerEtcFile/' +
                    slide1[1].cainPict.split(',')[0]
                  "
                  alt=""
                />
                <img v-else src="../assets/客户案例默认图片.png" alt="" />
                <div class="slide-box-bottom">
                  <p class="p1">{{ slide1[1].cainTitle }}</p>
                  <p class="p2">
                    {{ slide1[1].cainContent }}
                  </p>
                </div>
              </div> -->
            </div>
            <!-- 左箭头按钮 -->
            <div class="leftBtn" @click="throttle(PrevFun)">
              <img src="../assets/左箭头.png" alt="" />
            </div>
            <!-- 右箭头按钮 -->
            <div class="rightBtn" @click="throttle(NextFun)">
              <img src="../assets/右箭头.png" alt="" />
            </div>
          </div>
          <div class="content-btn-box" @click="$router.push('/custom')">
            <div class="content-btn">更多案例</div>
          </div>
        </div>
      </div>
      <div class="content content2">
        <div class="content-box">
          <div class="content-title">
            <p class="p1" ref="animationP4">产品服务</p>
            <p class="p2" ref="animationP5">专注、聚焦、深耕</p>
          </div>
          <div class="content-flex">
            <div
              class="content-flex-box"
              v-for="(item, index) in chanpinData"
              :key="index"
              ref="animationBox"
            >
              <div class="mark">
                <div class="mark-title">{{ item.prodName }}</div>
                <div class="mark-content">
                  {{ item.prodContent }}
                </div>
              </div>
              <img
                :src="$path.path + '/src/productPhoto/' + item.prodPhoto"
                alt=""
              />
              <p>{{ item.prodName }}</p>
            </div>
            <!-- <div class="content-flex-box" ref="animationP11">
              <div class="mark">
                <div class="mark-title">在线报名缴费系统</div>
                <div class="mark-content">
                  为参加培训考试的学员和机构提供在线报名通道，提供网上报名、网上资格审核和网上缴费等服务，让报名工作更加方便快捷、信息更加准确无误。
                </div>
              </div>
              <img src="../assets/产品服务-图标.png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box1" ref="animationP12">
              <div class="mark">
                <div class="mark-title">在线培训学习系统</div>
                <div class="mark-content">
                  线上学习平台帮助机构融合线上+线下，打造测-学-练-考-评的学习闭环，创新高效的学习模式，加强学员对知识的沉淀、管理、传播和创新。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (1).png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box2" ref="animationP13">
              <div class="mark">
                <div class="mark-title">考务工作管理系统</div>
                <div class="mark-content">
                  通过规范管理考前、考中、考后相关工作和流程，从而减轻管理人员工作负担，降低考务管理成本，提高考务工作的整体信息化管理水平。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (2).png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box3" ref="animationP14">
              <div class="mark">
                <div class="mark-title">在线题库管理系统</div>
                <div class="mark-content">
                  按照国家题库开发规程和各职业技能标准的要求，通过测量学原理，建立理论知识和技能操作考试资源库，对职业方向、知识结构、考题内容和组卷模型全方位的管理与命题服务，同时满足无纸化考试和传统考试的需求。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (3).png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box4" ref="animationP15">
              <div class="mark">
                <div class="mark-title">智能化在线考试系统</div>
                <div class="mark-content">
                  提供跨平台、跨系统的智能化考试服务，能进行PC客户端考试、浏览器考试和手机考试等多种模式，同时提供人脸识别、摄像监考、锁屏防切屏、打乱题序和选项等一系列防舞弊方案让考试更安全，满足各种常见考试需求。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (4).png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box5" ref="animationP16">
              <div class="mark">
                <div class="mark-title">在线监考控制系统</div>
                <div class="mark-content">
                  基于移动智能摄像头实现移动监考，只需要一个摄像头，现场配置网络即可实现视频监考，考试组织方通过监控墙，监考App即可实时监控考试过程，并对缺考，舞弊等考试情况进行记载，拍照，并在考试过程中控制摄像头云台，景深等更好地监控考试现场，保障考试公平公正。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (5).png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box6" ref="animationP17">
              <div class="mark">
                <div class="mark-title">在线阅卷评分系统</div>
                <div class="mark-content">
                  通过在线阅卷系统建立阅卷专家组，分配专家组阅卷任务，监控阅卷专家组阅卷进度以及评卷结果，指定阅卷评分规则、超出差值许可的处理方式以及计算阅卷最终成绩的多种计算规则，让评卷结果公平，公正，可追溯。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (6).png" alt="" />
            </div>
            <div class="content-flex-box content-flex-box7" ref="animationP18">
              <div class="mark">
                <div class="mark-title">数据分析预警系统</div>
                <div class="mark-content">
                  依托大屏展示设备，通过大屏的可视化展示，将相关业务分析结果数据进行抽取并利用
                  GIS、图表等技术进行直观、动态的大屏可视化展现，为领导分析决策、指挥调度提供可视化、智能化提供支持。
                </div>
              </div>
              <img src="../assets/产品服务-图标 (7).png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-box">
          <div class="content-title">
            <p class="p1" ref="animationP6">关于我们</p>
            <p class="p2" ref="animationP7">
              专注于职业技能领域数字信息化服务，立志于成为一流的职业技能领域数字信息化综合服务商、最大限度激发和释放人才活力
            </p>
          </div>
          <div class="content-info">
            <!-- <img
              v-if="detail.infoLogo"
              ref="scaleImg"
              :src="$path.path + '/src/sysImage/' + detail.infoLogo"
              alt=""
            /> -->
            <img ref="scaleImg" src="../assets/公司简介.png" alt="" />
            <div class="content-info-right">
              <div class="content-info-right-title" ref="leftP">
                <!-- 重庆鸿新在线信息技术有限公司 -->
                {{ detail.infoName }}
              </div>
              <div class="content-info-right-mark" ref="opacityP"></div>
              <div
                class="content-info-right-msg"
                ref="rightP"
                v-html="detail.infoProfile"
              >
                <!-- 重庆鸿新在线信息技术有限公司是国内领先的职业技能领域数字信息化服务提供商，公司创立于2021年，是一家拥有自主知识产权的软件研发企业，公司致力于为培训机构和职业院校提供专业的职业技能培训考试一体化数字信息化解决方案，业务范围包含职业技能提升在线培训、考务工作信息化管理、职业标准与评价规范开发应用服务、智能化在线考试、在线监考及考场控制、在线阅卷评分、大数据分析预警、职业教育产教融合与校企合作服务
                、系统集成和运维等数字化服务。 -->
              </div>
              <div class="content-btn-box" @click="$router.push('/about')">
                <div class="content-btn">了解更多</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content content3">
        <div class="content-box">
          <div class="content-title">
            <p class="p1" ref="animationP8">
              行业领先的职业技能领域数字信息化综合服务商
            </p>
          </div>
          <div class="contact-box" ref="scaleBox">
            <div class="contact-box-inset-box">
              <div class="contact-box-inset">
                <div class="contact-box-inset-title">500+</div>
                <div class="contact-box-inset-title1">服务机构</div>
              </div>
              <div class="contact-box-inset">
                <div class="contact-box-inset-title">
                  5000+<span>场次</span>
                </div>
                <div class="contact-box-inset-title1">累计考试</div>
              </div>
              <div class="contact-box-inset">
                <div class="contact-box-inset-title">
                  500000+<span>人次</span>
                </div>
                <div class="contact-box-inset-title1">累计服务</div>
              </div>
            </div>
            <div class="content-btn-box" @click="$router.push('/contact')">
              <div class="content-btn">联系我们</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-box content-box1">
          <div class="content-title">
            <p class="p1" ref="animationP9">合作伙伴</p>
            <p class="p2" ref="animationP10">
              客户第一、团队合作、敬业创新、乐于分享
            </p>
          </div>
          <vue-seamless-scroll
            v-if="scrollList.length >= 1"
            :data="scrollList"
            :class-option="defaultOption"
            class="scroll"
          >
            <ul class="partner-box">
              <li
                class="partner-box-flex"
                v-for="(item, index) in scrollList"
                :key="index"
              >
                <img class="img" src="../assets/矩形 1.png" alt="" />
                <img
                  class="img1"
                  v-if="item.connLogo"
                  :src="$path.path + '/src/customerFile/' + item.connLogo"
                  alt=""
                />
                <p>{{ item.connName }}</p>
              </li>
            </ul>
          </vue-seamless-scroll>
          <!-- <vue-seamless-scroll
            v-if="scrollList1.length >= 1"
            :data="scrollList1"
            :class-option="defaultOption1"
            class="scroll"
          >
            <ul class="partner-box">
              <li
                class="partner-box-flex"
                v-for="(item, index) in scrollList1"
                :key="index"
              >
                <img class="img" src="../assets/矩形 1.png" alt="" />
                <img class="img1" v-if="item.connLogo" :src="$path.path + '/src/customerFile/' + item.connLogo" alt="" />
                <p>{{ item.connName }}</p>
              </li>
            </ul>
          </vue-seamless-scroll> -->
        </div>
      </div>
    </template>
    <router-view v-else />
    <div class="content content4">
      <div class="content-box">
        <div class="footer-box">
          <div class="footer-box-left">
            <div class="footer-box-left-box">
              <img src="../assets/地址.png" alt="" />
              <p>联系地址：{{ detail.infoAddress }}</p>
            </div>
            <div class="footer-box-left-box">
              <img src="../assets/热线.png" alt="" />
              <p>联系热线：{{ detail.infoPhone }}</p>
            </div>
            <div class="footer-box-left-box">
              <img src="../assets/邮箱.png" alt="" />
              <p>联系邮箱：{{ detail.infoEmail }}</p>
            </div>
          </div>
          <div class="footer-box-right">
            <img
              :src="$path.path + '/src/sysImage/' + detail.infoCode"
              alt=""
            />
            <p>公司微信公众号</p>
          </div>
        </div>
      </div>
      <div class="footer-box1">
        <p>
          备案号：
          <a :href="detail.infoUrl" target="_blank">{{ detail.infoRecord }}</a>
        </p>
        <p>
          <img
            src="../assets/渝公网安备.png"
            alt=""
          />渝公网安备：50010702504858号
        </p>
      </div>
    </div>
    <div class="fixed-right">
      <div class="fixed-right-left" v-if="fixedShow">
        <div class="fixed-right-left-top">
          <div class="fixed-right-left-top-img">
            <img src="../assets/电话.png" alt="" />
          </div>
          <p>客服热线：</p>
        </div>
        <div class="fixed-right-left-middle">{{ kefuPhone }}</div>
        <div class="fixed-right-left-bottom">服务时间：{{ kefuTime }}</div>
        <div class="fixed-right-left-kefu">
          <div
            class="kefu-code"
            v-for="(item, index) in kefuData"
            :key="index"
            @click="activeKefu(item, index)"
            :class="{ 'kefu-code-active': activeIndex == index }"
          >
            <img
              v-if="item.custPhoto"
              :src="
                $path.path +
                '/src/customerServiceUploadAddress/' +
                item.custPhoto
              "
              alt=""
            />
            <img
              v-else-if="!item.custPhoto && activeIndex == index"
              src="../assets/客服1-选中.png"
              alt=""
            />
            <img v-else src="../assets/客服1.png" alt="" />
            <p>{{ item.custName }}</p>
          </div>
        </div>
      </div>
      <div class="fixed-right-right">
        <div ref="isShow" class="fixed-right-box back-top" @click="toTop">
          <img src="../assets/回顶部.png" alt="" />
          <!-- <p>回到顶部</p> -->
        </div>
        <div class="fixed-right-box kefu" v-if="!fixedShow" @click="getKefu">
          <img src="../assets/客服.png" alt="" />
          <!-- <p>在线客服</p> -->
        </div>
        <div class="fixed-right-box kefu kefu-active" v-else @click="getKefu">
          <img src="../assets/客服-选中.png" alt="" />
          <!-- <p>在线客服</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "../utils/jquery-1.7.2.min.js";
// import "../utils/html5zoo";
// import "../utils/lovelygallery";
import {
  SelectPublicityPictures,
  GetNewsInfo,
  GetCaseInfo,
  GetConnection,
  GetProductList,
  GetSystemManger,
  GetCustomerServiceList,
  UpdateNewsInfoTotal,
  UpdateCaseInfoTotal,
  SelectDictionary,
  SelectDicContent,
} from "../api/home";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "Home",
  components: { swiper, swiperSlide },
  props: {
    trueOrfalse: Boolean,
  },
  data() {
    return {
      fixedShow: false,
      slide: [],
      slide1: [],
      scrollList: [],
      scrollList1: [],
      leftVal: 0, // 轮播图盒子的偏移值
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定的时器
      imgWidth: 1280, // 在这里填写你需要的图片宽度
      ition: 0.8, // 设置轮播图过度时间
      zixunData: [],
      chanpinData: [],
      detail: {},
      kefuData: [],
      kefuPhone: "",
      kefuTime: "",
      activeIndex: 0,
      newsTab: [],
      swiperOptions: {
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    //滚动事件
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // var offsetTop = document.querySelector('#tabbarBox').offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      // console.log(scrollTop);
      if (0 <= scrollTop && scrollTop < 1600) {
        this.$refs.isShow.classList.remove("is-show");
      } else {
        this.$refs.isShow.classList.add("is-show");
      }
      if (this.$route.path == "/") {
        if (0 < scrollTop && scrollTop < 100) {
          this.$refs.animationP.classList.add("left-animation");
          this.$refs.animationP1.classList.add("right-animation");
        }
        if (100 < scrollTop && scrollTop < 300) {
          this.$refs.opacityBox.classList.add("opacity-animation");
        }
        if (420 < scrollTop && scrollTop < 600) {
          this.$refs.opacityBox1.classList.add("opacity-animation");
        }
        if (750 < scrollTop && scrollTop < 900) {
          this.$refs.animationP2.classList.add("left-animation");
          this.$refs.animationP3.classList.add("right-animation");
        }
        if (1640 < scrollTop && scrollTop < 2000) {
          this.$refs.animationP4.classList.add("left-animation");
          this.$refs.animationP5.classList.add("right-animation");
        }
        if (2700 < scrollTop && scrollTop < 3000) {
          this.$refs.animationP6.classList.add("left-animation");
          this.$refs.animationP7.classList.add("right-animation");
        }
        if (2860 < scrollTop && scrollTop < 3200) {
          this.$refs.scaleImg.classList.add("scale-animation");
          this.$refs.leftP.classList.add("opacity-animation");
          this.$refs.rightP.classList.add("opacity-animation");
          this.$refs.opacityP.classList.add("opacity-animation");
        }
        if (3340 < scrollTop && scrollTop < 3600) {
          this.$refs.animationP8.classList.add("scale-animation");
        }
        if (3400 < scrollTop && scrollTop < 3700) {
          this.$refs.scaleBox.classList.add("scale1-animation");
        }
        if (3870 < scrollTop && scrollTop < 4100) {
          this.$refs.animationP9.classList.add("left-animation");
          this.$refs.animationP10.classList.add("right-animation");
        }
        if (1770 < scrollTop && scrollTop < 1950) {
          this.$refs.animationBox.forEach((e) => {
            e.classList.add("rotate-animation");
          });
          // this.$refs.animationP11.classList.add("rotate-animation");
          // this.$refs.animationP12.classList.add("rotate-animation");
          // this.$refs.animationP13.classList.add("rotate-animation");
          // this.$refs.animationP14.classList.add("rotate-animation");
        }
        if (2180 < scrollTop && scrollTop < 2300) {
          // this.$refs.animationP15.classList.add("rotate-animation");
          // this.$refs.animationP16.classList.add("rotate-animation");
          // this.$refs.animationP17.classList.add("rotate-animation");
          // this.$refs.animationP18.classList.add("rotate-animation");
        }
      }
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    swiperInit() {
      new Swiper("#swiper-container", {
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // effect: "coverflow",
        loop: true,
        grabCursor: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
        // 如果需要滚动条
        // scrollbar: ".swiper-scrollbar",
      });
      new Swiper("#swiper-container1", {
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // effect: "coverflow",
        loop: true,
        grabCursor: true,
        slidesPerView: 2,
        grid: {
          fill: "row",
          rows: 1,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    MouseFun(type) {
      // 停止定时器            // 重新执行定时器
      type == "移入" ? clearTimeout(this.start) : this.anliSwiper();
    },
    // 此为自动轮播定时器
    anliSwiper() {
      this.start = setInterval(() => {
        this.NextFun();
      }, 3000);
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle(fun) {
      if (this.flag) {
        this.flag = false;
        fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true;
        }, 1000); // 节流间隔时间
      }
    },
    // 上一张
    PrevFun() {
      if (this.leftVal == 0) {
        // 判断显示的图片 是 第一张时执行
        // this.slide1.length是指循环图片数组的图片个数
        this.ition = 0; // 将过渡时间变成0，瞬间位移到最后一张图
        this.leftVal = Math.ceil(this.slide1.length / 2) * this.imgWidth; // 瞬间移动
        setTimeout(() => {
          // 通过延时障眼法,归原过渡时间,执行真正的“上一张”函数
          this.ition = 0.8;
          this.leftVal -= this.imgWidth;
        }, this.ition * 500);
      } else {
        // 判断显示的图片 不是 第一张时执行
        this.ition = 0.8;
        this.leftVal -= this.imgWidth;
      }
    },
    // 下一张
    NextFun() {
      if (
        this.leftVal ==
        Math.ceil(this.slide1.length / 2) * this.imgWidth - this.imgWidth
      ) {
        // 判断显示的图片 是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        setTimeout(() => {
          this.ition = 0;
          this.leftVal = 0;
        }, this.ition * 500);
      } else {
        // 判断显示的图片 不是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
      }
    },
    async function1() {
      return await new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.$path.path1 + "/js/jquery-1.7.2.min.js";
        resolve(document.body.appendChild(script));
      });
    },
    async function2() {
      return await new Promise((resolve, reject) => {
        let script1 = document.createElement("script");
        script1.type = "text/javascript";
        script1.src = this.$path.path1 + "/js/html5zoo.js";
        resolve(document.body.appendChild(script1));
      });
    },
    async function3() {
      return await new Promise((resolve, reject) => {
        let script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.src = this.$path.path1 + "/js/lovelygallery.js";
        resolve(document.body.appendChild(script2));
      });
    },
    getHome() {
      SelectPublicityPictures()
        .then((res) => {
          // this.slide = [
          //   {
          //     title: "技能人才评价工作服务管理平台",
          //     content: "为评价机构和考生提供一站式数字信息化服务",
          //     img: require("../assets/11.png"),
          //   },
          //   {
          //     title: "技能人才评价工作监管服务平台",
          //     content: "为监管单位和评价机构提供全流程数字化监管服务",
          //     img: require("../assets/22.png"),
          //   },
          //   {
          //     title: "职业技能提升在线学习平台",
          //     content:
          //       "为培训机构打造测 — 学 · 练 · 考 · 评 — 的闭环在线学习平台",
          //     img: require("../assets/33.png"),
          //   },
          // ];
          let arr = [];
          res.forEach((e) => {
            if (e.pictScence == "电脑端") {
              let obj = {
                title: "",
                content: "",
                img: this.$path.path + "/src/publicitypictures/" + e.pictSource,
              };
              arr.push(obj);
            }
          });
          this.slide = this.slide.concat(arr);
          let htmlStr =
            '<div id="html5zoo-1"><ul class="html5zoo-slides" style="display: none">';
          let htmlIndex = 0;
          this.slide.forEach((e) => {
            // <div class="logo-title"><h3>' +
            //   e.title +
            //   "</h3><p>" +
            //   e.content +
            //   "</p></div>" +
            //   '
            htmlIndex++;
            htmlStr +=
              '<li class="position-li"><img src="' + e.img + '" />' + "</li>";
          });
          htmlStr += "</ul></div>";
          $("#htmlStr").append(htmlStr);

          // let script = document.createElement("script");
          // script.type = "text/javascript";
          // script.src = this.$path.path1 + "/js/jquery-1.7.2.min.js";
          // document.body.appendChild(script);

          // if (this.trueOrfalse) {
          // if (htmlIndex == this.slide.length) {
          // setTimeout(() => {
          let callScript1 = document.querySelector(
            "script[data-callType='callScript1']"
          );
          if (callScript1) {
            document.body.removeChild(callScript1);
          }
          let script1 = document.createElement("script");
          script1.type = "text/javascript";
          script1.src = this.$path.path1 + "/js/html5zoo.js";
          script1.setAttribute("data-callType", "callScript1");
          document.body.appendChild(script1);
          // }, 1);
          setTimeout(() => {
            let callScript2 = document.querySelector(
              "script[data-callType='callScript2']"
            );
            if (callScript2) {
              document.body.removeChild(callScript2);
            }
            let script2 = document.createElement("script");
            script2.type = "text/javascript";
            script2.src = this.$path.path1 + "/js/lovelygallery.js";
            script2.setAttribute("data-callType", "callScript2");
            document.body.appendChild(script2);
          }, 300);
          // }
          // this.$emit("callBack", false);
          // }

          // this.function1().then(() => {
          //   this.function2().then(() => {
          //     this.function3();
          //   });
          // });
        })
        .catch((err) => {});
      let params = {
        page: 1,
        limit: 6,
      };
      GetNewsInfo(params)
        .then((res) => {
          this.zixunData = res.list;
        })
        .catch((err) => {});
      GetCaseInfo(params)
        .then((res) => {
          this.slide1 = res.list;
        })
        .catch((err) => {});
      GetProductList(params)
        .then((res) => {
          this.chanpinData = res;
        })
        .catch((err) => {});
      GetConnection()
        .then((res) => {
          this.scrollList = res;
          // this.scrollList1 = res
        })
        .catch((err) => {});
    },
    goToNewsDetail(item) {
      let data = {
        newsIdJson: this.$path.Encrypt(item.newsId),
      };
      UpdateNewsInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/newsDetail",
            query: { newsId: item.newsId },
          });
        })
        .catch((err) => {});
    },
    goToCustomDetail(item) {
      let data = {
        cainIdJson: this.$path.Encrypt(item.cainId),
      };
      UpdateCaseInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/customDetail",
            query: { cainId: item.cainId },
          });
        })
        .catch((err) => {});
    },
    getKefu() {
      this.fixedShow = !this.fixedShow;
      this.activeIndex = 0;
      if (this.fixedShow) {
        GetCustomerServiceList()
          .then((res) => {
            res.forEach((e) => {
              e.custPhone = this.$path.Decrypt(e.custPhone);
            });
            this.kefuPhone = res[0].custPhone;
            if (res[0].custServiceStartTime && res[0].custServiceEndTime) {
              this.kefuTime =
                res[0].custServiceStartTime + " - " + res[0].custServiceEndTime;
            } else {
              this.kefuTime = "";
            }
            this.kefuData = res;
          })
          .catch((err) => {});
      }
    },
    activeKefu(item, index) {
      this.activeIndex = index;
      this.kefuPhone = item.custPhone;
      if (item.custServiceStartTime && item.custServiceEndTime) {
        this.kefuTime =
          item.custServiceStartTime + " - " + item.custServiceEndTime;
      } else {
        this.kefuTime = "";
      }
    },
    getSystem() {
      GetSystemManger()
        .then((res) => {
          this.detail = res[0];
        })
        .catch((err) => {});
    },
    goToHXY() {
      // 跳转鸿新云在线学习平台
      window.open("https://www.hongxzx.cn/#/web/index");
    },
    getNewsTab() {
      let params = {
        dictName: "资讯类型",
      };
      SelectDictionary(params)
        .then((res) => {
          res.forEach((e) => {
            if (e.dictName == "资讯类型") {
              let para = {
                page: 1,
                limit: 99999,
                diccId: this.$path.Encrypt(e.dictId),
              };
              SelectDicContent(para)
                .then((res) => {
                  this.newsTab = res;
                  let obj = {
                    diccName: "全部",
                    diccId: 0,
                  };
                  this.newsTab.unshift(obj);
                })
                .catch((err) => {});
            }
          });
        })
        .catch((err) => {});
    },
  },
  mounted() {
    if (this.$route.path == "/") {
      window.addEventListener("scroll", this.handleScroll, true);
      this.$refs.logoImg.classList.add("left-animation");
      this.$refs.logoP.classList.add("top-animation");
    }
    // this.getNewsTab();
    this.getSystem();
    // this.getHome();
  },
  activated() {
    if (this.$route.path == "/") {
      this.getHome();
    }
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  watch: {
    $route(to, from) {
      //  || to.path == "/contact"
      if (to.path == "/") {
        // window.location.reload();
      }
    },
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
    defaultOption1() {
      return {
        step: 1,
        limitMoveNum: this.scrollList1.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
#htmlStr >>> img {
  width: calc(100vw - 17px) !important;
}
.home {
  position: relative;
  min-width: 1280px;
  width: 100%;
  overflow: hidden;
}

.el-menu-demo {
  position: absolute;
  /* width: 1000px; */
  z-index: 999;
  right: 200px;
  top: 0;
  background: rgba(0, 0, 0, 0);
  border-bottom: 0 !important;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: rgba(0, 0, 0, 0);
}
.logo-box {
  position: absolute;
  top: 60px;
  left: 10%;
  z-index: 999;
}
.logo-box .logo-img {
  width: 260px;
  height: 71.85px;
  margin-bottom: 13px;
}
.logo-box p {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.6px;
  color: rgba(51, 51, 51, 1);
}
.position-li {
  position: relative;
}
.logo-title {
  position: absolute;
  top: 260px;
  left: 10%;
  z-index: 99999;
}
.logo-title h3 {
  font-size: 44px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 25px;
}
.logo-title p {
  font-size: 24px;
  font-weight: 400;

  color: rgba(255, 255, 255, 1);
}
#swiper-container {
  width: 100%;
  height: 100vh;
}
#swiper-container1 {
  width: 100%;
  height: 467px;
}
.swiper-container .swiper-img {
  width: 100%;
  height: 100%;
}
.content {
  /* width: 100%; */
  min-width: 1280px;
  /* padding: 0 10%; */
  /* margin: 0 auto; */
}
.content .content-box {
  /* width: 100%; */
  width: 1280px;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.content .content-box1 {
  padding: 60px 0 0 0;
}
.content-box .content-title {
  width: 100%;
  text-align: center;
}
.content-box .content-title .p1 {
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-box .content-title .p2 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  margin-top: 22px;
  margin-bottom: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-box .content-box-inset {
  width: 100%;
  height: 270px;
  display: flex;
  overflow: hidden;
}
.content-box .content-box-inset:hover {
  cursor: pointer;
}
.content-box .content-box-inset1:hover {
  cursor: pointer;
}
.content-box .content-box-inset img {
  /* width: 500px;
  height: 280px; */
  width: 480px;
  height: 270px;
  margin-right: 40px;
}
.content-box .content-box-inset .content-box-inset-right {
  width: calc(100% - 520px);
}
.content-box .content-box-inset .content-box-inset-right .p1 {
  font-size: 20px;
  font-weight: 400;

  color: rgba(51, 51, 51, 1);
}
.content-box .content-box-inset .content-box-inset-right .p2 {
  font-size: 14px;
  font-weight: 400;

  margin-top: 15px;
  margin-bottom: 26px;
  color: rgba(153, 153, 153, 1);
}
.content-box .content-box-inset .content-box-inset-right .p3 {
  font-size: 14px;
  font-weight: 400;

  line-height: 28px;
  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content-box .content-box-inset1 {
  width: 100%;
  height: 117px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.content-box .content-box-inset-box {
  width: 100%;
}
.content-box .content-box-inset1 .content-box-inset-box1 {
  width: 48%;
}
.content-box .content-box-inset1 .content-box-inset1-box {
  width: 100%;
  display: flex;
}
.content-box .content-box-inset1 .content-box-inset1-box img {
  /* width: 200px;
  height: 112px; */
  width: 208px;
  height: 117px;
  margin-right: 20px;
}
.content-box
  .content-box-inset1
  .content-box-inset1-box
  .content-box-inset1-box-right {
  width: calc(100% - 228px);
}
.content-box
  .content-box-inset1
  .content-box-inset1-box
  .content-box-inset1-box-right
  .p1 {
  font-size: 20px;
  font-weight: 400;

  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-box
  .content-box-inset1
  .content-box-inset1-box
  .content-box-inset1-box-right
  .p2 {
  font-size: 14px;
  font-weight: 400;

  margin-top: 16px;
  margin-bottom: 30px;
  color: rgba(153, 153, 153, 1);
}
.content-box
  .content-box-inset1
  .content-box-inset1-box
  .content-box-inset1-box-right
  .p3 {
  font-size: 14px;
  font-weight: 400;

  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-box .content-btn-box {
  /* width: 164px; */
  /* height: 48px; */
  margin: 0 auto;
  margin-top: 60px;
}
.content-box .content-btn {
  /* margin: 0 auto; */
  text-align: center;
  width: 160px;
  height: 44px;
  line-height: 44px;
  border-radius: 8px;
  border: 2px solid #e31919;
  font-size: 18px;
  font-weight: 700;
  color: rgba(227, 25, 25, 1);
}
.content-box .slide-box {
  box-shadow: 0px 0px 6px 0px #ccc;
  width: 540px;
  margin-left: 50px;
  margin-right: 50px;
  /* width: 100%; */
  /* margin: 0 auto;
  margin-left: 2.5%;
  margin-right: 2.5%; */
}
.content-box .slide-box:hover {
  cursor: pointer;
}
.content-box .slide-box img {
  width: 540px;
  /* height: 360px; */
  height: 304px;
}
.content-box .slide-box .slide-box-bottom {
  /* width: 100%; */
  padding: 20px 20px 20px 20px;
}
.content-box .slide-box .slide-box-bottom .p1 {
  font-size: 18px;
  font-weight: 700;

  margin-bottom: 20px;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-box .slide-box .slide-box-bottom .p2 {
  font-size: 14px;
  font-weight: 400;

  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.swiper-button-prev,
.swiper-button-next {
  /* width: 11px;
  height: 20px; */
  padding: 30px 10px;
  background: rgba(255, 240, 240, 0.5);
  color: rgba(227, 25, 25, 1);
}
.content-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.content-flex .content-flex-box {
  position: relative;
  /* width: 23%; */
  width: 290px;
  height: 360px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: url(../assets/产品服务1.png.png) no-repeat; */
  background-size: 100% 100%;
}
.content-flex .content-flex-box1 {
  background: url(../assets/产品服务2.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box2 {
  background: url(../assets/产品服务3.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box3 {
  background: url(../assets/产品服务4.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box4 {
  background: url(../assets/产品服务5.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box5 {
  background: url(../assets/产品服务6.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box6 {
  background: url(../assets/产品服务7.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box7 {
  background: url(../assets/产品服务8.png.png) no-repeat;
  background-size: 100% 100%;
}
.content-flex .content-flex-box img {
  width: 100%;
  height: 100%;
}
.content-flex .content-flex-box p {
  position: absolute;
  bottom: 80px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.content-flex .content-flex-box:hover {
  cursor: pointer;
}
.content-flex .content-flex-box:hover p {
  display: none;
}
.content-flex .content-flex-box:hover .mark {
  animation: change linear 0.3s forwards;
}
@keyframes change {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.content1 {
  background: url(../assets/资讯动态-bg.png.png) no-repeat;
  background-size: 100% 100%;
}
.content2 {
  background: url(../assets/产品服务-bg.png.png) no-repeat;
  background-size: 100% 100%;
}
.content3 {
  background: url(../assets/联系我们-bg.png) no-repeat;
  background-size: 100% 100%;
}
.content-info {
  width: 100%;
  height: 360px;
  display: flex;
}
.content-info img {
  width: 500px;
  height: 360px;
  margin-right: 40px;
}
.content-info .content-info-right {
  width: calc(100% - 540px);
  position: relative;
  overflow: hidden;
}
.content-info .content-info-right .content-info-right-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 36px;
  font-weight: 700;
}
.content-info .content-info-right .content-info-right-mark {
  width: 50px;
  height: 6px;
  margin-top: 50px;
  margin-bottom: 30px;
  background: rgba(51, 51, 51, 1);
}
.content-info .content-info-right .content-info-right-msg {
  font-size: 14px;
  font-weight: 400;

  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content-info .content-info-right .content-info-right-msg ::v-deep img {
  width: 100%;
}
.content-info .content-info-right .content-btn-box {
  position: absolute;
  left: 0;
  bottom: 0;
}
.content3 .content-title .p1 {
  color: #fff;
}
.contact-box {
  width: 100%;
  height: 300px;
  margin-top: 60px;
  background: rgba(113, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-box .contact-box-inset-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contact-box .contact-box-inset {
  width: 33%;
  text-align: center;
}
.contact-box .contact-box-inset .contact-box-inset-title {
  font-size: 36px;
  font-weight: 700;

  line-height: 50px;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.contact-box .contact-box-inset .contact-box-inset-title1 {
  font-size: 18px;
  font-weight: 400;

  line-height: 36px;
  color: rgba(255, 255, 255, 1);
}
.contact-box .contact-box-inset .contact-box-inset-title span {
  font-size: 18px;
  font-weight: 400;

  position: absolute;
  top: 0;
}
.scroll {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  min-width: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  width: 240px;
  height: 170px;
  position: relative;
  margin-right: 20px;
}
.partner-box .partner-box-flex .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 248px;
  height: 147px;
  z-index: 1;
}
.partner-box .partner-box-flex .img1 {
  position: absolute;
  top: 15px;
  left: 20px;
  /* width: 200px;
  height: 70px; */
  width: 208px;
  height: 117px;
  z-index: 2;
}
.partner-box .partner-box-flex p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.content4 {
  background: rgba(33, 30, 30, 1);
}
.footer-box {
  width: 100%;
  display: flex;
}
.footer-box .footer-box-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-box .footer-box-right {
  width: 40%;
  text-align: center;
  border-left: 1px solid #453f3f;
}
.footer-box .footer-box-left .footer-box-left-box {
  display: flex;
  align-items: center;
}
.footer-box .footer-box-left .footer-box-left-box img {
  width: 12px;
  height: 12px;
  margin-right: 15px;
}
.footer-box .footer-box-left .footer-box-left-box p {
  font-size: 14px;
  font-weight: 400;

  color: rgba(156, 156, 156, 1);
}
.footer-box .footer-box-right img {
  width: 88px;
  height: 88px;
}
.footer-box .footer-box-right p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: rgba(156, 156, 156, 1);
}
.footer-box1 {
  width: 100%;
  height: 50px;
  /* line-height: 50px; */
  border-top: 1px solid #453f3f;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  color: rgba(102, 102, 102, 1);
}
.footer-box1 a {
  color: rgba(102, 102, 102, 1);
}
.footer-box1 a:hover {
  color: #007aff;
}
.footer-box1 p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-box1 img {
  width: 14px;
  height: 14px;
  margin: 0 10px 0 20px;
}
.fixed-right {
  position: fixed;
  z-index: 99999;
  right: 50px;
  bottom: 50px;
  display: flex;
}
.fixed-right-left {
  min-width: 150px;

  position: fixed;
  right: 110px;
  bottom: 50px;
  background: #fbfbfb;
  padding: 10px;
  border-radius: 8px;
  margin-right: 2px;
}
.fixed-right-left-kefu {
  max-height: 200px;
  overflow: auto;
}
.fixed-right .fixed-right-left .fixed-right-left-top {
  display: flex;
  align-items: center;
}
.fixed-right .fixed-right-left .fixed-right-left-top p {
  font-size: 14px;
  font-weight: 700;

  color: rgba(102, 102, 102, 1);
}
.fixed-right .fixed-right-left .fixed-right-left-top .fixed-right-left-top-img {
  background: #666666;
  border-radius: 3px;
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-right: 6px;
}
.fixed-right
  .fixed-right-left
  .fixed-right-left-top
  .fixed-right-left-top-img
  img {
  width: 100%;
  height: 100%;
}
.fixed-right .fixed-right-right {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.fixed-right .fixed-right-box {
  width: 60px;
  height: 60px;
  text-align: center;
  /* margin-bottom: 15px; */
  background: #fbfbfb;
  /* padding: 10px; */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #ccc;
}
.fixed-right .fixed-right-box:hover {
  cursor: pointer;
}
.fixed-right .fixed-right-box img {
  width: 30px;
  height: 30px;
}
.fixed-right .fixed-right-box p {
  font-size: 12px;
  font-weight: 400;

  line-height: 12px;
  color: rgba(153, 153, 153, 1);
}
.fixed-right .kefu-active p {
  color: rgba(227, 25, 25, 1);
}
.fixed-right .fixed-right-left .fixed-right-left-middle {
  font-size: 14px;
  font-weight: 700;

  margin: 9px 0;
  color: rgba(102, 102, 102, 1);
}
.fixed-right .fixed-right-left .fixed-right-left-bottom {
  font-size: 12px;
  font-weight: 400;

  color: rgba(153, 153, 153, 1);
  padding-bottom: 9px;
  border-bottom: 1px solid #e6e6e6;
}
.fixed-right .fixed-right-left .kefu-code {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 5px;
  border-radius: 8px;
}
.fixed-right .fixed-right-left .kefu-code:hover {
  background: #e31919;
  cursor: pointer;
}
.fixed-right .fixed-right-left .kefu-code-active {
  background: #e31919;
  cursor: pointer;
}
.fixed-right .fixed-right-left .kefu-code:hover p {
  color: #fff;
}
.fixed-right .fixed-right-left .kefu-code-active p {
  color: #fff !important;
}
/* .fixed-right .fixed-right-left .kefu-code:hover img {
  content: url(../assets/客服1-选中.png);
}
.fixed-right .fixed-right-left .kefu-code-active img {
  content: url(../assets/客服1-选中.png);
} */
.fixed-right .fixed-right-left .kefu-code img {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}
.fixed-right .fixed-right-left .kefu-code p {
  width: calc(100% - 44px);
  font-size: 14px;
  font-weight: 400;

  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content-btn-box:hover {
  cursor: pointer;
}
.content-btn-box:hover .content-btn {
  animation: btn-top linear 0.1s forwards;
}
@keyframes btn-top {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-3px);
  }
}

/* 图片容器样式 */
.SwiperBox {
  position: relative;
  /* width: 100%; */
  width: 1280px;
  /* height: 500px; */
  height: 444px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
}
.imgBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 360px; */
  display: flex;
  justify-content: flex-start;
  /* overflow: hidden; */
}
/* 图片默认样式 */
/* .imgBox img {
  flex-shrink: 0;
  width: 250px;
  height: 300px;
} */
/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
  position: absolute;
  top: 190px;
  transform: translateY(-50%);
  /* width: 30px;
  height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(109, 109, 109, 0.445);
  color: #fff;
  /* border-radius: 50%; */
  cursor: pointer;
  padding: 30px 10px;
  background: rgba(255, 240, 240, 0.5);
  color: rgba(227, 25, 25, 1);
}
.leftBtn {
  left: 0px;
}
.rightBtn {
  right: 0px;
}
.leftBtn img,
.rightBtn img {
  width: 11px;
  height: 20px;
}
.mark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* width: 100%; */
  height: 0;
  overflow: hidden;
  background: rgba(227, 25, 25, 0.8);
  color: #fff;
  padding: 0 30px;
}
.mark .mark-title {
  font-size: 24px;
  font-weight: 400;
  margin: 30px 0;
  text-align: center;
}
.mark .mark-content {
  font-size: 16px;
  font-weight: 400;
}
.left-animation {
  position: relative;
  animation: left linear 0.7s forwards;
}
@keyframes left {
  0% {
    /* right: 1000px; */
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    /* right: 0; */
    transform: translateY(0px);
    opacity: 1;
  }
}
.right-animation {
  position: relative;
  animation: right linear 0.7s forwards;
}
@keyframes right {
  0% {
    /* left: 1000px; */
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    /* left: 0; */
    transform: translateX(0px);
    opacity: 1;
  }
}
.top-animation {
  position: relative;
  animation: top linear 0.7s forwards;
}
@keyframes top {
  0% {
    /* top: 450px; */
    transform: translateY(450px);
    opacity: 0;
  }
  100% {
    /* top: 0; */
    transform: translateX(0px);
    opacity: 1;
  }
}
.scale-animation {
  position: relative;
  animation: scale linear 0.7s forwards;
}
@keyframes scale {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.scale1-animation {
  position: relative;
  animation: scale1 linear 0.7s forwards;
}
@keyframes scale1 {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.rotate-animation {
  position: relative;
  animation: rotate linear 0.7s forwards;
}
@keyframes rotate {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}
.opacity-animation {
  position: relative;
  display: block;
  animation: opacity linear 1s forwards;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.el-menu-demo .el-menu-item {
  /* width: 60px;
  text-align: center; */
  font-size: 16px;
  font-weight: 700;
  color: #fff !important;
  margin: 0 30px;
  padding: 0;
}
.el-menu-demo >>> .el-submenu__title {
  font-size: 16px;
  font-weight: 700;
  color: #fff !important;
  margin: 0 30px;
  padding: 0;
}
.el-menu-demo >>> .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-menu--popup .el-menu-item.is-active {
  color: #e31919 !important;
}
.el-menu--popup .el-menu-item:hover {
  color: #e31919 !important;
}
.el-menu--popup .el-menu-item {
  color: #333333 !important;
  text-align: center;
  /* padding: 0 38px !important;
  margin-left: -99px; */
}
.el-menu--popup-bottom-start {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.el-menu--horizontal > .el-submenu >>> .el-submenu__icon-arrow {
  display: none;
}
.back-top {
  visibility: hidden;
}
.is-show {
  visibility: visible;
}
.content-box-inset-box:hover .content-box-shadow {
  box-shadow: 0px 0px 6px 0px #ccc;
  animation: btn-top linear 0.1s forwards;
}
.content-box-inset-box1:hover .content-box-shadow {
  box-shadow: 0px 0px 6px 0px #ccc;
  animation: btn-top linear 0.1s forwards;
}

.wrapper {
  position: relative;
}
.wrapper .swiper-button-nextsp,
.wrapper .swiper-button-prevsp {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  position: absolute;
  z-index: 2222;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
}
</style>
