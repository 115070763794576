<template>
  <div class="product">
    <div class="header-bg">
      <p class="p1">产品服务</p>
      <p class="p2">专注、聚焦、深耕</p>
    </div>
    <div class="title-nav-box">
      <div class="title-nav-inset">
        <div
          class="title-nav"
          :class="$route.path == '/cultivate' ? 'title-nav-active' : ''"
          @click="$router.push('/cultivate')"
        >
          技能培训
        </div>
        <div
          class="title-nav"
          :class="$route.path == '/appraise' ? 'title-nav-active' : ''"
          @click="$router.push('/appraise')"
        >
          考务平台
        </div>
        <div
          class="title-nav"
          :class="$route.path == '/supervise' ? 'title-nav-active' : ''"
          @click="$router.push('/supervise')"
        >
          监管平台
        </div>
        <div
          class="title-nav"
          :class="$route.path == '/kaowuFuwu' ? 'title-nav-active' : ''"
          @click="$router.push('/kaowuFuwu')"
        >
          考务服务
        </div>
        <div
          class="title-nav"
          :class="$route.path == '/yingjian' ? 'title-nav-active' : ''"
          @click="$router.push('/yingjian')"
        >
          硬件及耗材
        </div>
        <div
          class="title-nav"
          :class="$route.path == '/shixun' ? 'title-nav-active' : ''"
          @click="$router.push('/shixun')"
        >
          智能实训
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "Product",
};
</script>
<style scoped>
.header-bg {
  width: 100%;
  height: 400px;
  background: url(../assets/产品服务-banner.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(102, 102, 102, 1);
}
.title-nav-box {
  width: 100%;
  height: 60px;
  background: #333333;
}
.title-nav-box .title-nav-inset {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-nav-box .title-nav-inset .title-nav {
  width: 30%;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 60px;
  text-align: center;
}
.title-nav-box .title-nav-inset .title-nav:hover {
  background: #e31919;
  cursor: pointer;
}
.title-nav-box .title-nav-inset .title-nav-active {
  background: #e31919;
}
</style>
