import axios from 'axios';
import publicPath from './public';

/* 防止重复提交，利用axios的cancelToken */
let pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const CancelToken = axios.CancelToken;

// 跳转登录地址
let toLogin = '';

/* 创建axios实例 */
const service = axios.create({
  baseURL: publicPath.path
});

/* 取消重复请求 */
const removePending = (config, f) => {
  // 获取请求的url
  const flagUrl = config.url;
  // 判断该请求是否在请求队列中
  if (pending.indexOf(flagUrl) !== -1) {
    // 如果在请求中，并存在f,f即axios提供的取消函数
    if (f) {
      f('取消重复请求'); // 执行取消操作
    } else {
      pending.splice(pending.indexOf(flagUrl), 1); // 把这条记录从数组中移除
    }
  } else {
    // 如果不存在在请求队列中，加入队列
    if (f) {
      pending.push(flagUrl);
    }
  }
};

/* request拦截器 */
service.interceptors.request.use(
  (config) => {
    // neverCancel 配置项，允许多个请求
    if (!config.neverCancel) {
      // 生成cancelToken，取消重复请求
      // config.cancelToken = new CancelToken((c) => {
      //     removePending(config, c)
      // })
    }
    let token = '';
    if (
      sessionStorage.getItem('AccessToken') &&
      sessionStorage.getItem('AccessToken') !== 'null'
    ) {
      token = sessionStorage.getItem('AccessToken');
    }
    if (!token) {
      // 如果token不存在
      // token = sessionStorage.getItem("AccessToken");
      // window.location.href = toLogin;
    }
    config.headers.Authorization = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* respone拦截器 */
service.interceptors.response.use(
  (response) => {
    if (response) {
      switch (response.data.code) {
        case 401:
          // 处理token过期
          sessionStorage.removeItem('AccessToken');
          window.location.href = toLogin;
          break;
        case 10402:
          // 处理token无效
          sessionStorage.removeItem('AccessToken');
          window.location.href = toLogin;
          break;
        case 10404:
          // 处理token不存在
          sessionStorage.removeItem('AccessToken');
          window.location.href = toLogin;
          break;
      }
    }
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 10403:
        // 处理token过期
      }
    }
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);

export default service;
