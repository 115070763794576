<template>
  <div class="about">
    <div class="header-bg">
      <p class="p1">关于我们</p>
      <p class="p2">
        专注于职业技能领域数字信息化服务，立志于成为一流的职业技能领域数字信息化综合服务商、最大限度激发和释放人才活力
      </p>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-title">
          公司简介
          <!-- {{ detail.infoName }} -->
        </div>
        <div class="content-msg" v-html="detail.infoProfile"></div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-box-top">
          <div class="content-box-top-tag">
            <p class="p1">500+</p>
            <p class="p2">典型客户</p>
          </div>
          <div class="content-box-top-tag">
            <p class="p1">15 <span>年</span></p>
            <p class="p2">行业经验</p>
          </div>
          <div class="content-box-top-tag">
            <p class="p1">500000+</p>
            <p class="p2">服务人次</p>
          </div>
        </div>
        <div class="content-box-bottom">
          <div class="content-box-bottom-box content-box-bottom-box1">
            <img src="../assets/双引号.png" alt="" />
            <p class="p1">使命</p>
            <p class="p2">给客户带去价值、为员工搭建平台、为社会促进发展</p>
          </div>
          <div class="content-box-bottom-box content-box-bottom-box2">
            <img src="../assets/双引号.png" alt="" />
            <p class="p1">愿景</p>
            <p class="p2">一流的职业技能领域数字化综合服务商</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content content1">
      <div class="content-box">
        <div class="content-title">荣誉资质</div>
        <vue-seamless-scroll
          v-if="scrollList.length >= 1"
          :data="scrollList"
          :class-option="defaultOption"
          class="scroll"
        >
          <ul class="partner-box">
            <li
              class="partner-box-flex"
              v-for="(item, index) in scrollList"
              :key="index"
            >
              <img
                :src="
                  $path.path +
                  '/src/qualificationcertificate/' +
                  item.endoPicture
                "
                alt=""
              />
              <p>{{ item.endoName }}</p>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import { GetEndowments, GetSystemManger } from "../api/home";
export default {
  name: "About",
  data() {
    return {
      scrollList: [],
      detail: {},
    };
  },
  methods: {
    getData() {
      GetSystemManger()
        .then((res) => {
          this.detail = res[0];
        })
        .catch((err) => {});
      GetEndowments()
        .then((res) => {
          this.scrollList = res;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
.header-bg {
  width: 100%;
  height: 400px;
  background: url(../assets/关于我们-banner.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 522px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(102, 102, 102, 1);
}
.content {
  width: 100%;
}
.content .content-box {
  width: 1200px;
  padding: 60px 0;
  margin: 0 auto;
}
.content .content-box .content-title {
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin-bottom: 60px;
}
.jianjie-title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
  color: rgba(211, 26, 26, 1);
}
.content .content-box .content-msg {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  /* text-indent: 2em; */
}
.content .content-box .content-msg ::v-deep img {
  width: 100%;
}
.content-box-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.content-box-top .content-box-top-tag {
  width: 30%;
  position: relative;
  text-align: center;
}
.content-box-top .content-box-top-tag .p1 {
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  position: relative;
}
.content-box-top .content-box-top-tag .p1 span {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  display: inline-block;
  position: absolute;
  top: 0;
  padding-left: 10px;
}
.content-box-top .content-box-top-tag .p2 {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(51, 51, 51, 1);
}
.content-box-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.content-box-bottom .content-box-bottom-box {
  width: 580px;
  height: 160px;
  border-radius: 8px;
  position: relative;
}
.content-box-bottom .content-box-bottom-box1 {
  background: url(../assets/使命.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-bottom .content-box-bottom-box2 {
  background: url(../assets/愿景.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-bottom .content-box-bottom-box .p1 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 42px;
  left: 34px;
  z-index: 2;
}
.content-box-bottom .content-box-bottom-box .p2 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  top: 88px;
  left: 34px;
}
.content-box-bottom .content-box-bottom-box img {
  position: absolute;
  top: 27px;
  left: 27px;
  width: 36px;
  height: 36px;
  z-index: 1;
}
.scroll {
  width: 100%;
  height: 270px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.partner-box .partner-box-flex img {
  /* width: 240px;
  height: 170px; */
  width: 200px;
  height: 270px;
  margin: 0 10px;
}
.partner-box .partner-box-flex p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  margin-top: 15px;
}
.content1 {
  background: url(../assets/产品服务-bg.png.png) no-repeat;
  background-size: 100% 100%;
}
</style>
