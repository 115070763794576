<template>
  <div class="mobileProduct">
    <div class="content content-margin">
      <p class="jianjie-title">技能人才评价监管系统</p>
      <img class="content-img" src="../assets/监管.png" alt="" />
      <p class="titile-p2 title-margin">
        利用大数据分析，提高人才决策分析的科学性，为领导决策提供科学、准确、及时的依据。通过打造集认定、竞赛、监管、分析、共享为一体的综合性技能人才评价服务管理平台，加速形成人人渴望成才、人人努力成才、人人皆可成才、人人尽展其才的良好局面。以视频监控信息与技能人才评价相关数据分析为支撑，建立技能人才评价服务监管体系，实现技能人才评价服务的全方位监管。
      </p>
    </div>
    <div class="content content-margin">
      <p class="content-title">系统组成</p>
      <p class="content-title1">
        技能人才评价监管服务平台由公共服务平台和监管平台两部分构成
      </p>
    </div>
    <div class="content">
      <div class="content-box content-box1">
        <div
          class="content-box-tab"
          :class="tabActive ? 'content-box-tab-active up' : ''"
          @click="tabActive = true"
        >
          <img v-if="tabActive" src="../assets/公共服务.png" alt="" />
          <img v-else src="../assets/公共服务1.png" alt="" />
          <p>公共服务平台</p>
        </div>
        <div
          class="content-box-tab"
          :class="tabActive ? '' : 'content-box-tab-active up'"
          @click="tabActive = false"
        >
          <img v-if="tabActive" src="../assets/监管平台.png" alt="" />
          <img v-else src="../assets/监管平台1.png" alt="" />
          <p>监管平台</p>
        </div>
      </div>
    </div>
    <template v-if="tabActive">
      <div class="content">
        <div class="content-box2">
          <div class="content-box-right">
            <div class="content-box-right-tag">平台简介</div>
            <div class="content-box-right-title">公共服务平台</div>
            <div class="content-box-right-msg">
              统一门户网站和微信公众号服务平台两部分构成。实现便民化服务，简化办事环境与手续，规范优化服务流程，实现线下服务向网上办事大厅延伸，建设线上线下一体化的服务体系，为各类服务对象提供方便快捷在线服务。从而实现为技能人才评价提供政策宣传、通知公告、资讯发布、评价机构查询、评价职业(工种)查询、专项职业能力考核项目查询、证书查询、职业技能标准查询、评价计划查询等一站式网站服务。
            </div>
          </div>
          <img src="../assets/公共服务平台.png" alt="" />
        </div>
      </div>
    </template>
    <div class="jianguan-bg" v-else>
      <div class="content">
        <div class="content-box2">
          <div class="content-box-right">
            <div class="content-box-right-tag">平台简介</div>
            <div class="content-box-right-title">监管平台</div>
            <div class="content-box-right-msg">
              主要用于技能人才评价监管服务单位对于技能人才评价工作总体目标及组织实施和监督检查工作。建立技能人才评价公开制度，构建政府监管、企业自律、社会监督、公众参与的质量监管体系。对技能人才评价工作进行全程、全方位监督，确保人才评价工作规范有序开展。
            </div>
            <div class="content-box-right-msg">
              技能人才监管平台主要包括：备案信息管理系统、专家队伍管理系统、在线题库管理系统、等级评价监管系统、专项考核监管系统、技能竞赛系统、远程监考系统、数据分析系统八部分构成。
            </div>
          </div>
          <img src="../assets/监管平台-简介.png" alt="" />
        </div>
      </div>
      <van-collapse class="hexin" v-model="activeName1" accordion>
        <van-collapse-item name="1">
          <template #title>
            <div>
              <img src="../assets/备案系统-0.png" alt="" /> 备案信息管理系统
            </div>
          </template>
          <div class="tab-content1">
            <div class="tab-content1-title">
              <img src="../assets/备案系统-1.png" alt="" />
              <p>备案信息管理系统</p>
            </div>
            <div class="tab-content1-msg">
              依据备案管理规范，具有对组织机构、认定职业工种、等级、考核场地、考评人员等进行备案管理，保证认定范围的严肃性、规范性和真实性。评价机构在网上进行填报机构申报相关资料信息，备案管理部门对评价机构网上申报的相关资料信息进行在线审核，审核通过后再分配给对应专家进行在线评审，专家评审通过后评价机构信息统一在技能人才评价网进行公示。
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item name="2">
          <template #title>
            <div>
              <img src="../assets/专项考核-0.png" alt="" /> 专项考核监管系统
            </div>
          </template>
          <div class="tab-content1">
            <div class="tab-content1-title">
              <img src="../assets/专项考核-1.png" alt="" />
              <p>专项考核监管系统</p>
            </div>
            <div class="tab-content1-msg">
              各级人社监管部门对批准备案的专项职业能力考核机构开展专项职业能力考核工作的审核或监督工作。
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item name="3">
          <template #title>
            <div>
              <img src="../assets/技能竞赛-0.png" alt="" />
              职业技能竞赛管理系统
            </div>
          </template>
          <div class="tab-content1">
            <div class="tab-content1-title">
              <img src="../assets/技能竞赛-1.png" alt="" />
              <p>职业技能竞赛管理系统</p>
            </div>
            <div class="tab-content1-msg">
              建设职业技能竞赛系统，规范职业技能竞赛活动流程，完善技能人才选拔机制，充分发挥职业技能竞赛在促进技能人才培养和选拔的引导推动作用，达到“以赛促评”目的。
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item name="4">
          <template #title>
            <div>
              <img src="../assets/专家队伍-0.png" alt="" />
              专家队伍培训管理系统
            </div>
          </template>
          <div class="tab-content1">
            <div class="tab-content1-title">
              <img src="../assets/专家队伍-1.png" alt="" />
              <p>专家队伍培训管理系统</p>
            </div>
            <div class="tab-content1-msg">
              建设专家培训管理系统，加强督导员、考评员队伍建设，提高职业技能等级认定与企业自主评价工作质量，实现职业技能人才考试过程质量管理提升。
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item name="5">
          <template #title>
            <div>
              <img src="../assets/在线题库-0.png" alt="" /> 在线题库管理系统
            </div>
          </template>
          <div class="tab-content1">
            <div class="tab-content1-title">
              <img src="../assets/在线题库-1.png" alt="" />
              <p>在线题库管理系统</p>
            </div>
            <div class="tab-content1-msg">
              建立技能人才评价省级题库，实现考核试题基本全覆盖市场需求量大的职业工种，全面支撑技能人才评价业务开展，实现试题跨系统授权使用。
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div class="content6">
        <van-tabs class="baozhang" v-model="activeName2" :ellipsis="false">
          <van-tab title="认定计划监管系统">
            <div class="content-tab1">
              <div class="content-tab1-title">
                <img src="../assets/计划监管.png" alt="" />
                <p>认定计划监管系统</p>
              </div>
              <div class="content-tab1-msg">
                监管服务单位对已备案成功的认定机构的评价活动进行监管，主要包括评价范围、报名资质审核、设置预警条件、确定监管点，随机派遣外部督导员进行现场督导，上报证书信息到国网上查询等。
              </div>
            </div>
            <img class="positionImg" src="../assets/图片.png" alt="" />
          </van-tab>
          <van-tab title="远程在线监考系统">
            <div class="content-tab1 content-tab-position">
              <div class="content-tab1-title">
                <img src="../assets/在线监考.png" alt="" />
                <p>远程在线监考系统</p>
              </div>
              <div class="content-tab1-msg">
                对评价机构开展的技能人才评价活动实施考场实时视频监控，并且可以和考场现场进行远程语音实时对讲、查看考场考生实时答题情况等，考核结束后还可对有异议的评价场次进行视频追溯回放。
              </div>
            </div>
            <img class="positionImg1" src="../assets/1.png" alt="" />
          </van-tab>
          <van-tab title="数据分析系统">
            <div class="content-tab1">
              <div class="content-tab1-title">
                <img src="../assets/数据分析1.png" alt="" />
                <p>数据分析系统</p>
              </div>
              <div class="content-tab1-msg">
                依托大屏展示设备，通过大屏的可视化展示，将涉及职业技能人才服务的业务数据进行抽取并利用GIS、图表等技术，对人才分布、人才结构、人才需求、业务办理、服务监控、决策支持等相关分析结果进行直观、动态的大屏可视化展现，为领导分析决策、指挥调度提供可视化、智能化提供支持。提高政府对人才的宏观管理水平和专业化和精细化的管理能力。
              </div>
            </div>
            <img class="positionImg2" src="../assets/2.png" alt="" />
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { GetConnection } from "../api/home";
export default {
  name: "MobileSupervise",
  data() {
    return {
      active: 0,
      active1: 0,
      activeName: "1",
      active2: 0,
      scrollList: [],
      scrollList1: [],
      tabActive: true,
      activeName1: "1",
      activeName2: "1",
    };
  },
  methods: {
    goToProduct() {
      this.$router.push("/mobileProduct?active=" + this.active);
    },
    onClick(name, title) {
      this.activeName = "1";
    },
    getData() {
      GetConnection()
        .then((res) => {
          this.scrollList = res;
          // this.scrollList1 = res
        })
        .catch((err) => {});
    },
  },
  mounted() {
    if (this.$route.query.active) {
      this.active = Number(this.$route.query.active);
    }
    this.getData();
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
    defaultOption1() {
      return {
        step: 1,
        limitMoveNum: this.scrollList1.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
.header-bg {
  width: 100%;
  height: 200px;
  background: url(../assets/产品服务-bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}
.top >>> .van-tabs__nav--card {
  /* height: 40px; */
  margin: 0;
  border: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab {
  background: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border-right: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab.van-tab--active {
  background: rgba(227, 25, 25, 1);
}
.content {
  /* width: 100%; */
  padding: 0 16px;
}
.content .content-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin: 20px 0;
}
.content .content-title1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  margin-bottom: 30px;
  line-height: 28px;
}
.content .content-title2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0 0 10px 0;
}
.content .content-img {
  width: 100%;
  height: 225px;
  margin-bottom: 10px;
}
.content .content-title3 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 21px;
  margin: 0;
}
.content .titile-p1 {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin: 20px 0;
}
.jianjie-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
  color: rgba(211, 26, 26, 1);
}
.content .titile-p2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  text-indent: 2em;
}
.tab-content {
  width: 100%;
  height: 176px;
  position: relative;
}
.tab-content .img {
  position: absolute;
  width: 100%;
  /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
  z-index: 1;
}
.tab-content .img1 {
  position: absolute;
  width: 100%;
  /* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
  z-index: 2;
}
.tab-content .tab-content-title {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
  color: #fff;
}
.tab-content .tab-content-title .p1 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.tab-content .tab-content-title .tab-content-msg {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  margin-right: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out .point-inset {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
.hexin >>> .van-cell__title div {
  display: flex;
  align-items: center;
}
.hexin >>> .van-cell__title div img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.gongneng >>> .van-tabs__line {
  width: 100px;
}
.content-flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-flex .content-flex-box {
  width: calc(48% - 20px);
  /* width: 145px; */
  height: 200px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #ccc;
}
.content-flex .content-flex-box img {
  width: 70px;
  height: 70px;
}
.content-flex .content-flex-box .p1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-flex .content-flex-box .p2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content1 {
  height: 200px;
  padding: 30px 16px;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-line {
  width: calc(100% - 32px);
  margin: 0 auto;
  height: 110px;
  background: #fff;
  margin-top: -55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-line-inset {
  /* width: calc(100% - 160px);
    height: 260px; */
  width: calc(100% - 24px);
  height: 65%;
}
.content-box {
  width: calc(100% - 32px);
  margin: 0 auto;
}
.content-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-box .content-box-inset {
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
}
.content-box .content-box-inset img {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.content-box .content-box-inset .content-box-inset-right {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-box .content-box-inset .content-box-inset-right .p1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-box .content-box-inset .content-box-inset-right .p2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
}
.content2 {
  /* height: 500px; */
  padding: 30px 16px;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 10px 0;
  background: #fff;
}
.content-tab .content-tab-box {
  width: 33.33%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-tab .content-tab-box1 {
  width: 50%;
}
.content-tab .content-tab-box img {
  width: 45px;
  height: 45px;
}
.content-tab .content-tab-box .p1 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-top: 14px;
  margin-bottom: 10px;
}
.content-tab .content-tab-box .p2 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content-tab2 {
  /* height: 260px; */
  padding: 30px 16px 10px 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content-tab2 .content-tab-box {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-tab2 .content-tab-box img {
  width: 18px;
  height: 18px;
  margin-right: 16px;
}
.content-tab2 .content-tab-box .p3 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.baozhang >>> .van-tabs__nav {
  background-color: rgba(0, 0, 0, 0);
}
.baozhang >>> .van-tab__text {
  font-size: 14px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}
.baozhang >>> .van-tabs__line {
  width: 60px;
  background-color: #fff;
}
.scroll {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  min-width: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  width: 132px;
  /* height: 80px; */
  height: 98px;
  position: relative;
  margin-right: 10px;
}
.partner-box .partner-box-flex .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 132px;
  height: 73px;
  z-index: 1;
}
.partner-box .partner-box-flex .img1 {
  position: absolute;
  top: 5px;
  left: 10px;
  /* width: 110px;
    height: 40px; */
  width: 112px;
  height: 63px;
  z-index: 2;
}
.partner-box .partner-box-flex p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.content-num {
  position: relative;
}
.title-margin {
  margin-bottom: 20px;
}
.content-margin {
  margin-top: 30px;
}
.content .num-title1 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 70px;
}
.content .num-title2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
}
.content-num .number {
  font-size: 60px;
  font-weight: 700;
  color: transparent;
  background: linear-gradient(
    180deg,
    rgba(34, 101, 231, 0.3) 0%,
    rgba(35, 101, 232, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  position: absolute;
  left: 0;
  top: 0;
}
.content .num-img {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content3 {
  background: rgba(247, 250, 255, 1);
  padding: 20px 16px;
}
.content .num-img1 {
  width: 100%;
  height: 225px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img2 {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img3 {
  width: 100%;
  height: 135px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content4 {
  padding: 20px 16px;
  background: url(../assets/image3.png) no-repeat;
  background-size: 100% 100%;
}
.content4 .number {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.content4 .num-title1,
.content4 .num-title2 {
  color: #fff;
}
.content .num-img4 {
  width: 100%;
  height: 170px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img5 {
  width: 100%;
  height: 55px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content-num-flex {
  margin-bottom: 10px;
  height: 240px;
  display: flex;
  justify-content: space-between;
}
.content-num-flex .content-num-flex-box {
  width: 30%;
  height: 240px;
  box-shadow: 0px 0px 6px 0px rgba(56, 128, 195, 0.25);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top img {
  width: 30px;
  height: 30px;
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top1 {
  background: rgba(144, 189, 53, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top2 {
  background: rgba(222, 161, 49, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top3 {
  background: rgba(67, 184, 164, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-bottom {
  width: calc(100% - 20px);
  height: 140px;
  padding: 10px;
}
.content-num-flex
  .content-num-flex-box
  .content-num-flex-box-bottom
  .content-num-flex-box-bottom-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content .num-img6 {
  width: 100%;
  height: 110px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img7 {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img8 {
  width: 100%;
  height: 220px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img9 {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img10 {
  width: 100%;
  height: 260px;
  margin-top: 10px;
}
.content .num-img11 {
  width: 100%;
  height: 130px;
  margin-top: -100px;
}
.content5 {
  padding: 20px 16px 0 16px;
  background: url(../assets/image13.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 5px);
  height: 60px;
  /* margin-right: 10px; */
  border-radius: 8px;
  background: rgba(247, 250, 255, 1);
  margin-bottom: 40px;
}
.content-box-tab:hover {
  cursor: pointer;
}
.content-box-tab img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.content-box-tab p {
  font-size: 16px;
  font-weight: 700;
  color: rgba(102, 102, 102, 1);
}
.content-box-tab-active {
  position: relative;
  background: url(../assets/蒙版分组-选中.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab-active p {
  color: #fff;
}
.content-box-tab-active:before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  content: "";
  border-color: rgba(136, 183, 213, 0);
}
.content-box-tab-active.up:before {
  border-top-color: rgba(7, 79, 224, 1);
  border-width: 12px;
  left: 49%;
  margin-left: -10px;
  top: 100%;
}
.content .content-box2 {
  width: 100%;
  padding-top: 20px;
  background: rgba(247, 250, 255, 1);
}
.content .content-box2 img {
  width: 100%;
  height: 250px;
  margin-top: 10px;
}
.content .content-box2 .content-box-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .content-box2 .content-box-right .content-box-right-tag {
  background: rgba(34, 101, 231, 1);
  width: 90px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.content .content-box2 .content-box-right .content-box-right-title {
  margin: 10px 0 15px 0;
  font-size: 24px;
  font-weight: 500;
  color: rgba(34, 101, 231, 1);
}
.content .content-box2 .content-box-right .content-box-right-msg {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 28px;
}
.tab-content1 {
  width: calc(100% - 24px);
  height: 260px;
  padding: 20px 12px;
  background: url(../assets/监管平台-bg.png) no-repeat;
  background-size: 100% 100%;
}
.tab-content1 .tab-content1-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tab-content1 .tab-content1-title img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.tab-content1 .tab-content1-title p {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.tab-content1 .tab-content1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.content6 {
  margin-top: 30px;
  margin-bottom: 100px;
  width: 100%;
  height: 540px;
  background: url(../assets/jianguan.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab1 {
  padding: 20px;
}
.content-tab1 .content-tab1-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.content-tab1 .content-tab1-title img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.content-tab1 .content-tab1-title p {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.content-tab1 .content-tab1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.positionImg {
  width: 100%;
  height: 300px;
  margin-top: 50px;
}
.positionImg1 {
  width: 100%;
  height: 300px;
  margin-top: 50px;
}
.positionImg2 {
  width: 100%;
  height: 300px;
}
.content .img-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
}
.content .img-box .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content .img-box .img-box-inset img {
  width: 50px;
  height: 50px;
}
.content .img-box .img-box-inset p {
  font-size: 16px;
  line-height: 56px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
}
.content .img-box1 .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  /* align-items: center; */
  /* width: 165px; */
  width: 48%;
  height: 214px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.content .img-box1 .img-box-inset img {
  /* width: 165px; */
  width: 100%;
  height: 134px;
}
.content .img-box1 .img-box-inset .p {
  padding: 0 12px;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box1 .img-box-inset .p1 {
  padding: 0 12px;
  font-size: 10px;
  color: rgba(153, 153, 153, 1);
}
.content .img-box1 .img-box-inset .mark {
  display: flex;
  padding: 0 12px;
  margin: 10px 0;
  align-items: center;
}
.content .img-box1 .img-box-inset .mark .mark-left {
  width: 15px;
  height: 3px;
  background: rgba(228, 30, 30, 1);
}
.content .img-box1 .img-box-inset .mark .mark-right {
  width: 15px;
  height: 3px;
  background: rgba(51, 51, 51, 1);
}
.shixun-box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shixun-box .shixun-img {
  width: 343px;
  height: 184px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.shixun-box .shixun-img1 {
  width: 343px;
  height: 240px;
}
.shixun-box .shixun-inset .shixun-top {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.shixun-box .shixun-inset .shixun-middle {
  font-size: 14px;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  margin: 15px 0;
}
.shixun-box .shixun-inset .shixun-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box {
  width: 108px;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(255, 240, 240, 1) 0%,
    rgba(255, 240, 240, 0.01) 100%
  );
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box img {
  width: 14px;
  height: 14px;
  margin: 6px;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box p {
  font-size: 12px;
  color: rgba(51, 51, 51, 1);
}
.flex-box {
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.flex-box .flex-box-inset {
  margin-top: 20px;
  padding-bottom: 12px;
  width: 48%;
  height: 204px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-box .flex-box-inset .p1 {
  align-self: start;
  padding: 0 12px;
  font-size: 14px;
  line-height: 40px;
  color: rgba(51, 51, 51, 1);
}
.flex-box .flex-box-inset .p2 {
  align-self: start;
  padding: 0 12px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
}
.flex-box .flex-box-inset img {
  width: calc(100% - 24px);
  height: 113px;
}
</style>
