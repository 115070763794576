import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueBus from "vue-bus";
Vue.use(VueBus);

// 引入全局样式
import "./styles/main.css";

// 引入swiper
import "swiper/css/swiper.css";

// 引入自适应
import "lib-flexible";

// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 引入vue-seamless-scroll
import scroll from "vue-seamless-scroll";
Vue.use(scroll);

// 引入vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 引入百度地图
// import BaiduMap from "vue-baidu-map";
// Vue.use(BaiduMap, {
//   ak: "krAyGB8A6cr7liSuPcqYbDBYxqpwT3Kw",
// });

// 设置title
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

// 添加通用方法
import path from "./utils/public";
Vue.prototype.$path = path;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
