import Vue from "vue";
import VueRouter from "vue-router";
// PC
import Home from "../views/Home.vue";
import News from "../views/News.vue";
import NewsDetail from "../views/NewsDetail.vue";
import Product from "../views/Product.vue";
import Cultivate from "../views/Cultivate.vue";
import Appraise from "../views/Appraise.vue";
import Supervise from "../views/Supervise.vue";
import KaowuFuwu from "../views/KaowuFuwu.vue";
import Yingjian from "../views/Yingjian.vue";
import Shixun from "../views/Shixun.vue";
import Custom from "../views/Custom.vue";
import CustomDetail from "../views/CustomDetail.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
// Mobile
import Mobile from "../views/Mobile.vue";
import MobileNews from "../views/MobileNews.vue";
import MobileNewsDetail from "../views/MobileNewsDetail.vue";
import MobileProduct from "../views/MobileProduct.vue";
import MobileCultivate from "../views/MobileCultivate.vue";
import MobileAppraise from "../views/MobileAppraise.vue";
import MobileSupervise from "../views/MobileSupervise.vue";
import MobileKaowuFuwu from "../views/MobileKaowuFuwu.vue";
import MobileYingjian from "../views/MobileYingjian.vue";
import MobileShixun from "../views/MobileShixun.vue";
import MobileCustom from "../views/MobileCustom.vue";
import MobileCustomDetail from "../views/MobileCustomDetail.vue";
import MobileAbout from "../views/MobileAbout.vue";
import MobileContact from "../views/MobileContact.vue";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "首页",
      keepAlive: true,
    },
    component: Home,
    children: [
      {
        path: "/news",
        name: "News",
        meta: {
          title: "资讯动态",
        },
        component: News,
        children: [
          {
            path: "/newsDetail",
            name: "NewsDetail",
            meta: {
              title: "资讯动态详情",
            },
            component: NewsDetail,
          },
        ],
      },
      {
        path: "/product",
        name: "Product",
        meta: {
          title: "产品服务",
        },
        component: Product,
        children: [
          {
            path: "/cultivate",
            name: "Cultivate",
            meta: {
              title: "技能培训",
            },
            component: Cultivate,
          },
          {
            path: "/appraise",
            name: "Appraise",
            meta: {
              title: "考务平台",
            },
            component: Appraise,
          },
          {
            path: "/supervise",
            name: "Supervise",
            meta: {
              title: "监管平台",
            },
            component: Supervise,
          },
          {
            path: "/kaowuFuwu",
            name: "KaowuFuwu",
            meta: {
              title: "考务服务",
            },
            component: KaowuFuwu,
          },
          {
            path: "/yingjian",
            name: "Yingjian",
            meta: {
              title: "硬件及耗材",
            },
            component: Yingjian,
          },
          {
            path: "/shixun",
            name: "Shixun",
            meta: {
              title: "智能实训",
            },
            component: Shixun,
          },
        ],
      },
      {
        path: "/custom",
        name: "Custom",
        meta: {
          title: "客户案例",
        },
        component: Custom,
        children: [
          {
            path: "/customDetail",
            name: "CustomDetail",
            meta: {
              title: "客户案例详情",
            },
            component: CustomDetail,
          },
        ],
      },
      {
        path: "/about",
        name: "About",
        meta: {
          title: "关于我们",
        },
        component: About,
      },
      {
        path: "/contact",
        name: "Contact",
        meta: {
          title: "联系我们",
        },
        component: Contact,
      },
    ],
  },
  {
    path: "/mobile",
    name: "Mobile",
    meta: {
      title: "首页",
    },
    component: Mobile,
    children: [
      {
        path: "/mobileNews",
        name: "MobileNews",
        meta: {
          title: "资讯动态",
        },
        component: MobileNews,
        children: [
          {
            path: "/mobileNewsDetail",
            name: "MobileNewsDetail",
            meta: {
              title: "资讯动态详情",
            },
            component: MobileNewsDetail,
          },
        ],
      },
      {
        path: "/mobileProduct",
        name: "MobileProduct",
        meta: {
          title: "产品服务",
        },
        component: MobileProduct,
      },
      {
        path: "/mobileCultivate",
        name: "MobileCultivate",
        meta: {
          title: "技能培训",
        },
        component: MobileCultivate,
      },
      {
        path: "/mobileAppraise",
        name: "MobileAppraise",
        meta: {
          title: "考务平台",
        },
        component: MobileAppraise,
      },
      {
        path: "/mobileSupervise",
        name: "MobileSupervise",
        meta: {
          title: "监管平台",
        },
        component: MobileSupervise,
      },
      {
        path: "/mobileKaowuFuwu",
        name: "MobileKaowuFuwu",
        meta: {
          title: "考务服务",
        },
        component: MobileKaowuFuwu,
      },
      {
        path: "/mobileYingjian",
        name: "MobileYingjian",
        meta: {
          title: "硬件及耗材",
        },
        component: MobileYingjian,
      },
      {
        path: "/mobileShixun",
        name: "MobileShixun",
        meta: {
          title: "智能实训",
        },
        component: MobileShixun,
      },
      {
        path: "/mobileCustom",
        name: "MobileCustom",
        meta: {
          title: "客户案例",
        },
        component: MobileCustom,
        children: [
          {
            path: "/mobilecustomDetail",
            name: "MobileCustomDetail",
            meta: {
              title: "客户案例详情",
            },
            component: MobileCustomDetail,
          },
        ],
      },
      {
        path: "/mobileAbout",
        name: "MobileAbout",
        meta: {
          title: "关于我们",
        },
        component: MobileAbout,
      },
      {
        path: "/mobileContact",
        name: "MobileContact",
        meta: {
          title: "联系我们",
        },
        component: MobileContact,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash", // "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
