<template>
  <div class="news">
    <template v-if="$route.path == '/news'">
      <div class="header-bg">
        <p class="p1">资讯动态</p>
        <p class="p2">
          专注于职业技能领域数字化信息服务，致力于成为一流的职业技能领域数字信息化综合服务商
        </p>
      </div>
      <el-tabs
        v-if="newsTab.length > 0"
        class="news-tab"
        v-model="diccId"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in newsTab"
          :key="index"
          :label="item.diccName"
          :name="String(item.diccId)"
        ></el-tab-pane>
      </el-tabs>
      <div class="content">
        <div class="content-box">
          <!-- (item, index) in newsArr.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            ) -->
          <div
            class="content-box-flex-box"
            v-for="(item, index) in newsArr"
            :key="index"
            @click="goToNewsDetail(item)"
          >
            <div class="content-box-flex">
              <img
                v-if="item.newsPict"
                :src="
                  $path.path + '/src/newsInfoUploadAddress/' + item.newsPict
                "
                alt=""
              />
              <img v-else src="../assets/资讯动态.png" alt="" />
              <div class="content-box-flex-right">
                <p class="p1">{{ item.newsTitle }}</p>
                <p class="p2">{{ item.newsTime }} | {{ item.diccName }}</p>
                <p class="p3">
                  {{ item.newsdisc }}
                </p>
              </div>
            </div>
          </div>
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :total="count"
            :page-size="pageSize"
            prev-text="上一页"
            next-text="下一页"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </template>
    <router-view v-else />
  </div>
</template>
<script>
import {
  GetNewsInfo,
  UpdateNewsInfoTotal,
  SelectDictionary,
  SelectDicContent,
} from "../api/home";
export default {
  name: "News",
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      newsArr: [],
      count: 0,
      diccId: 0,
      newsTab: [],
    };
  },
  methods: {
    currentChange(e) {
      this.currentPage = e;
      this.getData();
    },
    goToNewsDetail(item) {
      let data = {
        newsIdJson: this.$path.Encrypt(item.newsId),
      };
      UpdateNewsInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/newsDetail",
            query: { newsId: item.newsId },
          });
        })
        .catch((err) => {});
    },
    getData() {
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        diccId: Number(this.diccId),
      };
      GetNewsInfo(params)
        .then((res) => {
          this.newsArr = res.list;
          this.count = res.count;
        })
        .catch((err) => {});
    },
    getNewsTab() {
      let params = {
        dictName: "资讯类型",
      };
      SelectDictionary(params)
        .then((res) => {
          res.forEach((e) => {
            if (e.dictName == "资讯类型") {
              let para = {
                page: 1,
                limit: 99999,
                diccId: this.$path.Encrypt(e.dictId),
              };
              SelectDicContent(para)
                .then((res) => {
                  this.newsTab = res;
                  let obj = {
                    diccName: "全部",
                    diccId: 0,
                  };
                  this.newsTab.unshift(obj);
                })
                .catch((err) => {});
            }
          });
        })
        .catch((err) => {});
    },
    handleClick(tab, event) {
      this.$router.push("/news?diccId=" + this.diccId);
      this.getData();
    },
  },
  mounted() {
    if (this.$route.query.diccId) {
      this.diccId = this.$route.query.diccId;
    }
    this.getNewsTab();
    this.getData();
  },
  watch: {
    "$route.query.diccId": function () {
      this.diccId = this.$route.query.diccId;
      this.getData();
    },
  },
};
</script>
<style scoped>
.news {
  min-height: 100vh;
}
.header-bg {
  width: 100%;
  height: 400px;
  background: url(../assets/资讯动态-banner.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 522px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(102, 102, 102, 1);
}
.content {
  /* width: 100%; */
  /* padding: 0 10%; */
  min-width: 1280px;
}
.content .content-box {
  /* width: 100%;
  min-width: 860px; */
  width: 1280px;
  margin: 0 auto;
  padding: 0 0 60px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.content-box-flex-box {
  width: 100%;
  margin-top: 40px;
}
.content .content-box .content-box-flex {
  display: flex;
  align-items: center;
}
.content .content-box .content-box-flex img {
  width: 320px;
  height: 180px;
  margin-right: 40px;
}
.content .content-box .content-box-flex .content-box-flex-right {
  width: calc(100% - 360px);
}
.content .content-box .content-box-flex .content-box-flex-right .p1 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content .content-box .content-box-flex .content-box-flex-right .p2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  margin: 20px 0;
  color: rgba(153, 153, 153, 1);
}
.content .content-box .content-box-flex .content-box-flex-right .p3 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 32px;
  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.pagination {
  margin: 0 auto;
  margin-top: 60px;
}
.content .content-box .content-box-flex-box:hover {
  cursor: pointer;
}
.content .content-box .content-box-flex-box:hover .content-box-flex {
  box-shadow: 0px 0px 6px 0px #ccc;
  animation: btn-top linear 0.1s forwards;
}
@keyframes btn-top {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
}
.content .content-box .content-box-flex-box:hover .content-box-flex-right .p1 {
  color: rgba(227, 25, 25, 1);
}
>>> .el-pager li {
  min-width: 0;
  width: 28px;
  color: #999;
}
>>> .el-pager li.active {
  color: #e31919;
  border: 1px solid #e31919;
  border-radius: 50%;
}
>>> .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
>>> .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
>>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-bottom: 1px solid #e4e7ed;
}
.news-tab {
  margin-top: 60px;
}
.news-tab >>> .el-tabs__item {
  width: 180px;
  height: 44px;
  background: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}
.news-tab >>> .is-active {
  background: rgba(228, 30, 30, 1);
  color: rgba(255, 255, 255, 1);
}
</style>
