<template>
  <div class="mobileNews">
    <template v-if="$route.path == '/mobileNews'">
      <div class="header-bg">
        <p class="p1">资讯动态</p>
        <p class="p2">
          专注于职业技能领域数字化信息服务，致力于成为一流的职业技能领域数字信息化综合服务商
        </p>
      </div>
      <van-tabs
        v-if="newsTab.length > 0"
        class="top"
        v-model="active"
        type="card"
        :ellipsis="false"
        @click="onClick"
      >
        <van-tab
          v-for="(item, index) in newsTab"
          :key="index"
          :title="item.diccName"
        ></van-tab>
      </van-tabs>
      <div class="content">
        <div class="content-box">
          <div
            class="content-box-flex"
            v-for="(item, index) in newsArr"
            :key="index"
            @click="goToNewsDetail(item)"
          >
            <div class="content-box-flex-right">
              <p class="p1">{{ item.newsTitle }}</p>
              <p class="p2">{{ item.newsTime }} | {{ item.diccName }}</p>
              <p class="p3">
                {{ item.newsdisc }}
              </p>
            </div>
          </div>
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :total="count"
            :page-size="pageSize"
            prev-text="上一页"
            next-text="下一页"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </template>
    <router-view v-else />
  </div>
</template>
<script>
import {
  GetNewsInfo,
  UpdateNewsInfoTotal,
  SelectDictionary,
  SelectDicContent,
} from "../api/home";
export default {
  name: "MobileNews",
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      newsArr: [],
      newsTab: [],
      active: 0,
      diccId: 0,
      count: 0,
    };
  },
  methods: {
    currentChange(e) {
      this.currentPage = e;
      this.getData();
    },
    goToNewsDetail(item) {
      let data = {
        newsIdJson: this.$path.Encrypt(item.newsId),
      };
      UpdateNewsInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/mobileNewsDetail",
            query: { newsId: item.newsId },
          });
        })
        .catch((err) => {});
    },
    getData() {
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        diccId: this.diccId,
      };
      GetNewsInfo(params)
        .then((res) => {
          this.newsArr = res.list;
          this.count = res.count;
        })
        .catch((err) => {});
    },
    getNewsTab() {
      let params = {
        dictName: "资讯类型",
      };
      SelectDictionary(params)
        .then((res) => {
          res.forEach((e) => {
            if (e.dictName == "资讯类型") {
              let para = {
                page: 1,
                limit: 99999,
                diccId: this.$path.Encrypt(e.dictId),
              };
              SelectDicContent(para)
                .then((res) => {
                  this.newsTab = res;
                  let obj = {
                    diccName: "全部",
                    diccId: 0,
                  };
                  this.newsTab.unshift(obj);
                  if (this.$route.query.diccId) {
                    this.diccId = this.$route.query.diccId;
                    this.newsTab.forEach((e, i) => {
                      if (this.diccId == e.diccId) {
                        this.active = i;
                      }
                    });
                  } else {
                    this.diccId = this.newsTab[0].diccId;
                  }
                  this.getData();
                })
                .catch((err) => {});
            }
          });
        })
        .catch((err) => {});
    },
    onClick() {
      this.diccId = this.newsTab[this.active].diccId;
      this.getData();
      this.$router.push("/mobileNews?diccId=" + this.diccId);
    },
  },
  mounted() {
    this.getNewsTab();
  },
};
</script>
<style scoped>
.top >>> .van-tabs__nav--card {
  /* height: 40px; */
  margin: 0;
  border: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab {
  background: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border-right: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab.van-tab--active {
  background: rgba(227, 25, 25, 1);
}
.mobileNews {
  min-height: 100vh;
}
.header-bg {
  width: 100%;
  height: 200px;
  background: url(../assets/资讯动态-bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}
.content {
  /* width: 100%; */
  padding: 0 16px;
}
.content .content-box {
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.content .content-box .content-box-flex {
  width: 100%;
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #f5f5f5;
}
.content .content-box .content-box-flex .content-box-flex-right {
  width: 100%;
}
.content .content-box .content-box-flex .content-box-flex-right .p1 {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content .content-box .content-box-flex .content-box-flex-right .p2 {
  font-size: 14px;
  font-weight: 400;
  margin: 20px 0;
  color: rgba(153, 153, 153, 1);
}
.content .content-box .content-box-flex .content-box-flex-right .p3 {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 32px; */
  color: rgba(153, 153, 153, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pagination {
  margin: 0 auto;
  margin-top: 30px;
}
.content .content-box .content-box-flex:hover {
  box-shadow: 0px 0px 6px 0px #ccc;
  cursor: pointer;
}
.content .content-box .content-box-flex:hover .content-box-flex-right .p1 {
  color: rgba(227, 25, 25, 1);
}
>>> .el-pager li {
  min-width: 0;
  width: 28px;
  color: #999;
}
>>> .el-pager li.active {
  color: #e31919;
  border: 1px solid #e31919;
  border-radius: 50%;
}
</style>
