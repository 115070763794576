<template>
  <div class="mobileProduct">
    <!-- <div class="header-bg">
      <p class="p1">产品服务</p>
      <p class="p2">专注、聚焦、深耕</p>
    </div> -->
    <!-- sticky -->
    <!-- <van-tabs
      v-if="$route.path == '/mobileProduct'"
      class="top"
      v-model="active"
      type="card"
      :ellipsis="false"
      @click="goToProduct"
    > -->
      <!-- <van-tab title="技能培训"> -->
      <template v-if="$route.path == '/mobileProduct' && active == 0">
        <div class="content content-margin">
          <p class="jianjie-title">鸿新云在线学习平台</p>
          <div class="titile-p2">
            由重庆鸿新在线信息技术有限公司开发，面向职业教培领域，专注于职业技能、技术人才培养的在线学习平台。平台有丰富的课程和题库资源，精彩的视频学习、海量的题库练习，汇集200+职业(工种)、3000+精选优质课程资源；为学员创造最佳的学习体验。
            <p class="titile-p2">
              鸿新云致力于提供职业教育一站式学习平台，是提升技能知识、提高职业技能的专业平台。
            </p>
          </div>
        </div>
        <div class="flex-box">
          <div class="flex-box-inset">
            <p class="p1">内容形式多样</p>
            <p class="p2">讲义、视频、练习、测试等</p>
            <img src="../assets/内容形式多样.png" alt="">
          </div>
          <div class="flex-box-inset">
            <p class="p1">课程全面</p>
            <p class="p2">海量题库，进入鸿新云公众号即能开始学习</p>
            <img src="../assets/课程全面.png" alt="">
          </div>
          <div class="flex-box-inset">
            <p class="p1">无场景限制</p>
            <p class="p2">充分利用碎片时间，随时随地学习</p>
            <img src="../assets/无场景限制.png" alt="">
          </div>
          <div class="flex-box-inset">
            <p class="p1">无设备限制</p>
            <p class="p2">使用手机、电脑、平板随时随机学习，“一机在手，学习无忧”</p>
            <img src="../assets/无设备限制.png" alt="">
          </div>
        </div>
        <div class="content">
          <div class="content-title">核心功能</div>
          <van-tabs
            v-if="$route.path == '/mobileProduct' && active == 0"
            class="gongneng"
            v-model="active1"
            :ellipsis="false"
            @click="onClick"
          >
            <van-tab title="多样化培训方式">
              <van-collapse class="hexin" v-model="activeName" accordion>
                <van-collapse-item name="1">
                  <template #title>
                    <div>
                      <img src="../assets/在线课程.png" alt="" /> 在线课程
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">在线课程</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        不限容量课程库，自由设定课程分类
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        随时随地学习，知识技能触手可及
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="2">
                  <template #title>
                    <div>
                      <img src="../assets/学习计划.png" alt="" /> 学习计划
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">学习计划</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        集线上线下课程/作业/考试/导师辅导为一体的综合性学习任务
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        脉络清晰，层次分明
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        自动化推送，让学员第一时间获取所需内容
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="3">
                  <template #title>
                    <div>
                      <img src="../assets/o2o混合培训.png" alt="" />
                      O2O混合培训
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">O2O混合培训</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        线上线下学考练一体，打造多元化的培训内容
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        在线报名/通知/签到等功能，满足精细化学员管理
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="4">
                  <template #title>
                    <div>
                      <img src="../assets/直播课堂.png" alt="" /> 直播课堂
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">直播课堂</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        多种教学工具与直播互动方式，让课堂生动有趣
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        自动录播，一键生成在线课程，让做课轻而易举
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="5">
                  <template #title>
                    <div>
                      <img src="../assets/线下培训.png" alt="" /> 线下培训
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">线下培训</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        培训开班、通知、协议签署一站式解决
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        学员签到等数字化管理，让培训经理有迹可循
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-tab>
            <van-tab title="智能化便捷运营">
              <van-collapse class="hexin" v-model="activeName" accordion>
                <van-collapse-item name="1">
                  <template #title>
                    <div>
                      <img src="../assets/多端多角色.png" alt="" />
                      多端多角色专属视图，学习管理高效便捷
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">多端多角色专属视图，学习管理高效便捷</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        学习管理：学习情况随时查看，培训进度尽在掌握
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        学员学习：界面布局清晰明了，功能入口操作简单
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="2">
                  <template #title>
                    <div>
                      <img src="../assets/智能运营工具.png" alt="" />
                      智能运营工具，摆脱重复性工作
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">智能运营工具，摆脱重复性工作</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        在线课程一键生成课程封面，提升工作效率
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        面授课程集中管理，一次配置，重复引用
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        在线学习计划配置规则，自动推送给规则内学员
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        在线考试题库、试卷库，试卷无需重复编写，支持多规则自动组卷
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="3">
                  <template #title>
                    <div>
                      <img src="../assets/数据安全.png" alt="" />
                      培训规则随心配，数据安全有保障
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">培训规则随心配，数据安全有保障</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        学习任务设定可见范围，保密资源不泄露
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        学员学习防录屏，有效抵御数据外流风险
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        管理员权限自由配置，层层验证，范围清洗
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-tab>
            <van-tab title="全方位学习激励">
              <van-collapse class="hexin" v-model="activeName" accordion>
                <van-collapse-item name="1">
                  <template #title>
                    <div>
                      <img src="../assets/激励体系.png" alt="" />
                      全面的激励体系
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">全面的激励体系</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        学习、授课或参与互动均可获得学习积分
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        完成学习任务，可获得特定在线勋章奖励
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        支持在线向学员发放学习证书
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="2">
                  <template #title>
                    <div>
                      <img src="../assets/学习排行.png" alt="" />
                      令人瞩目的学习排行
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">令人瞩目的学习排行</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        展示学员学习排行榜，学习动态一目了然
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        展示学员学习积分榜，调动学员好胜心
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="3">
                  <template #title>
                    <div>
                      <img src="../assets/游戏化学习.png" alt="" />
                      趣味横生的游戏化学习
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">趣味横生的游戏化学习</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        寓教于乐，在趣味闯关中学习知识
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        活泼精美的动画设计，打造轻松愉快的学习氛围
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-tab>
            <van-tab title="精细化数据分析">
              <van-collapse class="hexin" v-model="activeName" accordion>
                <van-collapse-item name="1">
                  <template #title>
                    <div>
                      <img src="../assets/数据闭环.png" alt="" />
                      全流程数据闭环管理
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">全流程数据闭环管理</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        任务进度、学员档案、收口记录等信息
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        培训进展清洗展示
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        多项数据分析报表支撑，覆盖培训运营全流程
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        数据明细一网打尽
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="2">
                  <template #title>
                    <div>
                      <img src="../assets/数据分析.png" alt="" />
                      学习数据智能分析
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">学习数据智能分析</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        问卷结果，考试成绩智能分析
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        重要数据一目了然，省心省力
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="3">
                  <template #title>
                    <div>
                      <img src="../assets/可视化报表.png" alt="" />
                      可视化报表自由可配
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">可视化报表自由可配</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        内置报表设计器，个性报表自由配置
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        随时随地可学，知识技能触手可及
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
                <van-collapse-item name="4">
                  <template #title>
                    <div>
                      <img src="../assets/数据变动.png" alt="" />
                      数据变动尽在掌握
                    </div>
                  </template>
                  <div class="tab-content">
                    <img class="img" src="../assets/内容图片.png" alt="" />
                    <img
                      class="img1"
                      src="../assets/文字下方遮罩层.png"
                      alt=""
                    />
                    <div class="tab-content-title">
                      <p class="p1">数据变动尽在掌握</p>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        支持开启数据订阅功能
                      </div>
                      <div class="tab-content-msg">
                        <div class="point-out">
                          <div class="point-inset"></div>
                        </div>
                        数据更新自动通知，随时掌握数据动态
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-tab>
          </van-tabs>
        </div>
        <div class="content">
          <div class="content-title">个性化系统设置，学习平台品牌自由</div>
          <div class="content-title1">
            高度自定义，无需定制，随时修改培训平台机构信息
          </div>
          <div class="content-flex">
            <div class="content-flex-box">
              <img src="../assets/企业信息设置.png" alt="" />
              <p class="p1">企业信息设置</p>
              <p class="p2">企业logo、企业slogan、专属域名、23项企业设置</p>
            </div>
            <div class="content-flex-box">
              <img src="../assets/个性化设置.png" alt="" />
              <p class="p1">个性化设置</p>
              <p class="p2">
                图标设置、图片设置、模块设置、颜色设置、自定义消息内容
              </p>
            </div>
            <div class="content-flex-box">
              <img src="../assets/储存管理.png" alt="" />
              <p class="p1">储存管理</p>
              <p class="p2">素材库、知识库、独立课件服务器</p>
            </div>
            <div class="content-flex-box">
              <img src="../assets/学习配置.png" alt="" />
              <p class="p1">学习配置</p>
              <p class="p2">专业设置、课程设置、试题设置、计划设置</p>
            </div>
          </div>
        </div>
        <div class="content content1">
          <div class="content-title content-title2">
            服务始终如一，工匠精神成就品牌
          </div>
          <div class="content-title content-title3">
            我们始终坚持“以客户为中心”，听客户所需，想客户所想。希望以优质的服务和品牌实力，助力客户提高培训效率，让培训更简单。
          </div>
        </div>
        <div class="content-line">
          <img
            class="content-line-inset"
            src="../assets/服务理念22.png.png"
            alt=""
          />
        </div>
        <div class="content">
          <div class="content-title">
            化繁为简，减少客户对培训平台的学习成本
          </div>
          <div class="content-title1">拒绝复杂操作对客户产生的负担</div>
          <div class="content-box">
            <div class="content-box-inset">
              <img src="../assets/多终端学习.png" alt="" />
              <div class="content-box-inset-right">
                <p class="p1">多终端学习</p>
                <p class="p2">
                  支持微信H5、电脑网页、APP、企业微信、钉钉、飞书等多端直接使用，灵活便捷
                </p>
              </div>
            </div>
            <div class="content-box-inset">
              <img src="../assets/小白式操作.png" alt="" />
              <div class="content-box-inset-right">
                <p class="p1">小白式操作</p>
                <p class="p2">
                  前后台功能操作便捷，各个功能逻辑清晰，极大降低人员学习成本
                </p>
              </div>
            </div>
            <div class="content-box-inset">
              <img src="../assets/3000+精品课程.png" alt="" />
              <div class="content-box-inset-right">
                <p class="p1">3000+精品课程</p>
                <p class="p2">
                  110+课程分类，覆盖多岗位、多行业，对企业自建培训课程体系进行补充
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="content content2">
          <div class="content-title content-title2">多重保障，稳定高效</div>
          <van-tabs
            v-if="$route.path == '/mobileProduct' && active == 0"
            class="baozhang"
            v-model="active2"
            title-inactive-color="#fff"
            title-active-color="#fff"
          >
            <van-tab title="运营数据">
              <div class="content-tab">
                <div class="content-tab-box">
                  <img src="../assets/服务机构.png" alt="" />
                  <p class="p1">500+</p>
                  <p class="p2">服务机构</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/服务人数.png" alt="" />
                  <p class="p1">500000+</p>
                  <p class="p2">服务人数</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/课程资源.png" alt="" />
                  <p class="p1">1500+</p>
                  <p class="p2">课程资源</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/考试人数.png" alt="" />
                  <p class="p1">300000+</p>
                  <p class="p2">考试人数</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/学习人数.png" alt="" />
                  <p class="p1">200000+</p>
                  <p class="p2">学习人数</p>
                </div>
              </div>
            </van-tab>
            <van-tab title="安全保障">
              <div class="content-tab content-tab1">
                <div class="content-tab-box content-tab-box1">
                  <img src="../assets/三级等保.png" alt="" />
                  <p class="p1">三级等保</p>
                  <p class="p2">已到达国家对非银行单位最高的安全标准</p>
                </div>
                <div class="content-tab-box content-tab-box1">
                  <img src="../assets/物理安全防护.png" alt="" />
                  <p class="p1">物理安全防护</p>
                  <p class="p2">腾讯云VPC专有网络独立内网，严格的SLA等级保护</p>
                </div>
                <div class="content-tab-box content-tab-box1">
                  <img src="../assets/数据自动备份.png" alt="" />
                  <p class="p1">数据自动备份</p>
                  <p class="p2">数据信息定期自动备份，云防护设备和措施</p>
                </div>
                <div class="content-tab-box content-tab-box1">
                  <img src="../assets/安全管理制度.png" alt="" />
                  <p class="p1">安全管理制度</p>
                  <p class="p2">规范的安全管理制度，严格的管理权限认证</p>
                </div>
              </div>
            </van-tab>
            <van-tab title="技术和服务">
              <div class="content-tab content-tab2">
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标1.png" alt="" />
                  <p class="p3">高并发及服务器集群运维能力</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标5.png" alt="" />
                  <p class="p3">SaaS版和混合云版系统免费升级</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标2.png" alt="" />
                  <p class="p3">第三方系统及应用的集成能力</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标6.png" alt="" />
                  <p class="p3">1V1资讯辅导、7x12小时及时响应</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标3.png" alt="" />
                  <p class="p3">开放的生态环境，共享资源，提供更多可能</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标7.png" alt="" />
                  <p class="p3">产品使用帮助、系统技术维护</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标4.png" alt="" />
                  <p class="p3">先进的系统研发技术、AI人工智能技术</p>
                </div>
                <div class="content-tab-box">
                  <img src="../assets/技术服务-图标8.png" alt="" />
                  <p class="p3">专属实施、运营服务及项目支持</p>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="content">
          <div class="content-title">
            已有200+客户选择我们，每个客户的选择都是一份肯定
          </div>
          <div class="content-title1">
            覆盖企业内训、教育培训、自媒体、内容电商、学校等众多领域
          </div>
          <vue-seamless-scroll
            v-if="scrollList.length >= 1"
            :data="scrollList"
            :class-option="defaultOption"
            class="scroll"
          >
            <ul class="partner-box">
              <li
                class="partner-box-flex"
                v-for="(item, index) in scrollList"
                :key="index"
              >
                <img class="img" src="../assets/矩形 1.png" alt="" />
                <img
                  class="img1"
                  v-if="item.connLogo"
                  :src="$path.path + '/src/customerFile/' + item.connLogo"
                  alt=""
                />
                <p>{{ item.connName }}</p>
              </li>
            </ul>
          </vue-seamless-scroll>
          <!-- <vue-seamless-scroll
            v-if="scrollList1.length >= 1"
            :data="scrollList1"
            :class-option="defaultOption1"
            class="scroll"
          >
            <ul class="partner-box">
              <li
                class="partner-box-flex"
                v-for="(item, index) in scrollList1"
                :key="index"
              >
                <img class="img" src="../assets/矩形 1.png" alt="" />
                <img class="img1" v-if="item.connLogo" :src="$path.path + '/src/customerFile/' + item.connLogo" alt="" />
                <p>{{ item.connName }}</p>
              </li>
            </ul>
          </vue-seamless-scroll> -->
        </div>
      </template>
      <!-- </van-tab> -->
      <!-- <van-tab title="考务平台"> -->
      <template v-if="$route.path == '/mobileProduct' && active == 1">
        <div class="content content-margin">
          <p class="jianjie-title">技能人才评价服务管理平台</p>
          <img class="content-img" src="../assets/技能人才评价.png" alt="" />
          <p class="titile-p2 title-margin">
            技能人才评价服务管理平台，该平台可形成从评价信息公示、评价过程管理、评价结果数据交互、相关工作信息（文件）保存的全流程可追溯信息化数据链，并与省市监管系统进行数据交互，实现技能等级认定全过程来源可查、去向可追、责任可究。机构还可根据自身规模与管理需要，实现模块定制开发。
          </p>
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">01</p>
            <p class="num-title1">考生在线服务平台</p>
          </div>
          <p class="num-title2">
            为机构提供技能人才评价门户服务网站，用于机构面向考生提供：评价范围公示、评价计划公告、考生在线报名、考前在线复习、信息在线查询、考评结果公示、在线留言反馈、监督举报查询等相关服务功能。
          </p>
          <img class="num-img" src="../assets/image.png" alt="" />
        </div>
        <div class="content content-margin content3">
          <div class="content-num">
            <p class="number">02</p>
            <p class="num-title1">技能等级认定工作管理系统</p>
          </div>
          <p class="num-title2">
            为机构规范开展职业技能等级认定工作提供工作管理、数据管理、信息化支持服务，提供职业技能等级认定工作一体化在线管理平台，实现评价计划编排、评价过程管理、评价结果数据提交、职业技能等级认定相关工作信息保存，形成可追溯信息化数据链，实现技能等级认定全过程来源可查、去向可追、责任可究。
          </p>
          <img class="num-img1" src="../assets/image1.png" alt="" />
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">03</p>
            <p class="num-title1">专项能力考核工作管理系统</p>
          </div>
          <p class="num-title2">
            为机构规范开展专项职业能力考核工作提供工作管理、数据管理、信息化支持服务，提供专项能力考核工作一体化在线管理平台，实现考核计划编排、考核过程管理、考核结果数据提交、相关工作信息保存，形成可追溯信息化数据链，实现专项能力考核全过程来源可查、去向可追、责任可究。
          </p>
          <img class="num-img2" src="../assets/image2.png" alt="" />
        </div>
        <div class="content content-margin content4">
          <div class="content-num">
            <p class="number">04</p>
            <p class="num-title1">理论知识题库管理系统</p>
          </div>
          <p class="num-title2">
            为提高机构理论知识题库规范水平和社会公信力，按照技能人才评价国家题库开发技术指导建立统一规范的技能人才评价理论知识题库管理系统，系统遵照国家职业技能标准或评价规范，科学整合理论知识鉴定要素，从而确保题库质量，保障技能人才评价工作的正常进行。
          </p>
          <img class="num-img3" src="../assets/image4.png" alt="" />
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">05</p>
            <p class="num-title1">技能操作题库管理系统</p>
          </div>
          <p class="num-title2">
            为提高机构技能操作题库规范水平和社会公信力，按照技能人才评价国家题库开发技术指导建立统一规范的技能人才评价技能操作题库管理系统，系统遵照国家职业技能标准或评价规范，科学整合技能操作鉴定要素，从而确保题库质量，保障技能人才评价工作的正常进行。
          </p>
          <img class="num-img3" src="../assets/image5.png" alt="" />
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">06</p>
            <p class="num-title1">理论知识在线考试系统</p>
          </div>
          <p class="num-title2">
            为机构提供高效、安全、稳定的标准化全功能在线考试系统，助力于机构在确保评价工作质量的前提下，对于互联网、数字经济领域的职业（工种）以及其他知识技能型职业（工种），采取理论知识智能化机考，探索“互联网+人才评价”的新模式，为更多行业的服务标准化和人才评价体系建设注入新的活力，促进技能人才评价体系健康蓬勃发展。支持考生通过电脑、平板、手机等多终端设备参加考试，同时融合人脸识别、视频监控、AI等技术实现多样化的防作弊机制，对考生考试行为进行全面、智能化监控，从而提高技能人才评价工作效率和质量。
          </p>
          <img class="num-img4" src="../assets/image6.png" alt="" />
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">07</p>
            <p class="num-title1">技能操作考核在线评分系统</p>
          </div>
          <p class="num-title2">
            技能操作考核在线评分系统是一款专为职业技能考核设计的软件系统，采用专业的从考核内容到要点定义的评分表并配合分档评分、结果评分、手动打分等多种方式。
          </p>
          <img class="num-img5" src="../assets/image7.png" alt="" />
          <div class="content-num-flex">
            <div class="content-num-flex-box">
              <div class="content-num-flex-box-top content-num-flex-box-top1">
                <img src="../assets/可靠.png" alt="" />
                <p>更可靠</p>
              </div>
              <div class="content-num-flex-box-bottom">
                评分更可靠，灵活定义考评组和考评任务，可实时监控任务进度、查看考察结果，各端协同工作；
              </div>
            </div>
            <div class="content-num-flex-box">
              <div class="content-num-flex-box-top content-num-flex-box-top2">
                <img src="../assets/公平.png" alt="" />
                <p>更公平</p>
              </div>
              <div class="content-num-flex-box-bottom">
                评分更公平，可手动设定各端评分差值，超出差值范围退回重评；
              </div>
            </div>
            <div class="content-num-flex-box">
              <div class="content-num-flex-box-top content-num-flex-box-top3">
                <img src="../assets/智能.png" alt="" />
                <p>更智能</p>
              </div>
              <div class="content-num-flex-box-bottom">
                <div class="content-num-flex-box-bottom-text">
                  评分更智能，考后智能结果分析，配套现场考务app和手机评分app，清晰考生各个考核点分值差值，实时通知考评任务，随时随地参与测评，助力于机构探索全程在线开展评价认定的新模式。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">08</p>
            <p class="num-title1">理论知识考核在线阅卷系统</p>
          </div>
          <p class="num-title2">
            用于机构面向阅卷老师提供理论知识上机考核主观题（简答题、论述题等）在线阅卷评分系统。
          </p>
          <img class="num-img6" src="../assets/image8.png" alt="" />
          <img class="num-img7" src="../assets/image9.png" alt="" />
        </div>
        <div class="content content-margin">
          <div class="content-num">
            <p class="number">09</p>
            <p class="num-title1">远程在线监考系统</p>
          </div>
          <p class="num-title2">
            远程在线监考系统是一款专为各种考试而设计的智能化远程监考系统，无论是传统学校的笔试，各种职业资格证书的机考，社会教师资格证一类的面试等均可实现监考，功能强大。具有视频监控墙，人脸识别防代考，视频处理，远程巡考、考试动态点播回放等功能。旨在为每场考试提供公平，公开，公正的考试环境，它简单易用，一部手机即可随时随地轻松完成监考任务。
          </p>
          <img class="num-img8" src="../assets/image10.png" alt="" />
        </div>
        <div class="content content-margin content5">
          <div class="content-num">
            <p class="number">10</p>
            <p class="num-title1">身份信息在线核验系统</p>
          </div>
          <p class="num-title2">
            为提高技能人才评价工作质量，面向考生及考评员提供身份信息在线核验系统，核验记录系统将自动记录并同步至考务平台中。
          </p>
          <img class="num-img9" src="../assets/image11.png" alt="" />
          <img class="num-img10" src="../assets/image12.png" alt="" />
          <img class="num-img11" src="../assets/image14.png" alt="" />
        </div>
      </template>
      <!-- </van-tab> -->
      <!-- <van-tab title="监管平台"> -->
      <template v-if="$route.path == '/mobileProduct' && active == 2">
        <div class="content content-margin">
          <p class="jianjie-title">技能人才评价监管系统</p>
          <img class="content-img" src="../assets/监管.png" alt="" />
          <p class="titile-p2 title-margin">
            利用大数据分析，提高人才决策分析的科学性，为领导决策提供科学、准确、及时的依据。通过打造集认定、竞赛、监管、分析、共享为一体的综合性技能人才评价服务管理平台，加速形成人人渴望成才、人人努力成才、人人皆可成才、人人尽展其才的良好局面。以视频监控信息与技能人才评价相关数据分析为支撑，建立技能人才评价服务监管体系，实现技能人才评价服务的全方位监管。
          </p>
        </div>
        <div class="content content-margin">
          <p class="content-title">系统组成</p>
          <p class="content-title1">
            技能人才评价监管服务平台由公共服务平台和监管平台两部分构成
          </p>
        </div>
        <div class="content">
          <div class="content-box content-box1">
            <div
              class="content-box-tab"
              :class="tabActive ? 'content-box-tab-active up' : ''"
              @click="tabActive = true"
            >
              <img v-if="tabActive" src="../assets/公共服务.png" alt="" />
              <img v-else src="../assets/公共服务1.png" alt="" />
              <p>公共服务平台</p>
            </div>
            <div
              class="content-box-tab"
              :class="tabActive ? '' : 'content-box-tab-active up'"
              @click="tabActive = false"
            >
              <img v-if="tabActive" src="../assets/监管平台.png" alt="" />
              <img v-else src="../assets/监管平台1.png" alt="" />
              <p>监管平台</p>
            </div>
          </div>
        </div>
        <template v-if="tabActive">
          <div class="content">
            <div class="content-box2">
              <div class="content-box-right">
                <div class="content-box-right-tag">平台简介</div>
                <div class="content-box-right-title">公共服务平台</div>
                <div class="content-box-right-msg">
                  统一门户网站和微信公众号服务平台两部分构成。实现便民化服务，简化办事环境与手续，规范优化服务流程，实现线下服务向网上办事大厅延伸，建设线上线下一体化的服务体系，为各类服务对象提供方便快捷在线服务。从而实现为技能人才评价提供政策宣传、通知公告、资讯发布、评价机构查询、评价职业(工种)查询、专项职业能力考核项目查询、证书查询、职业技能标准查询、评价计划查询等一站式网站服务。
                </div>
              </div>
              <img src="../assets/公共服务平台.png" alt="" />
            </div>
          </div>
        </template>
        <div class="jianguan-bg" v-else>
          <div class="content">
            <div class="content-box2">
              <div class="content-box-right">
                <div class="content-box-right-tag">平台简介</div>
                <div class="content-box-right-title">监管平台</div>
                <div class="content-box-right-msg">
                  主要用于技能人才评价监管服务单位对于技能人才评价工作总体目标及组织实施和监督检查工作。建立技能人才评价公开制度，构建政府监管、企业自律、社会监督、公众参与的质量监管体系。对技能人才评价工作进行全程、全方位监督，确保人才评价工作规范有序开展。
                </div>
                <div class="content-box-right-msg">
                  技能人才监管平台主要包括：备案信息管理系统、专家队伍管理系统、在线题库管理系统、等级评价监管系统、专项考核监管系统、技能竞赛系统、远程监考系统、数据分析系统八部分构成。
                </div>
              </div>
              <img src="../assets/监管平台-简介.png" alt="" />
            </div>
          </div>
          <van-collapse class="hexin" v-model="activeName1" accordion>
            <van-collapse-item name="1">
              <template #title>
                <div>
                  <img src="../assets/备案系统-0.png" alt="" /> 备案信息管理系统
                </div>
              </template>
              <div class="tab-content1">
                <div class="tab-content1-title">
                  <img src="../assets/备案系统-1.png" alt="" />
                  <p>备案信息管理系统</p>
                </div>
                <div class="tab-content1-msg">
                  依据备案管理规范，具有对组织机构、认定职业工种、等级、考核场地、考评人员等进行备案管理，保证认定范围的严肃性、规范性和真实性。评价机构在网上进行填报机构申报相关资料信息，备案管理部门对评价机构网上申报的相关资料信息进行在线审核，审核通过后再分配给对应专家进行在线评审，专家评审通过后评价机构信息统一在技能人才评价网进行公示。
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item name="2">
              <template #title>
                <div>
                  <img src="../assets/专项考核-0.png" alt="" /> 专项考核监管系统
                </div>
              </template>
              <div class="tab-content1">
                <div class="tab-content1-title">
                  <img src="../assets/专项考核-1.png" alt="" />
                  <p>专项考核监管系统</p>
                </div>
                <div class="tab-content1-msg">
                  各级人社监管部门对批准备案的专项职业能力考核机构开展专项职业能力考核工作的审核或监督工作。
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item name="3">
              <template #title>
                <div>
                  <img src="../assets/技能竞赛-0.png" alt="" />
                  职业技能竞赛管理系统
                </div>
              </template>
              <div class="tab-content1">
                <div class="tab-content1-title">
                  <img src="../assets/技能竞赛-1.png" alt="" />
                  <p>职业技能竞赛管理系统</p>
                </div>
                <div class="tab-content1-msg">
                  建设职业技能竞赛系统，规范职业技能竞赛活动流程，完善技能人才选拔机制，充分发挥职业技能竞赛在促进技能人才培养和选拔的引导推动作用，达到“以赛促评”目的。
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item name="4">
              <template #title>
                <div>
                  <img src="../assets/专家队伍-0.png" alt="" />
                  专家队伍培训管理系统
                </div>
              </template>
              <div class="tab-content1">
                <div class="tab-content1-title">
                  <img src="../assets/专家队伍-1.png" alt="" />
                  <p>专家队伍培训管理系统</p>
                </div>
                <div class="tab-content1-msg">
                  建设专家培训管理系统，加强督导员、考评员队伍建设，提高职业技能等级认定与企业自主评价工作质量，实现职业技能人才考试过程质量管理提升。
                </div>
              </div>
            </van-collapse-item>
            <van-collapse-item name="5">
              <template #title>
                <div>
                  <img src="../assets/在线题库-0.png" alt="" /> 在线题库管理系统
                </div>
              </template>
              <div class="tab-content1">
                <div class="tab-content1-title">
                  <img src="../assets/在线题库-1.png" alt="" />
                  <p>在线题库管理系统</p>
                </div>
                <div class="tab-content1-msg">
                  建立技能人才评价省级题库，实现考核试题基本全覆盖市场需求量大的职业工种，全面支撑技能人才评价业务开展，实现试题跨系统授权使用。
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
          <div class="content6">
            <van-tabs
              v-if="$route.path == '/mobileProduct' && active == 2"
              class="baozhang"
              v-model="activeName2"
              :ellipsis="false"
            >
              <van-tab title="认定计划监管系统">
                <div class="content-tab1">
                  <div class="content-tab1-title">
                    <img src="../assets/计划监管.png" alt="" />
                    <p>认定计划监管系统</p>
                  </div>
                  <div class="content-tab1-msg">
                    监管服务单位对已备案成功的认定机构的评价活动进行监管，主要包括评价范围、报名资质审核、设置预警条件、确定监管点，随机派遣外部督导员进行现场督导，上报证书信息到国网上查询等。
                  </div>
                </div>
                <img class="positionImg" src="../assets/图片.png" alt="" />
              </van-tab>
              <van-tab title="远程在线监考系统">
                <div class="content-tab1 content-tab-position">
                  <div class="content-tab1-title">
                    <img src="../assets/在线监考.png" alt="" />
                    <p>远程在线监考系统</p>
                  </div>
                  <div class="content-tab1-msg">
                    对评价机构开展的技能人才评价活动实施考场实时视频监控，并且可以和考场现场进行远程语音实时对讲、查看考场考生实时答题情况等，考核结束后还可对有异议的评价场次进行视频追溯回放。
                  </div>
                </div>
                <img class="positionImg1" src="../assets/1.png" alt="" />
              </van-tab>
              <van-tab title="数据分析系统">
                <div class="content-tab1">
                  <div class="content-tab1-title">
                    <img src="../assets/数据分析1.png" alt="" />
                    <p>数据分析系统</p>
                  </div>
                  <div class="content-tab1-msg">
                    依托大屏展示设备，通过大屏的可视化展示，将涉及职业技能人才服务的业务数据进行抽取并利用GIS、图表等技术，对人才分布、人才结构、人才需求、业务办理、服务监控、决策支持等相关分析结果进行直观、动态的大屏可视化展现，为领导分析决策、指挥调度提供可视化、智能化提供支持。提高政府对人才的宏观管理水平和专业化和精细化的管理能力。
                  </div>
                </div>
                <img class="positionImg2" src="../assets/2.png" alt="" />
              </van-tab>
            </van-tabs>
          </div>
        </div>
      </template>
      <!-- </van-tab> -->
      <!-- <van-tab title="考务服务"> -->
      <template v-if="$route.path == '/mobileProduct' && active == 3">
        <div class="content content-margin">
          <p class="jianjie-title">技能人才评价考务服务</p>
          <p class="titile-p2">
            为了更好的服务各考评机构，我公司提供考务技术服务与劳务服务，为机构解决平台操作繁琐、人力资源不足，经费支付困难的问题，协助机构按照要求合理合规完成工作。具体可提供人力资源服务、劳务服务、经费保障服务、师资协调服务、耗材采购服务、证书打印服务等。
          </p>
        </div>
        <div class="content">
          <div class="img-box">
            <div class="img-box-inset">
              <img src="@/assets/人力资源.png" alt="" />
              <p>人力资源服务</p>
            </div>
            <div class="img-box-inset">
              <img src="@/assets/师资协调.png" alt="" />
              <p>师资协调服务</p>
            </div>
            <div class="img-box-inset">
              <img src="@/assets/经费保障.png" alt="" />
              <p>经费保障服务</p>
            </div>
          </div>
          <div class="img-box">
            <div class="img-box-inset">
              <img src="@/assets/耗材采购.png" alt="" />
              <p>耗材采购服务</p>
            </div>
            <div class="img-box-inset">
              <img src="@/assets/证书打印.png" alt="" />
              <p>证书打印服务</p>
            </div>
            <div class="img-box-inset">
              <img src="@/assets/项目管理.png" alt="" />
              <p>项目管理服务</p>
            </div>
          </div>
        </div>
      </template>
      <!-- </van-tab> -->
      <!-- <van-tab title="硬件及耗材"> -->
      <template v-if="$route.path == '/mobileProduct' && active == 4">
        <div class="content content-margin">
          <p class="jianjie-title">技能人才评价设备服务</p>
          <p class="titile-p2">
            为了方便机构开展工作，我公司结合实际情况，可为机构提供配套设施设备代采购服务。根据客户需求，提供全套技能考试及培训软硬件解决方案，协助考评机构轻松上手，快速开展业务。
          </p>
        </div>
        <div class="content">
          <div class="img-box1">
            <div class="img-box-inset">
              <p class="p">证书耗材</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Certificate consumables</p>
              <img src="@/assets/证书耗材.png" alt="" />
            </div>
            <div class="img-box-inset">
              <p class="p">考试电脑</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Examination computer</p>
              <img src="@/assets/电脑.png" alt="" />
            </div>
            <!-- </div>
          <div class="img-box1"> -->
            <div class="img-box-inset">
              <p class="p">平板</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Flat plate</p>
              <img src="@/assets/平板.png" alt="" />
            </div>
            <div class="img-box-inset">
              <p class="p">摄像头</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">camera</p>
              <img src="@/assets/摄像头.png" alt="" />
            </div>
            <!-- </div>
          <div class="img-box1"> -->
            <div class="img-box-inset">
              <p class="p">无线路由器</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Wireless router</p>
              <img src="@/assets/无线路由器.png" alt="" />
            </div>
            <div class="img-box-inset">
              <p class="p">人脸识别机</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Face recognition machine</p>
              <img src="@/assets/人脸识别机.png" alt="" />
            </div>
            <!-- </div>
          <div class="img-box1"> -->
            <div class="img-box-inset">
              <p class="p">人脸识别闸机</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Face recognition gate</p>
              <img src="@/assets/实操设备.png" alt="" />
            </div>
            <div class="img-box-inset">
              <p class="p">平板充电柜</p>
              <div class="mark">
                <div class="mark-left"></div>
                <div class="mark-right"></div>
              </div>
              <p class="p1">Flat charging cabinet</p>
              <img src="@/assets/实训中心设备.png" alt="" />
            </div>
          </div>
        </div>
      </template>
      <!-- </van-tab> -->
      <!-- <van-tab title="智能实训"> -->
      <template v-if="$route.path == '/mobileProduct' && active == 5">
        <div class="content content-margin">
          <p class="jianjie-title">智能实训</p>
          <p class="titile-p2">
            我公司与大华、腾讯、阿里及华为等全球领先高科技企业在智慧物联、大数据、人工智能、云计算等领域达成长期战略合作。致力于打通人才供应端和人才需求端，进而实现人才培养和市场需求的无缝对接。
            <p class="titile-p2">结合高校的雄厚人才基础和丰富教学经验，依托企业的深厚“互联网+”产业背景，与合作伙伴和院校成立校企联盟，构建产教融合、产业学院，形成全新的“互联网+”产业教育体系、科研体系和人才培养输出体系。成为互联网领域实用适用型人才培养解决方案践行者。</p>
          </p>
        </div>
        <div class="content">
          <div class="shixun-box">
            <div class="shixun-inset">
              <div class="shixun-top">
                <span style="color: rgba(228, 30, 30, 1)">实训基地建设</span>
              </div>
              <div class="shixun-middle">
                鸿新在线为您打造实训中心综合解决方案，根据您的需求，为您提供多功能于一体的实训基地建设。
              </div>
              <div class="shixun-bottom">
                <div class="shixun-flex-box">
                  <img src="@/assets/教学.png" alt="" />
                  <p>教学</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/生产.png" alt="" />
                  <p>生产</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/系统培训.png" alt="" />
                  <p>培训</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/技术服务.png" alt="" />
                  <p>技术服务</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/技能认定.png" alt="" />
                  <p>职业技能认定</p>
                </div>
              </div>
            </div>
            <img
              class="shixun-img shixun-img1"
              src="@/assets/智能实训-图片1.png"
              alt=""
            />
          </div>
          <div class="shixun-box">
            <div class="shixun-inset">
              <div class="shixun-top">
                为机构建设<span style="color: rgba(228, 30, 30, 1)"
                  >特色鲜明、高质量</span
                >的实训室
              </div>
              <div class="shixun-middle">
                以培养“高技能、多层次、多功能”的技能人才和打造功能齐全的实训基地为建设目标，坚持需求为本、应用为王、质量为先的原则，切实将实训室建设和提升职业技能、提高就业能力相结合。
              </div>
              <div class="shixun-bottom">
                <div class="shixun-flex-box">
                  <img src="@/assets/需求为本.png" alt="" />
                  <p>需求为本</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/应用为王.png" alt="" />
                  <p>应用为王</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/质量为先.png" alt="" />
                  <p>质量为先</p>
                </div>
              </div>
            </div>
            <img class="shixun-img" src="@/assets/智能实训-图片2.png" alt="" />
          </div>
          <div class="shixun-box">
            <div class="shixun-inset">
              <div class="shixun-top">
                模拟企业<span style="color: rgba(228, 30, 30, 1)"
                  >真实工作环境</span
                >
              </div>
              <div class="shixun-middle">
                实训室模拟企业真实工作环境，相关技术参数符合产业生产标准，实现更智能、更安全、更有效的生产实训方式。
              </div>
              <div class="shixun-bottom">
                <div class="shixun-flex-box">
                  <img src="@/assets/更智能.png" alt="" />
                  <p>更智能</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/安全.png" alt="" />
                  <p>更安全</p>
                </div>
                <div class="shixun-flex-box">
                  <img src="@/assets/有效.png" alt="" />
                  <p>更有效</p>
                </div>
              </div>
            </div>
            <img class="shixun-img" src="@/assets/智能实训-图片3.png" alt="" />
          </div>
        </div>
      </template>
      <!-- </van-tab> -->
    <!-- </van-tabs> -->
  </div>
</template>
<script>
import { GetConnection } from "../api/home";
export default {
  name: "MobileProduct",
  data() {
    return {
      active: 0,
      active1: 0,
      activeName: "1",
      active2: 0,
      scrollList: [],
      scrollList1: [],
      tabActive: true,
      activeName1: "1",
      activeName2: "1",
    };
  },
  methods: {
    goToProduct() {
      this.$router.push("/mobileProduct?active=" + this.active);
    },
    onClick(name, title) {
      this.activeName = "1";
    },
    getData() {
      GetConnection()
        .then((res) => {
          this.scrollList = res;
          // this.scrollList1 = res
        })
        .catch((err) => {});
    },
  },
  mounted() {
    if (this.$route.query.active) {
      this.active = Number(this.$route.query.active);
    }
    this.getData();
  },
  computed: {
    defaultOption() {
      return {
        step: 1,
        limitMoveNum: this.scrollList.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
    defaultOption1() {
      return {
        step: 1,
        limitMoveNum: this.scrollList1.length,
        hoverStop: true,
        direction: 2,
        openTouch: true,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
};
</script>
<style scoped>
.header-bg {
  width: 100%;
  height: 200px;
  background: url(../assets/产品服务-bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}
.top >>> .van-tabs__nav--card {
  /* height: 40px; */
  margin: 0;
  border: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab {
  background: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  border-right: 1px solid #fff;
}
.top >>> .van-tabs__nav--card .van-tab.van-tab--active {
  background: rgba(227, 25, 25, 1);
}
.content {
  /* width: 100%; */
  padding: 0 16px;
}
.content .content-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin: 20px 0;
}
.content .content-title1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  margin-bottom: 30px;
  line-height: 28px;
}
.content .content-title2 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0 0 10px 0;
}
.content .content-img {
  width: 100%;
  height: 225px;
  margin-bottom: 10px;
}
.content .content-title3 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 21px;
  margin: 0;
}
.content .titile-p1 {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  margin: 20px 0;
}
.jianjie-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
  color: rgba(211, 26, 26, 1);
}
.content .titile-p2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  text-indent: 2em;
}
.tab-content {
  width: 100%;
  height: 176px;
  position: relative;
}
.tab-content .img {
  position: absolute;
  width: 100%;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: 1;
}
.tab-content .img1 {
  position: absolute;
  width: 100%;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  z-index: 2;
}
.tab-content .tab-content-title {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
  color: #fff;
}
.tab-content .tab-content-title .p1 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.tab-content .tab-content-title .tab-content-msg {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  margin-right: 10px;
}
.tab-content .tab-content-title .tab-content-msg .point-out .point-inset {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
}
.hexin >>> .van-cell__title div {
  display: flex;
  align-items: center;
}
.hexin >>> .van-cell__title div img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.gongneng >>> .van-tabs__line {
  width: 100px;
}
.content-flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-flex .content-flex-box {
  width: calc(48% - 20px);
  /* width: 145px; */
  height: 200px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 6px 0px #ccc;
}
.content-flex .content-flex-box img {
  width: 70px;
  height: 70px;
}
.content-flex .content-flex-box .p1 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-flex .content-flex-box .p2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content1 {
  height: 200px;
  padding: 30px 16px;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-line {
  width: calc(100% - 32px);
  margin: 0 auto;
  height: 110px;
  background: #fff;
  margin-top: -55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-line-inset {
  /* width: calc(100% - 160px);
  height: 260px; */
  width: calc(100% - 24px);
  height: 65%;
}
.content-box {
  width: calc(100% - 32px);
  margin: 0 auto;
}
.content-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-box .content-box-inset {
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
}
.content-box .content-box-inset img {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.content-box .content-box-inset .content-box-inset-right {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-box .content-box-inset .content-box-inset-right .p1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content-box .content-box-inset .content-box-inset-right .p2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
}
.content2 {
  /* height: 500px; */
  padding: 30px 16px;
  background: url(../assets/矩形\ 3.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 10px 0;
  background: #fff;
}
.content-tab .content-tab-box {
  width: 33.33%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-tab .content-tab-box1 {
  width: 50%;
}
.content-tab .content-tab-box img {
  width: 45px;
  height: 45px;
}
.content-tab .content-tab-box .p1 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-top: 14px;
  margin-bottom: 10px;
}
.content-tab .content-tab-box .p2 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}
.content-tab2 {
  /* height: 260px; */
  padding: 30px 16px 10px 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.content-tab2 .content-tab-box {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-tab2 .content-tab-box img {
  width: 18px;
  height: 18px;
  margin-right: 16px;
}
.content-tab2 .content-tab-box .p3 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.baozhang >>> .van-tabs__nav {
  background-color: rgba(0, 0, 0, 0);
}
.baozhang >>> .van-tab__text {
  font-size: 14px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}
.baozhang >>> .van-tabs__line {
  width: 60px;
  background-color: #fff;
}
.scroll {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.scroll >>> div {
  min-width: 10px;
}
.partner-box {
  min-width: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.partner-box .partner-box-flex {
  width: 132px;
  /* height: 80px; */
  height: 98px;
  position: relative;
  margin-right: 10px;
}
.partner-box .partner-box-flex .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 132px;
  height: 73px;
  z-index: 1;
}
.partner-box .partner-box-flex .img1 {
  position: absolute;
  top: 5px;
  left: 10px;
  /* width: 110px;
  height: 40px; */
  width: 112px;
  height: 63px;
  z-index: 2;
}
.partner-box .partner-box-flex p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.content-num {
  position: relative;
}
.title-margin {
  margin-bottom: 20px;
}
.content-margin {
  margin-top: 30px;
}
.content .num-title1 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 70px;
}
.content .num-title2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
}
.content-num .number {
  font-size: 60px;
  font-weight: 700;
  color: transparent;
  background: linear-gradient(
    180deg,
    rgba(34, 101, 231, 0.3) 0%,
    rgba(35, 101, 232, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  position: absolute;
  left: 0;
  top: 0;
}
.content .num-img {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content3 {
  background: rgba(247, 250, 255, 1);
  padding: 20px 16px;
}
.content .num-img1 {
  width: 100%;
  height: 225px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img2 {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img3 {
  width: 100%;
  height: 135px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content4 {
  padding: 20px 16px;
  background: url(../assets/image3.png) no-repeat;
  background-size: 100% 100%;
}
.content4 .number {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
}
.content4 .num-title1,
.content4 .num-title2 {
  color: #fff;
}
.content .num-img4 {
  width: 100%;
  height: 170px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content .num-img5 {
  width: 100%;
  height: 55px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content-num-flex {
  margin-bottom: 10px;
  height: 240px;
  display: flex;
  justify-content: space-between;
}
.content-num-flex .content-num-flex-box {
  width: 30%;
  height: 240px;
  box-shadow: 0px 0px 6px 0px rgba(56, 128, 195, 0.25);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top img {
  width: 30px;
  height: 30px;
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top1 {
  background: rgba(144, 189, 53, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top2 {
  background: rgba(222, 161, 49, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-top3 {
  background: rgba(67, 184, 164, 1);
}
.content-num-flex .content-num-flex-box .content-num-flex-box-bottom {
  width: calc(100% - 20px);
  height: 140px;
  padding: 10px;
}
.content-num-flex
  .content-num-flex-box
  .content-num-flex-box-bottom
  .content-num-flex-box-bottom-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.content .num-img6 {
  width: 100%;
  height: 110px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img7 {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img8 {
  width: 100%;
  height: 220px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img9 {
  width: 100%;
  height: 130px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.content .num-img10 {
  width: 100%;
  height: 260px;
  margin-top: 10px;
}
.content .num-img11 {
  width: 100%;
  height: 130px;
  margin-top: -100px;
}
.content5 {
  padding: 20px 16px 0 16px;
  background: url(../assets/image13.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 5px);
  height: 60px;
  /* margin-right: 10px; */
  border-radius: 8px;
  background: rgba(247, 250, 255, 1);
  margin-bottom: 40px;
}
.content-box-tab:hover {
  cursor: pointer;
}
.content-box-tab img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.content-box-tab p {
  font-size: 16px;
  font-weight: 700;
  color: rgba(102, 102, 102, 1);
}
.content-box-tab-active {
  position: relative;
  background: url(../assets/蒙版分组-选中.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab-active p {
  color: #fff;
}
.content-box-tab-active:before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  content: "";
  border-color: rgba(136, 183, 213, 0);
}
.content-box-tab-active.up:before {
  border-top-color: rgba(7, 79, 224, 1);
  border-width: 12px;
  left: 49%;
  margin-left: -10px;
  top: 100%;
}
.content .content-box2 {
  width: 100%;
  padding-top: 20px;
  background: rgba(247, 250, 255, 1);
}
.content .content-box2 img {
  width: 100%;
  height: 250px;
  margin-top: 10px;
}
.content .content-box2 .content-box-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .content-box2 .content-box-right .content-box-right-tag {
  background: rgba(34, 101, 231, 1);
  width: 90px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.content .content-box2 .content-box-right .content-box-right-title {
  margin: 10px 0 15px 0;
  font-size: 24px;
  font-weight: 500;
  color: rgba(34, 101, 231, 1);
}
.content .content-box2 .content-box-right .content-box-right-msg {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 28px;
}
.tab-content1 {
  width: calc(100% - 24px);
  height: 260px;
  padding: 20px 12px;
  background: url(../assets/监管平台-bg.png) no-repeat;
  background-size: 100% 100%;
}
.tab-content1 .tab-content1-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tab-content1 .tab-content1-title img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.tab-content1 .tab-content1-title p {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.tab-content1 .tab-content1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.content6 {
  margin-top: 30px;
  margin-bottom: 100px;
  width: 100%;
  height: 540px;
  background: url(../assets/jianguan.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab1 {
  padding: 20px;
}
.content-tab1 .content-tab1-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.content-tab1 .content-tab1-title img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.content-tab1 .content-tab1-title p {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.content-tab1 .content-tab1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.positionImg {
  width: 100%;
  height: 300px;
  margin-top: 50px;
}
.positionImg1 {
  width: 100%;
  height: 300px;
  margin-top: 50px;
}
.positionImg2 {
  width: 100%;
  height: 300px;
}
.content .img-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
}
.content .img-box .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content .img-box .img-box-inset img {
  width: 50px;
  height: 50px;
}
.content .img-box .img-box-inset p {
  font-size: 16px;
  line-height: 56px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0;
}
.content .img-box1 .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  /* align-items: center; */
  /* width: 165px; */
  width: 48%;
  height: 214px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.content .img-box1 .img-box-inset img {
  /* width: 165px; */
  width: 100%;
  height: 134px;
}
.content .img-box1 .img-box-inset .p {
  padding: 0 12px;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}
.content .img-box1 .img-box-inset .p1 {
  padding: 0 12px;
  font-size: 10px;
  color: rgba(153, 153, 153, 1);
}
.content .img-box1 .img-box-inset .mark {
  display: flex;
  padding: 0 12px;
  margin: 10px 0;
  align-items: center;
}
.content .img-box1 .img-box-inset .mark .mark-left {
  width: 15px;
  height: 3px;
  background: rgba(228, 30, 30, 1);
}
.content .img-box1 .img-box-inset .mark .mark-right {
  width: 15px;
  height: 3px;
  background: rgba(51, 51, 51, 1);
}
.shixun-box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shixun-box .shixun-img {
  width: 343px;
  height: 184px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.shixun-box .shixun-img1 {
  width: 343px;
  height: 240px;
}
.shixun-box .shixun-inset .shixun-top {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.shixun-box .shixun-inset .shixun-middle {
  font-size: 14px;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
  margin: 15px 0;
}
.shixun-box .shixun-inset .shixun-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box {
  width: 108px;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(255, 240, 240, 1) 0%,
    rgba(255, 240, 240, 0.01) 100%
  );
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box img {
  width: 14px;
  height: 14px;
  margin: 6px;
}
.shixun-box .shixun-inset .shixun-bottom .shixun-flex-box p {
  font-size: 12px;
  color: rgba(51, 51, 51, 1);
}
.flex-box{
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.flex-box .flex-box-inset{
  margin-top: 20px;
  padding-bottom: 12px;
  width: 48%;
  height: 204px;
background: rgba(255, 255, 255, 1);
box-shadow: 0px 0px 6px  rgba(0, 0, 0, 0.15);
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}
.flex-box .flex-box-inset .p1{
  align-self: start;
  padding: 0 12px;
  font-size: 14px;
line-height: 40px;
color: rgba(51, 51, 51, 1);
}
.flex-box .flex-box-inset .p2{
  align-self: start;
  padding: 0 12px;
  font-size: 12px;
line-height: 18px;
color: rgba(102, 102, 102, 1);
}
.flex-box .flex-box-inset img{
  width: calc(100% - 24px);
  height: 113px;
}
</style>
