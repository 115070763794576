<template>
  <div class="shixun">
    <div class="content">
      <div class="content-title">智能实训</div>
      <div class="content-msg">
        我公司与大华、腾讯、阿里及华为等全球领先高科技企业在智慧物联、大数据、人工智能、云计算等领域达成长期战略合作。致力于打通人才供应端和人才需求端，进而实现人才培养和市场需求的无缝对接。
        <div class="content-msg">
          结合高校的雄厚人才基础和丰富教学经验，依托企业的深厚“互联网+”产业背景，与合作伙伴和院校成立校企联盟，构建产教融合、产业学院，形成全新的“互联网+”产业教育体系、科研体系和人才培养输出体系。成为互联网领域实用适用型人才培养解决方案践行者。
        </div>
      </div>
      <div class="content-box">
        <img
          class="content-img content-img1"
          src="@/assets/智能实训-图片1.png"
          alt=""
        />
        <div class="content-inset">
          <div class="content-top">
            <span style="color: rgba(228, 30, 30, 1)">实训基地建设</span>
          </div>
          <div class="content-middle">
            鸿新在线为您打造实训中心综合解决方案，根据您的需求，为您提供多功能于一体的实训基地建设。
          </div>
          <div class="content-bottom">
            <div class="content-flex-box">
              <img src="@/assets/教学.png" alt="" />
              <p>教学</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/生产.png" alt="" />
              <p>生产</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/系统培训.png" alt="" />
              <p>培训</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/技术服务.png" alt="" />
              <p>技术服务</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/技能认定.png" alt="" />
              <p>职业技能认定</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box content-box1">
        <div class="content-inset">
          <div class="content-top">
            为机构建设<span style="color: rgba(228, 30, 30, 1)"
              >特色鲜明、高质量</span
            >的实训室
          </div>
          <div class="content-middle">
            以培养“高技能、多层次、多功能”的技能人才和打造功能齐全的实训基地为建设目标，坚持需求为本、应用为王、质量为先的原则，切实将实训室建设和提升职业技能、提高就业能力相结合。
          </div>
          <div class="content-bottom">
            <div class="content-flex-box">
              <img src="@/assets/需求为本.png" alt="" />
              <p>需求为本</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/应用为王.png" alt="" />
              <p>应用为王</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/质量为先.png" alt="" />
              <p>质量为先</p>
            </div>
          </div>
        </div>
        <img class="content-img" src="@/assets/智能实训-图片2.png" alt="" />
      </div>
      <div class="content-box">
        <img class="content-img" src="@/assets/智能实训-图片3.png" alt="" />
        <div class="content-inset">
          <div class="content-top">
            模拟企业<span style="color: rgba(228, 30, 30, 1)"
              >真实工作环境</span
            >
          </div>
          <div class="content-middle">
            实训室模拟企业真实工作环境，相关技术参数符合产业生产标准，实现更智能、更安全、更有效的生产实训方式。
          </div>
          <div class="content-bottom">
            <div class="content-flex-box">
              <img src="@/assets/更智能.png" alt="" />
              <p>更智能</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/安全.png" alt="" />
              <p>更安全</p>
            </div>
            <div class="content-flex-box">
              <img src="@/assets/有效.png" alt="" />
              <p>更有效</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Shixun",
};
</script>
<style scoped>
.content {
  /* padding: 0 10%; */
  width: 1280px;
  margin: 0 auto;
}
.content .content-title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin: 60px 0;
}
.jianjie-title {
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: rgba(211, 26, 26, 1);
  margin: 60px 0;
}
.content .content-msg {
  text-indent: 2em;
  /* text-align: center; */
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(102, 102, 102, 1);
}
.content .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}
.content .content-box1 {
  margin: 160px 0;
}
.content .content-box .content-img {
  width: 560px;
  height: 300px;
  margin: 0 60px;
}
.content .content-box .content-img1 {
  height: 392px;
}
.content .content-box .content-inset {
  width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .content-box .content-inset .content-top {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}
.content .content-box .content-inset .content-middle {
  font-size: 16px;
  color: rgba(102, 102, 102, 1);
  margin: 20px 0;
}
.content .content-box .content-inset .content-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.content .content-box .content-inset .content-bottom .content-flex-box {
  width: 180px;
  height: 70px;
  background: linear-gradient(
    90deg,
    rgba(255, 240, 240, 1) 0%,
    rgba(255, 240, 240, 0.01) 100%
  );
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.content .content-box .content-inset .content-bottom .content-flex-box img {
  width: 20px;
  height: 20px;
  margin: 0 16px;
}
.content .content-box .content-inset .content-bottom .content-flex-box p {
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}
</style>
