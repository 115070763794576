<template>
  <div class="contact">
    <div class="header-bg">
      <p class="p1">联系我们</p>
      <p class="p2">行业领先的职业技能领域 数字信息化综合服务商</p>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-box-left">
          <div class="content-box-left-inset">
            <div class="img-box">
              <img src="../assets/热线2.png" alt="" />
            </div>
            <div class="p-box">
              <p class="p1">联系电话：</p>
              <p class="p2">{{ detail.infoPhone }}</p>
            </div>
          </div>
          <div class="content-box-left-inset">
            <div class="img-box">
              <img src="../assets/邮箱2.png" alt="" />
            </div>
            <div class="p-box">
              <p class="p1">联系邮箱：</p>
              <p class="p2">{{ detail.infoEmail }}</p>
            </div>
          </div>
          <div class="content-box-left-inset">
            <div class="img-box">
              <img src="../assets/地址2.png" alt="" />
            </div>
            <div class="p-box">
              <p class="p1">联系地址：</p>
              <p class="p3">{{ detail.infoAddress }}</p>
            </div>
          </div>
        </div>
        <div id="map"></div>
        <!-- <baidu-map
          id="map"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
        >
          <bm-marker
            :position="center"
            animation="BMAP_ANIMATION_BOUNCE"
            @click="infoWindowOpen"
          >
            <bm-info-window
              :show="mapInfoShow"
              @close="infoWindowClose"
              title="华璞城建材家居广场"
            >
              <p class="address">
                重庆市九龙坡区石桥铺街道经纬大道1409号7栋7层2号
              </p>
            </bm-info-window>
          </bm-marker>
        </baidu-map> -->
      </div>
    </div>
    <div class="content1">
      <div class="content-box">
        <div class="content-title">请填写您的需求获取更多信息</div>
        <div class="content-title1">深入沟通，只为更深度的合作</div>
        <el-form ref="form" :model="form" :rules="rules" label-width="0">
          <el-form-item prop="messTitle">
            <el-input
              v-model="form.messTitle"
              placeholder="企业名称"
              clearable
            ></el-input>
          </el-form-item>
          <div class="flex-input">
            <el-form-item prop="messPhone">
              <el-input
                v-model="form.messPhone"
                placeholder="联系电话"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item prop="messName">
              <el-input
                v-model="form.messName"
                placeholder="姓名称呼"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item prop="messContent">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="描述你的需求，如网站、手机APP、后台管理系统等"
              v-model="form.messContent"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item class="btn-box">
            <el-button type="primary" @click="onSubmit">提交项目需求</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="popup-content">
        <img src="../assets/提交成功.png" alt="" />
        <p class="p1">您的需求信息提交成功！</p>
        <p class="p2">工作人员会在2小时内联系，请您注意电话畅通</p>
        <van-button class="popup-btn" type="danger" @click="show = false"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import initMap from "../utils/map";
import initMapLib from "../utils/mapLib";
import { GetSystemManger, AddMessage } from "../api/home";
export default {
  name: "Contact",
  data() {
    return {
      form: {
        messTitle: "",
        messPhone: "",
        messName: "",
        messContent: "",
        messStatas: "待处理",
      },
      show: false,
      detail: {},
      rules: {
        messTitle: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        messPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        messName: [
          { required: true, message: "请输入姓名称呼", trigger: "blur" },
        ],
        messContent: [
          { required: true, message: "请输入您的需求", trigger: "blur" },
        ],
      },
      searchInfoWindowShow: true,
      // mapInfoShow: true,
      // center: { lng: 106.468498, lat: 29.527385 },
      // zoom: 17,
      // ak: "krAyGB8A6cr7liSuPcqYbDBYxqpwT3Kw",
    };
  },
  methods: {
    initMap() {
      let that = this;
      initMap.init().then((BMap) => {
        initMapLib.init().then((BMapLib) => {
          // var map = new BMap.Map("map"); // 创建地图实例
          // 禁止地图旋转和倾斜可以通过配置项进行设置
          var map = new BMap.Map("map", {
            enableRotate: false,
            enableTilt: false,
          });
          var point = new BMap.Point(106.468498, 29.527385); // 创建点坐标
          map.centerAndZoom(point, 17); // 初始化地图，设置中心点坐标和地图级别
          map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

          // var content =
          //   '<div style="margin:0;line-height:20px;padding:2px;">' +
          //   '<img src="../img/公司简介.de49f2e4.png" alt="" style="float:right;zoom:1;overflow:hidden;width:100px;height:100px;margin-left:3px;" />' +
          //   "地址：重庆市九龙坡区石桥铺街道经纬大道1409号7栋7层2号<br/>电话：023-68658793<br/>简介：这是简介内容。" +
          //   "</div>";
          var content =
            '<div style="margin:0;line-height:20px;padding:2px;">' +
            '<img src="' +
            this.$path.path +
            "/src/sysImage/" +
            this.detail.infoLogo +
            '" alt="" style="float:right;zoom:1;overflow:hidden;width:100px;height:100px;margin-left:3px;" />' +
            "地址：" +
            this.detail.infoAddress +
            "<br/>电话：" +
            this.detail.infoPhone +
            "</div>";
          //创建检索信息窗口对象
          var searchInfoWindow = null;
          searchInfoWindow = new BMapLib.SearchInfoWindow(map, content, {
            title: this.detail.infoName, //标题
            width: 290, //宽度
            height: 105, //高度
            panel: "panel", //检索结果面板
            enableAutoPan: true, //自动平移
            searchTypes: [
              BMAPLIB_TAB_SEARCH, //周边检索
              BMAPLIB_TAB_TO_HERE, //到这里去
              BMAPLIB_TAB_FROM_HERE, //从这里出发
            ],
          });

          var marker = new BMap.Marker(point); //创建marker对象
          searchInfoWindow.open(marker);
          marker.addEventListener("click", function (e) {
            that.searchInfoWindowShow = !that.searchInfoWindowShow;
            if (that.searchInfoWindowShow) {
              searchInfoWindow.open(marker);
            } else {
              searchInfoWindow.close(marker);
            }
          });
          map.addOverlay(marker); //在地图中添加marker
          marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
        });
      });
    },
    // handler({ BMap, map }) {
    //   // this.center.lng = 106.468498;
    //   // this.center.lat = 29.527385;
    //   // this.zoom = 15;
    // },
    // infoWindowOpen(type, target) {
    //   this.mapInfoShow = !this.mapInfoShow;
    // },
    // infoWindowClose() {
    //   this.mapInfoShow = false;
    // },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = {
            messageJson: this.$path.Encrypt(JSON.stringify(this.form)),
          };
          AddMessage(data)
            .then((res) => {
              this.$refs["form"].resetFields();
              this.show = true;
            })
            .catch((err) => {});
        } else {
          return false;
        }
      });
    },
    getData() {
      GetSystemManger()
        .then((res) => {
          this.detail = res[0];
          this.initMap();
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.header-bg {
  width: 100%;
  height: 400px;
  background: url(../assets/联系我们-banner.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 30px;
  color: rgba(102, 102, 102, 1);
}
.content {
  /* width: 100%; */
  /* padding: 0 10%; */
  min-width: 1280px;
}
.content .content-box {
  /* width: 100%;
  min-width: 860px; */
  width: 1280px;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .content-box .content-box-left {
  width: 600px;
  height: 560px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
}
.content .content-box .content-box-left .content-box-left-inset {
  width: calc(100% - 60px);
  height: 160px;
  padding: 0 30px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}
.content .content-box .content-box-left .content-box-left-inset img {
  width: 30px;
  height: 24px;
}
.img-box {
  margin-right: 15px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 240, 240, 1);
}
.p-box {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p-box .p1 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.p-box .p2 {
  font-size: 32px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.p-box .p3 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
#map {
  width: 560px;
  height: 560px;
}
.content1 {
  padding: 60px 0;
  height: 510px;
  background: url(../assets/项目需求.png) no-repeat;
  background-size: 100% 100%;
}
.content1 .content-box {
  width: 800px;
  margin: 0 auto;
}
.content1 .content-box .content-title {
  font-size: 36px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin-bottom: 22px;
}
.content1 .content-box .content-title1 {
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}
>>> .el-input__inner {
  height: 60px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  background: rgba(255, 255, 255, 0.8);
}
>>> .el-input__inner::placeholder {
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
>>> .el-textarea__inner {
  min-height: 100px !important;
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  background: rgba(255, 255, 255, 0.8);
}
>>> .el-textarea__inner::placeholder {
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.flex-input {
  display: flex;
  justify-content: space-between;
}
.flex-input >>> .el-input__inner {
  width: 390px;
}
.btn-box {
  display: flex;
  justify-content: center;
}
.btn-box >>> .el-button {
  width: 300px;
  height: 60px;
  border-radius: 8px;
  background: rgba(227, 25, 25, 1);
  border: none;
  font-size: 20px;
  font-weight: 700;
}
>>> .van-popup {
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(3, 3, 3, 0.2);
}
.popup-content {
  width: 500px;
  height: 310px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-content img {
  width: 80px;
  height: 80px;
}
.popup-content .p1 {
  font-size: 24px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin: 32px 0 42px 0;
}
.popup-content .p2 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin-bottom: 44px;
}
.popup-content >>> .popup-btn {
  padding: 0;
  margin-bottom: 40px;
}
.popup-content .popup-btn >>> .van-button__content {
  width: 160px;
  height: 44px;
  border-radius: 8px;
  background: rgba(227, 25, 25, 1);
}
.popup-content .popup-btn >>> .van-button__text {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
>>> .BMapLib_sendToPhone {
  display: none;
}
</style>
