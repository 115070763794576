import CryptoJS from "crypto-js";
export default {
  path:
    window.location.host.split(":")[0] == "localhost"
      ? "http://localhost:1581/"
      : window.location.protocol + "//" + window.location.host,
  path1: window.location.protocol + "//" + window.location.host,
  // path: "http://www.hongxzx.com/",
  arrGroup(array, subGroupLength) {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
      newArray.push(array.slice(index, (index += subGroupLength)));
    }
    return newArray;
  },
  /* 判断是否是外链 */
  isUrl(url) {
    return !!(
      url &&
      (url.startsWith("http://") ||
        url.startsWith("https://") ||
        url.startsWith("//"))
    );
  },
  Decrypt(str) {
    // 解密
    // ar KEY = "12345678900000001234567890000000";/ / 32位
    // var KEY = "borainsoftmasterborainsoftmaster";
    // var IV = "borainsoftmaster"; //16位
    var KEY = "borainsoftmasterborainsoftmaster";
    var IV = "borainsoftmaster"; // 16位
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },
  Encrypt(str) {
    // 加密
    /* var KEY = "*#,!br-softbrora";
            var IV = "borainsoft@.,*&"; //16位 */
    var KEY = "borainsoftmasterborainsoftmaster";
    var IV = "borainsoftmaster"; // 16位
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encrypted = "";
    var srcs = CryptoJS.enc.Utf8.parse(str);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  judgeSex(str) {
    var sex = "";
    if (str.substr(16, 1) % 2 === 1) {
      sex = "男";
    } else {
      sex = "女";
    }
    return sex;
  },
  timeFormatStyle(data) {
    var returnTime = "--";
    if (data == "" || data == null) {
    } else {
      if (
        data.indexOf("0001") > -1 ||
        data.indexOf("9999") > -1 ||
        data.indexOf("1900") > -1 ||
        data.indexOf("1970") > -1
      ) {
      } else {
        if (data.indexOf("T") > -1) {
          var years = data.split("T")[0];
          var hour = data.split("T")[1].slice(0, 8);
          returnTime = years + " " + hour;
        } else {
          returnTime = data;
        }
      }
    }
    return returnTime;
  },
  formatDates(date, fmt) {
    //方法二
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    let o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
    };
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + "";
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? str : padLeftZero(str)
        );
      }
    }
    return fmt;
  },
  //时间转换
  transformTimestamp(timestamp) {
    let a = new Date(timestamp).getTime();
    const date = new Date(a);
    const Y = date.getFullYear() + "年";
    const M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "月";
    const D =
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
    const dateString = Y + M + D;
    return dateString;
  },
  //时间转换
  timeFormatSeconds(time) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (seconds < 10) seconds = "0" + seconds;
    return (
      year + "-" + month + "-" + day + " " + hours + ":" + min + ":" + seconds
    );
  },
  //时间转换
  timeFormatSeconds1(time) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (seconds < 10) seconds = "0" + seconds;
    return (
      year +
      "年" +
      month +
      "月" +
      day +
      "日 " +
      hours +
      ":" +
      min +
      ":" +
      seconds
    );
  },
  //时间转换
  timeFormatSeconds2(time, time1) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (seconds < 10) seconds = "0" + seconds;

    var d1 = time1 ? new Date(time1) : new Date();
    var year1 = d1.getFullYear();
    var month1 = d1.getMonth() + 1;
    var day1 = d1.getDate();
    var hours1 = d1.getHours();
    var min1 = d1.getMinutes();
    var seconds1 = d1.getSeconds();
    if (month1 < 10) month1 = "0" + month1;
    if (day1 < 10) day1 = "0" + day1;
    if (hours1 < 10) hours1 = "0" + hours1;
    if (min1 < 10) min1 = "0" + min1;
    if (seconds1 < 10) seconds1 = "0" + seconds1;

    let timeStr = year + "年" + month + "月" + day + "日 ";
    let timeStr1 = year1 + "年" + month1 + "月" + day1 + "日 ";
    if (timeStr == timeStr1) {
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日 " +
        hours +
        ":" +
        min +
        " - " +
        hours1 +
        ":" +
        min1
      );
    } else {
      return (
        year +
        "年" +
        month +
        "月" +
        day +
        "日 " +
        hours +
        ":" +
        min +
        " - " +
        year1 +
        "年" +
        month1 +
        "月" +
        day1 +
        "日 " +
        hours1 +
        ":" +
        min1
      );
    }
  },

  //时间转换
  timeFormatSeconds3(time) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (seconds < 10) seconds = "0" + seconds;
    return year + "年" + month + "月" + day + "日 ";
  },

  //时间转换
  timeFormatSeconds4(time, time1) {
    var d = time ? new Date(time) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hours = d.getHours();
    var min = d.getMinutes();
    var seconds = d.getSeconds();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (seconds < 10) seconds = "0" + seconds;

    var d1 = time1 ? new Date(time1) : new Date();
    var year1 = d1.getFullYear();
    var month1 = d1.getMonth() + 1;
    var day1 = d1.getDate();
    var hours1 = d1.getHours();
    var min1 = d1.getMinutes();
    var seconds1 = d1.getSeconds();
    if (month1 < 10) month1 = "0" + month1;
    if (day1 < 10) day1 = "0" + day1;
    if (hours1 < 10) hours1 = "0" + hours1;
    if (min1 < 10) min1 = "0" + min1;
    if (seconds1 < 10) seconds1 = "0" + seconds1;

    let timeStr = year + "年" + month + "月" + day + "日 ";
    let timeStr1 = year1 + "年" + month1 + "月" + day1 + "日 ";
    if (timeStr == timeStr1) {
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        min +
        " - " +
        hours1 +
        ":" +
        min1
      );
    } else {
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        min +
        " - " +
        year1 +
        "-" +
        month1 +
        "-" +
        day1 +
        " " +
        hours1 +
        ":" +
        min1
      );
    }
  },

  timeDate(dateForm, timeType) {
    if (!dateForm) {
      return "";
    } else if (dateForm.indexOf("T") != -1) {
      if (timeType == "yyyy-mm-dd hh:mm:ss") {
        dateForm = dateForm.replace("T", " ");
        if (dateForm.indexOf(".") != -1) {
          dateForm = dateForm.split(".");
          dateForm = dateForm[0];
        }
      } else if (timeType == "yyyy-mm-dd") {
        var date = dateForm.split("T");
        return date[0];
      }
    } else {
      dateForm = new Date(
        parseInt(dateForm.replace("/Date(", "").replace(")/", ""), 10)
      );
      var year = dateForm.getFullYear();
      var month = dateForm.getMonth() + 1;
      var date = dateForm.getDate();
      var hour = dateForm.getHours();
      var minute = dateForm.getMinutes();
      var second = dateForm.getSeconds();
      if (month < 10) month = "0" + month;
      if (date < 10) date = "0" + date;
      if (hour < 10) hour = "0" + hour;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      if (year == "1900" || year == "9999") {
        return "";
      }
      if (timeType == "yyyy-mm-dd hh:mm:ss") {
        return (
          year +
          "-" +
          month +
          "-" +
          date +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      } else if (timeType == "yyyy-mm-dd") {
        return year + "-" + month + "-" + date;
      } else if (timeType == "yyyy-mm") {
        return year + "-" + month;
      } else if (timeType == "yyyy") {
        return year;
      } else if (timeType == "hh:mm:ss") {
        return hour + ":" + minute + ":" + second;
      } else if (timeType == "hh时mm分ss秒") {
        return hour + "时" + minute + "分" + second + "秒";
      } else if (timeType == "hh:mm") {
        return hour + ":" + minute;
      } else if (timeType == "day") {
        return date + "天";
      }
    }
  },

  timestampFormatter(inputTime, isPop) {
    // 最终时间结果对象
    const finalDateObj = {
      h: null, // 小时
      m: null, // 分钟
      s: null, // 秒
    };

    // 剩余时间总的毫秒数 除以 1000 变为总秒数（时间戳为13位 需要除以1000，为10位 则不需要）
    let dec = inputTime / 1000;

    if (dec <= 0) {
      dec = 0;
    }

    // 得到小时 格式化成前缀加零的样式
    let h = Math.trunc(dec / 3600);
    h = h < 10 ? "0" + h : h;
    // 得到分钟 格式化成前缀加零的样式
    let m = Math.trunc((dec % 3600) / 60);
    m = m < 10 ? "0" + m : m;
    // 得到秒 格式化成前缀加零的样式
    let s = Math.trunc((dec % 3600) % 60);
    s = s < 10 ? "0" + s : s;

    finalDateObj.h = h;
    finalDateObj.m = m;
    finalDateObj.s = s;

    return isPop ? `${h}时${m}分${s}秒` : finalDateObj;
  },

  timestampFormatter1(inputTime, isPop) {
    // 最终时间结果对象
    const finalDateObj = {
      h: null, // 小时
      m: null, // 分钟
      s: null, // 秒
    };

    // 剩余时间总的毫秒数 除以 1000 变为总秒数（时间戳为13位 需要除以1000，为10位 则不需要）
    let dec = inputTime / 1000;

    if (dec <= 0) {
      dec = 0;
    }

    // 得到天 格式化成前缀加零的样式
    let d = Math.trunc(dec / 3600 / 24);

    // 得到小时 格式化成前缀加零的样式
    let h = Math.trunc(dec / 3600);
    h = h < 10 ? "0" + h : h;
    // 得到分钟 格式化成前缀加零的样式
    let m = Math.trunc((dec % 3600) / 60);
    m = m < 10 ? "0" + m : m;
    // 得到秒 格式化成前缀加零的样式
    let s = Math.trunc((dec % 3600) % 60);
    s = s < 10 ? "0" + s : s;

    finalDateObj.h = h;
    finalDateObj.m = m;
    finalDateObj.s = s;

    return isPop ? `${d}天` : finalDateObj;
  },

  timestampFormatter3(inputTime, isPop) {
    // 最终时间结果对象
    const finalDateObj = {
      h: null, // 小时
      m: null, // 分钟
      s: null, // 秒
    };

    // 剩余时间总的毫秒数 除以 1000 变为总秒数（时间戳为13位 需要除以1000，为10位 则不需要）
    let dec = inputTime / 1000;

    if (dec <= 0) {
      dec = 0;
    }

    // 得到小时 格式化成前缀加零的样式
    let h = Math.trunc(dec / 3600);
    h = h < 10 ? "0" + h : h;
    // 得到分钟 格式化成前缀加零的样式
    let m = Math.trunc((dec % 3600) / 60);
    m = m < 10 ? "0" + m : m;
    // 得到秒 格式化成前缀加零的样式
    let s = Math.trunc((dec % 3600) % 60);
    s = s < 10 ? "0" + s : s;

    finalDateObj.h = h;
    finalDateObj.m = m;
    finalDateObj.s = s;

    return isPop ? `${h}:${m}:${s}` : finalDateObj;
  },

  // 判断日期是否为1900 || 9999
  dateJudge(date) {
    if (
      date.split(" ")[0].split("-")[0] == "1900" ||
      date.split(" ")[0].split("-")[0] == "9999"
    ) {
      return "--";
    } else {
      return date;
    }
  },

  //根据身份证号获取出生日期
  getBirthdayFromCardId(dataVal) {
    var returnStr =
      dataVal.substring(6, 10) +
      "-" +
      dataVal.substring(10, 12) +
      "-" +
      dataVal.substring(12, 14);
    return returnStr;
  },
  //根据身份证号获取性别
  getGenderFromCardId(dataVal) {
    var returnStr = "";
    if (parseInt(dataVal.substr(16, 1)) % 2 == 1) {
      returnStr = "男";
    } else {
      returnStr = "女";
    }
    return returnStr;
  },
  //根据身份证号码获取年龄
  getAgeFromCardId(dataVal) {
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - dataVal.substring(6, 10) - 1;
    if (
      dataVal.substring(10, 12) < month ||
      (dataVal.substring(10, 12) == month && dataVal.substring(12, 14) <= day)
    ) {
      age++;
    }
    return age;
  },

  //下载图片文件
  downLoadPicFile(imgUrl, filename) {
    let image = new Image();
    // 解决跨域 canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // 得到图片base64编码数据
      let url = canvas.toDataURL("image/png");
      // 生成一个a元素
      let ele = document.createElement("a");
      ele.setAttribute("href", url); //设置下载文件的url地址
      ele.setAttribute("download", filename); //用于设置下载文件的文件名
      document.body.appendChild(ele);
      ele.click();
      document.body.removeChild(ele);
    };
    image.src = imgUrl;
  },

  // 文件下载
  downloadFileFromWeb(url, filename) {
    let ele = document.createElement("a");
    ele.setAttribute("href", url); //设置下载文件的url地址
    ele.setAttribute("download", filename); //用于设置下载文件的文件名
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
  },
  // 文件下载
  downloadFile(url, filename) {
    const prefix = process.env.VUE_APP_API_BASE_URL;
    let ele = document.createElement("a");
    ele.setAttribute("href", prefix + url); //设置下载文件的url地址
    ele.setAttribute("download", filename); //用于设置下载文件的文件名
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
  },
};

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}
