<template>
  <div class="trainingExam">
    <div class="content">
      <div class="content-box">
        <p class="content-title">技能人才评价监管系统</p>
        <div class="content-box-inset">
          <img src="../assets/监管服务平台.png" alt="" />
          <div class="content-box-inset-right">
            利用大数据分析，提高人才决策分析的科学性，为领导决策提供科学、准确、及时的依据。通过打造集认定、竞赛、监管、分析、共享为一体的综合性技能人才评价服务管理平台，加速形成人人渴望成才、人人努力成才、人人皆可成才、人人尽展其才的良好局面。以视频监控信息与技能人才评价相关数据分析为支撑，建立技能人才评价服务监管体系，实现技能人才评价服务的全方位监管。
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <p class="content-title">系统组成</p>
        <p class="content-title1">
          技能人才评价监管服务平台由公共服务平台和监管平台两部分构成
        </p>
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div
          class="content-box-tab"
          :class="tabActive ? 'content-box-tab-active up' : ''"
          @click="tabActive = true"
        >
          <img v-if="tabActive" src="../assets/公共服务.png" alt="" />
          <img v-else src="../assets/公共服务1.png" alt="" />
          <p>公共服务平台</p>
        </div>
        <div
          class="content-box-tab"
          :class="tabActive ? '' : 'content-box-tab-active up'"
          @click="tabActive = false"
        >
          <img v-if="tabActive" src="../assets/监管平台.png" alt="" />
          <img v-else src="../assets/监管平台1.png" alt="" />
          <p>监管平台</p>
        </div>
      </div>
    </div>
    <template v-if="tabActive">
      <div class="content">
        <div class="content-box1">
          <img src="../assets/公共服务平台.png" alt="" />
          <div class="content-box-right">
            <div class="content-box-right-tag">平台简介</div>
            <div class="content-box-right-title">公共服务平台</div>
            <div class="content-box-right-msg">
              统一门户网站和微信公众号服务平台两部分构成。实现便民化服务，简化办事环境与手续，规范优化服务流程，实现线下服务向网上办事大厅延伸，建设线上线下一体化的服务体系，为各类服务对象提供方便快捷在线服务。从而实现为技能人才评价提供政策宣传、通知公告、资讯发布、评价机构查询、评价职业(工种)查询、专项职业能力考核项目查询、证书查询、职业技能标准查询、评价计划查询等一站式网站服务。
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="jianguan-bg" v-else>
      <div class="content">
        <div class="content-box1">
          <div class="content-box-right">
            <div class="content-box-right-tag">平台简介</div>
            <div class="content-box-right-title">监管平台</div>
            <div class="content-box-right-msg">
              主要用于技能人才评价监管服务单位对于技能人才评价工作总体目标及组织实施和监督检查工作。建立技能人才评价公开制度，构建政府监管、企业自律、社会监督、公众参与的质量监管体系。对技能人才评价工作进行全程、全方位监督，确保人才评价工作规范有序开展。技能人才监管平台主要包括：备案信息管理系统、专家队伍管理系统、在线题库管理系统、等级评价监管系统、专项考核监管系统、技能竞赛系统、远程监考系统、数据分析系统八部分构成。
            </div>
          </div>
          <img src="../assets/监管平台-简介.png" alt="" />
        </div>
      </div>
      <div class="tab-content">
        <el-tabs v-model="activeName" tab-position="left" style="height: 300px">
          <el-tab-pane name="1"
            ><span slot="label"
              ><img
                v-if="activeName == '1'"
                src="../assets/备案系统.png"
                alt=""
              /><img
                v-else
                src="../assets/备案系统-0.png"
                alt=""
              />备案信息管理系统</span
            >
            <div class="content-tab">
              <div class="content-tab-title">
                <img src="../assets/备案系统-1.png" alt="" />
                <p>备案信息管理系统</p>
              </div>
              <div class="content-tab-msg">
                依据备案管理规范，具有对组织机构、认定职业工种、等级、考核场地、考评人员等进行备案管理，保证认定范围的严肃性、规范性和真实性。评价机构在网上进行填报机构申报相关资料信息，备案管理部门对评价机构网上申报的相关资料信息进行在线审核，审核通过后再分配给对应专家进行在线评审，专家评审通过后评价机构信息统一在技能人才评价网进行公示。
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2"
            ><span slot="label"
              ><img
                v-if="activeName == '2'"
                src="../assets/专项考核.png"
                alt=""
              /><img
                v-else
                src="../assets/专项考核-0.png"
                alt=""
              />专项考核监管系统</span
            >
            <div class="content-tab">
              <div class="content-tab-title">
                <img src="../assets/专项考核-1.png" alt="" />
                <p>专项考核监管系统</p>
              </div>
              <div class="content-tab-msg">
                各级人社监管部门对批准备案的专项职业能力考核机构开展专项职业能力考核工作的审核或监督工作。
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="3"
            ><span slot="label"
              ><img
                v-if="activeName == '3'"
                src="../assets/技能竞赛.png"
                alt=""
              /><img
                v-else
                src="../assets/技能竞赛-0.png"
                alt=""
              />职业技能竞赛管理系统</span
            >
            <div class="content-tab">
              <div class="content-tab-title">
                <img src="../assets/技能竞赛-1.png" alt="" />
                <p>职业技能竞赛管理系统</p>
              </div>
              <div class="content-tab-msg">
                建设职业技能竞赛系统，规范职业技能竞赛活动流程，完善技能人才选拔机制，充分发挥职业技能竞赛在促进技能人才培养和选拔的引导推动作用，达到“以赛促评”目的。
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="4"
            ><span slot="label"
              ><img
                v-if="activeName == '4'"
                src="../assets/专家队伍.png"
                alt=""
              /><img
                v-else
                src="../assets/专家队伍-0.png"
                alt=""
              />专家队伍培训管理系统</span
            >
            <div class="content-tab">
              <div class="content-tab-title">
                <img src="../assets/专家队伍-1.png" alt="" />
                <p>专家队伍培训管理系统</p>
              </div>
              <div class="content-tab-msg">
                建设专家培训管理系统，加强督导员、考评员队伍建设，提高职业技能等级认定与企业自主评价工作质量，实现职业技能人才考试过程质量管理提升。
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="5"
            ><span slot="label"
              ><img
                v-if="activeName == '5'"
                src="../assets/在线题库.png"
                alt=""
              /><img
                v-else
                src="../assets/在线题库-0.png"
                alt=""
              />在线题库管理系统</span
            >
            <div class="content-tab">
              <div class="content-tab-title">
                <img src="../assets/在线题库-1.png" alt="" />
                <p>在线题库管理系统</p>
              </div>
              <div class="content-tab-msg">
                建立技能人才评价省级题库，实现考核试题基本全覆盖市场需求量大的职业工种，全面支撑技能人才评价业务开展，实现试题跨系统授权使用。
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="content content1">
        <el-tabs class="tab" v-model="activeName1">
          <el-tab-pane label="认定计划监管系统" name="1">
            <div class="content-tab1">
              <div class="content-tab1-title">
                <img src="../assets/计划监管.png" alt="" />
                <p>认定计划监管系统</p>
              </div>
              <div class="content-tab1-msg">
                监管服务单位对已备案成功的认定机构的评价活动进行监管，主要包括评价范围、报名资质审核、设置预警条件、确定监管点，随机派遣外部督导员进行现场督导，上报证书信息到国网上查询等。
              </div>
            </div>
            <img class="positionImg" src="../assets/图片.png" alt="" />
          </el-tab-pane>
          <el-tab-pane label="远程在线监考系统" name="2">
            <div class="content-tab1 content-tab-position">
              <div class="content-tab1-title">
                <img src="../assets/在线监考.png" alt="" />
                <p>远程在线监考系统</p>
              </div>
              <div class="content-tab1-msg">
                对评价机构开展的技能人才评价活动实施考场实时视频监控，并且可以和考场现场进行远程语音实时对讲、查看考场考生实时答题情况等，考核结束后还可对有异议的评价场次进行视频追溯回放。
              </div>
            </div>
            <img
              class="positionImg1"
              src="../assets/远程在线监考-图片.png"
              alt=""
            />
          </el-tab-pane>
          <el-tab-pane label="数据分析系统" name="3">
            <div class="content-tab1">
              <div class="content-tab1-title">
                <img src="../assets/数据分析1.png" alt="" />
                <p>数据分析系统</p>
              </div>
              <div class="content-tab1-msg">
                依托大屏展示设备，通过大屏的可视化展示，将涉及职业技能人才服务的业务数据进行抽取并利用GIS、图表等技术，对人才分布、人才结构、人才需求、业务办理、服务监控、决策支持等相关分析结果进行直观、动态的大屏可视化展现，为领导分析决策、指挥调度提供可视化、智能化提供支持。提高政府对人才的宏观管理水平和专业化和精细化的管理能力。
              </div>
            </div>
            <img
              class="positionImg2"
              src="../assets/数据分析系统-图片.png"
              alt=""
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TrainingExam",
  data() {
    return {
      tabActive: true,
      activeName: "1",
      activeName1: "1",
    };
  },
};
</script>
<style scoped>
.content {
  /* width: 100%; */
  /* padding: 0 10%; */
  min-width: 1280px;
}
.content .content-box {
  /* width: 100%;
  min-width: 860px; */
  width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.content .content-box .content-title {
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  width: 100%;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}
.jianjie-title {
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: rgba(211, 26, 26, 1);
  margin-top: 60px;
  margin-bottom: 40px;
}
.content .content-box .content-title1 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  margin-bottom: 60px;
}
.content .content-box .content-box-inset {
  display: flex;
  margin-top: 20px;
}
.content .content-box .content-box-inset img {
  width: 580px;
  height: 380px;
}
.content .content-box .content-box-inset .content-box-inset-right {
  text-indent: 2em;
  padding-top: 17px;
  width: 580px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-indent: 2em;
  line-height: 28px;
  margin-left: 40px;
}
.content .content-box .content-box-inset .content-box-inset-right p {
  text-indent: 2em;
}
.content-box-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 590px;
  height: 100px;
  margin-right: 20px;
  border-radius: 8px;
  background: rgba(247, 250, 255, 1);
  margin-bottom: 40px;
}
.content-box-tab:hover {
  cursor: pointer;
}
.content-box-tab img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.content-box-tab p {
  font-size: 24px;
  font-weight: 700;
  color: rgba(102, 102, 102, 1);
}
.content-box-tab-active {
  position: relative;
  background: url(../assets/蒙版分组-选中.png) no-repeat;
  background-size: 100% 100%;
}
.content-box-tab-active p {
  color: #fff;
}
.content-box-tab-active:before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  content: "";
  border-color: rgba(136, 183, 213, 0);
}
.content-box-tab-active.up:before {
  border-top-color: rgba(7, 79, 224, 1);
  border-width: 12px;
  left: 49%;
  margin-left: -10px;
  top: 100%;
}
.content .content-box1 {
  width: 100%;
  padding: 60px;
  display: flex;
  justify-content: center;
  background: rgba(247, 250, 255, 1);
}
.content .content-box1 img {
  width: 580px;
  height: 400px;
  margin-right: 40px;
}
.content .content-box1 .content-box-right {
  width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content .content-box1 .content-box-right .content-box-right-tag {
  background: rgba(34, 101, 231, 1);
  width: 116px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.content .content-box1 .content-box-right .content-box-right-title {
  margin: 15px 0 25px 0;
  font-size: 36px;
  font-weight: 500;
  color: rgba(34, 101, 231, 1);
}
.content .content-box1 .content-box-right .content-box-right-msg {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 28px;
}
>>> .el-tabs__nav-wrap::after {
  height: 0 !important;
}
.el-tabs--left >>> .el-tabs__header.is-left {
  background: #fff;
}
.el-tabs--left >>> .el-tabs__content {
  padding-top: 0;
}
.el-tabs--left >>> .el-tabs__active-bar {
  width: 0 !important;
}
.el-tabs--left >>> .el-tabs__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}
.el-tabs--left >>> .el-tabs__item {
  height: 60px;
  padding: 0 24px;
  width: 480px;
  display: flex !important;
  align-items: center !important;
}
.el-tabs--left >>> .el-tabs__item span {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 18px;
  font-weight: 700;
  color: rgba(102, 102, 102, 1);
}
.el-tabs--left >>> .el-tabs__item span img {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
.el-tabs--left >>> .el-tabs__item.is-active {
  padding: 0 20px;
  border-left: 4px solid rgba(34, 101, 231, 1);
  background: linear-gradient(90deg, rgba(235, 241, 255, 1) 0%, #ffffff 100%);
}
.el-tabs--left >>> .el-tabs__item.is-active span {
  color: rgba(34, 101, 231, 1);
}
.el-tabs--left >>> .el-tabs__item:hover {
  padding: 0 20px;
  border-left: 4px solid rgba(34, 101, 231, 1);
  background: linear-gradient(90deg, rgba(235, 241, 255, 1) 0%, #ffffff 100%);
}
.el-tabs--left >>> .el-tabs__item:hover span {
  color: rgba(34, 101, 231, 1);
}
.tab-content {
  width: 1280px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
.content-tab {
  width: 620px;
  height: 240px;
  padding: 30px 40px;
  background: url(../assets/监管平台-模块-bg.png) no-repeat;
  background-size: 100% 100%;
}
.content-tab .content-tab-title {
  display: flex;
  align-items: center;
}
.content-tab .content-tab-title img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.content-tab .content-tab-title p {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.content-tab .content-tab-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  margin-top: 30px;
}
.tab >>> .el-tabs__content {
  padding-top: 0;
}
.tab >>> .el-tabs__content .el-tab-pane {
  min-height: 530px;
  display: flex;
  position: relative;
}
.tab >>> .el-tabs__content #pane-1 {
  display: flex;
  justify-content: center;
}
.tab {
  width: 1280px;
  margin: 0 auto;
}
.tab >>> .el-tabs__nav-wrap::after {
  height: 1px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.el-tabs--top >>> .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.el-tabs--top >>> .el-tabs__nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.el-tabs--top >>> .el-tabs__item {
  height: 60px;
  line-height: 60px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.el-tabs--top >>> .el-tabs__item.is-active {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.el-tabs--top >>> .el-tabs__item:hover {
  color: #fff;
}
.el-tabs--top >>> .el-tabs__active-bar {
  height: 0;
}
.content1 {
  width: 100%;
  height: 480px;
  padding: 60px 0 0 0;
  background: url(../assets/背景.png) no-repeat;
  background-size: 100% 100%;
  margin-bottom: 140px;
}
.content-tab1 {
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -200px;
}
.content-tab1 .content-tab1-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.content-tab1 .content-tab1-title img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.content-tab1 .content-tab1-title p {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.content-tab1 .content-tab1-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 1);
}
.positionImg {
  width: 640px;
  height: 530px;
}
.content-tab-position {
  margin-top: -300px;
}
.positionImg1 {
  top: 50px;
  position: absolute;
  width: 100%;
  height: 450px;
}
.positionImg2 {
  width: 640px;
  height: 477px;
}
.jianguan-bg {
  background: rgba(247, 250, 255, 1);
}
</style>
