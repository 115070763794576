<template>
  <div class="mobileNewsDetail">
    <div class="mobileNewsDetail-content">
      <div class="nav-position">
        <img src="../assets/当前位置.png" alt="" />
        <p class="nav-font">当前位置：</p>
        <el-breadcrumb class="nav-font" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            :to="{ path: item.path ? item.path : '/' }"
            v-for="(item, index) in breadList"
            :key="index"
          >
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="newsDetail-title">{{ detail.newsTitle }}</div>
      <div class="newsDetail-middle">
        <div>资讯类型：{{ detail.diccName }}</div>
        <div>发布时间：{{ detail.newsTime }}</div>
        <div>浏览次数：{{ detail.newsTotal }}</div>
      </div>
      <div class="newsDetail-desc">{{ detail.newsdisc }}</div>
      <div class="newsDetail-line"></div>
      <div class="newsDetail-msg" v-html="detail.newsContent"></div>
      <div class="newsDetail-tips">
        该文观点仅代表作者本人，平台仅提供信息存储空间服务。
      </div>
      <div class="newsDetail-file" v-if="detail.newsFile">
        <p>附件：</p>
        <div>
          <p
            class="p"
            v-for="(item, index) in detail.newsFile.split(',')"
            :key="index"
          >
            <a :href="$path.path + '/src/newsInfoUploadAddress/' + item">{{
              item
            }}</a>
          </p>
          <!-- <a
            v-for="(item, index) in detail.newsFile.split(',')"
            :key="index"
            :href="$path.path + '/src/newsInfoUploadAddress/' + item"
          >
            {{ item }}
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SelectNewsInfoList } from "../api/home";
export default {
  name: "NewsDetail",
  data() {
    return {
      breadList: [],
      info: {},
      detail: {},
    };
  },
  methods: {
    getBreadcrumb() {
      if (Object.keys(this.$route.matched[0].meta).length > 0) {
        this.breadList = this.$route.matched;
      } else {
        this.breadList = [];
      }
    },
    getData() {
      let params = {
        // newsId: this.$path.Encrypt(this.info.newsId),
        newsId: this.$path.Encrypt(this.info),
      };
      SelectNewsInfoList(params)
        .then((res) => {
          this.detail = res[0];
        })
        .catch((err) => {});
    },
  },
  mounted() {
    if (this.$route.query.newsId) {
      this.info = this.$route.query.newsId;
      this.getData();
    }
    this.getBreadcrumb();
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
};
</script>
<style scoped>
.mobileNewsDetail {
  min-height: 100vh;
}
.mobileNewsDetail-content {
  padding: 0 16px;
}
.nav-position {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: rgba(247, 247, 247, 1);
  margin: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nav-position img {
  width: 14px;
  height: 14px;
  margin: 0 20px;
}
.nav-position .nav-font {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.newsDetail-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
}
.newsDetail-middle {
  font-size: 12px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin: 20px 0 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .newsDetail-middle div {
  margin: 0 10px;
} */
.newsDetail-desc {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.newsDetail-line {
  width: 60px;
  height: 1px;
  background: rgba(204, 204, 204, 1);
  margin: 30px 0;
}
.newsDetail-tips {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin: 30px 0;
}
.newsDetail-file {
  background: rgba(247, 247, 247, 1);
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  display: flex;
  /* align-items: center; */
}
.newsDetail-file p {
  /* width: 60px; */
  white-space: nowrap;
}
.newsDetail-file div {
  width: calc(100% - 40px);
}
.newsDetail-file .p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.newsDetail-file a {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin: 0 10px;
}
.newsDetail-file a:hover {
  color: #007aff;
}
.newsDetail-msg ::v-deep img {
  width: 100%;
  height: 100%;
}
</style>
