<template>
  <div class="customDetail">
    <div class="title-bg">
      <img src="../assets/鸿新在线_logo横.png" alt="" />
    </div>
    <div class="newsDetail-content">
      <div class="nav-position">
        <img src="../assets/当前位置.png" alt="" />
        <p class="nav-font">当前位置：</p>
        <el-breadcrumb class="nav-font" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            :to="{ path: item.path ? item.path : '/' }"
            v-for="(item, index) in breadList"
            :key="index"
          >
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="newsDetail-title">{{ detail.cainTitle }}</div>
      <div class="newsDetail-middle">
        <div>发布时间：{{ detail.cainTime }}</div>
        <div>浏览次数：{{ detail.cainTotal }}</div>
      </div>
      <div class="newsDetail-desc">{{ detail.cainContent }}</div>
      <div class="newsDetail-line"></div>
      <div class="newsDetail-msg" v-html="detail.cainRemarks"></div>
    </div>
  </div>
</template>
<script>
import { SelectCaseInfoList } from "../api/home";
export default {
  name: "CustomDetail",
  data() {
    return {
      breadList: [],
      info: {},
      detail: {},
    };
  },
  methods: {
    getBreadcrumb() {
      if (Object.keys(this.$route.matched[0].meta).length > 0) {
        this.breadList = this.$route.matched;
      } else {
        this.breadList = [];
      }
    },
    getData() {
      let params = {
        cainId: this.$path.Encrypt(this.info),
      };
      SelectCaseInfoList(params)
        .then((res) => {
          this.detail = res[0];
        })
        .catch((err) => {});
    },
  },
  mounted() {
    if (this.$route.query.cainId) {
      this.info = this.$route.query.cainId;
      this.getData();
    }
    this.getBreadcrumb();
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
};
</script>
<style scoped>
.customDetail {
  min-height: 100vh;
}
.title-bg {
  width: 100%;
  height: 70px;
  background: linear-gradient(
    135deg,
    rgba(247, 176, 22, 1) 0%,
    rgba(227, 25, 25, 1) 100%
  );
  display: flex;
  align-items: center;
}
.title-bg img {
  width: 166.47px;
  height: 46px;
  margin-left: 10%;
}
.newsDetail-content {
  /* padding: 0 10%; */
  width: 1280px;
  margin: 0 auto;
}
.nav-position {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: rgba(247, 247, 247, 1);
  margin: 20px 0;
}
.nav-position img {
  width: 14px;
  height: 14px;
  margin: 0 20px;
}
.nav-position .nav-font {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.newsDetail-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
}
.newsDetail-middle {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin: 20px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsDetail-middle div {
  margin: 0 10px;
}
.newsDetail-desc {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.newsDetail-line {
  width: 60px;
  height: 1px;
  background: rgba(204, 204, 204, 1);
  margin: 30px 0;
}
/* .newsDetail-msg ::v-deep img {
  width: 100%;
} */
</style>
