<template>
  <div id="app">
    <keep-alive v-if="this.$route.meta.keepAlive">
      <router-view :trueOrfalse="trueOrfalse" @callBack="callBack" />
    </keep-alive>
    <router-view v-else :trueOrfalse="trueOrfalse" />
    <!-- <router-view /> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      trueOrfalse: true,
    };
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    redirectPath() {
      if (this._isMobile() && this.$route.path == "/") {
        this.$router.push("/mobile");
      }
      if (!this._isMobile() && this.$route.path == "/mobile") {
        this.$router.push("/");
      }
      if (this._isMobile() && this.$route.path == "/news") {
        let diccId = 0;
        if (this.$route.query.diccId) {
          diccId = this.$route.query.diccId;
        }
        this.$router.push("/mobileNews?diccId=" + diccId);
      }
      if (this._isMobile() && this.$route.path == "/newsDetail") {
        let newsId = 0;
        if (this.$route.query.newsId) {
          newsId = this.$route.query.newsId;
        }
        this.$router.push("/mobileNewsDetail?newsId=" + newsId);
      }
      if (!this._isMobile() && this.$route.path == "/mobileNews") {
        let diccId = 0;
        if (this.$route.query.diccId) {
          diccId = this.$route.query.diccId;
        }
        this.$router.push("/news?diccId=" + diccId);
      }
      if (!this._isMobile() && this.$route.path == "/mobileNewsDetail") {
        let newsId = 0;
        if (this.$route.query.newsId) {
          newsId = this.$route.query.newsId;
        }
        this.$router.push("/newsDetail?newsId=" + newsId);
      }
      if (this._isMobile() && this.$route.path == "/cultivate") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 0 } });
        this.$router.push("/mobileCultivate");
      }
      if (this._isMobile() && this.$route.path == "/appraise") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 1 } });
        this.$router.push("/mobileAppraise");
      }
      if (this._isMobile() && this.$route.path == "/supervise") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 2 } });
        this.$router.push("/mobileSupervise");
      }
      if (this._isMobile() && this.$route.path == "/kaowuFuwu") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 3 } });
        this.$router.push("/mobileKaowuFuwu");
      }
      if (this._isMobile() && this.$route.path == "/yingjian") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 4 } });
        this.$router.push("/mobileYingjian");
      }
      if (this._isMobile() && this.$route.path == "/shixun") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 5 } });
        this.$router.push("/mobileShixun");
      }
      if (!this._isMobile() && this.$route.path == "/mobileCultivate") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 0 } });
        this.$router.push("/cultivate");
      }
      if (!this._isMobile() && this.$route.path == "/mobileAppraise") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 1 } });
        this.$router.push("/appraise");
      }
      if (!this._isMobile() && this.$route.path == "/mobileSupervise") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 2 } });
        this.$router.push("/supervise");
      }
      if (!this._isMobile() && this.$route.path == "/mobileKaowuFuwu") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 3 } });
        this.$router.push("/kaowuFuwu");
      }
      if (!this._isMobile() && this.$route.path == "/mobileYingjian") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 4 } });
        this.$router.push("/yingjian");
      }
      if (!this._isMobile() && this.$route.path == "/mobileShixun") {
        // this.$router.push({ path: "/mobileProduct", query: { active: 5 } });
        this.$router.push("/shixun");
      }
      if (!this._isMobile() && this.$route.path == "/mobileProduct") {
        let active = 0;
        if (this.$route.query.active) {
          active = this.$route.query.active;
        }
        if (active == 0) {
          this.$router.push("/cultivate");
        }
        if (active == 1) {
          this.$router.push("/appraise");
        }
        if (active == 2) {
          this.$router.push("/supervise");
        }
        if (active == 3) {
          this.$router.push("/kaowuFuwu");
        }
        if (active == 4) {
          this.$router.push("/yingjian");
        }
        if (active == 5) {
          this.$router.push("/shixun");
        }
      }
      if (
        this._isMobile() &&
        (this.$route.path == "/custom" || this.$route.path == "/customDetail")
      ) {
        this.$router.push("/mobileCustom");
      }
      if (
        !this._isMobile() &&
        (this.$route.path == "/mobileCustom" ||
          this.$route.path == "/mobileCustomDetail")
      ) {
        this.$router.push("/custom");
      }
      if (this._isMobile() && this.$route.path == "/about") {
        this.$router.push("/mobileAbout");
      }
      if (!this._isMobile() && this.$route.path == "/mobileAbout") {
        this.$router.push("/about");
      }
      if (this._isMobile() && this.$route.path == "/contact") {
        this.$router.push("/mobileContact");
      }
      if (!this._isMobile() && this.$route.path == "/mobileContact") {
        this.$router.push("/contact");
      }
    },
    callBack(value) {
      this.trueOrfalse = value;
    },
  },
  mounted() {
    this.redirectPath();
  },
  watch: {
    $route(to, from) {
      this.redirectPath();
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
