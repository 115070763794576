import { render, staticRenderFns } from "./MobileKaowuFuwu.vue?vue&type=template&id=8e13b9c6&scoped=true&"
import script from "./MobileKaowuFuwu.vue?vue&type=script&lang=js&"
export * from "./MobileKaowuFuwu.vue?vue&type=script&lang=js&"
import style0 from "./MobileKaowuFuwu.vue?vue&type=style&index=0&id=8e13b9c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e13b9c6",
  null
  
)

export default component.exports