<template>
  <div class="mobileCustom">
    <template v-if="$route.path == '/mobileCustom'">
      <div class="header-bg">
        <p class="p1">客户案例</p>
        <p class="p2">给客户带去价值、为员工搭建平台、为社会促进发展</p>
      </div>
      <div class="content">
        <div class="content-box">
          <div
            class="content-box-flex-box"
            v-for="(item, index) in newsArr"
            :key="index"
            @click="goToCustomDetail(item)"
          >
            <div class="content-box-flex">
              <img
                v-if="item.cainPict"
                :src="
                  $path.path +
                  '/src/customerEtcFile/' +
                  item.cainPict.split(',')[0]
                "
                alt=""
              />
              <img v-else src="../assets/客户案例默认图片.png" alt="" />
              <div class="content-box-flex-bottom">
                <p class="p1">{{ item.cainTitle }}</p>
                <p class="p2">
                  {{ item.cainContent }}
                </p>
                <div class="content-box-flex-bottom-bottom">
                  <p class="p3">{{ item.cainTime }}</p>
                  <div class="content-box-flex-bottom-bottom-right">
                    <p class="p4">查看详情</p>
                    <img src="../assets/箭头.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :total="count"
            :page-size="pageSize"
            prev-text="上一页"
            next-text="下一页"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </template>
    <router-view v-else />
  </div>
</template>
<script>
import { GetCaseInfo, UpdateCaseInfoTotal } from "../api/home";
export default {
  name: "MobileCustom",
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      newsArr: [],
      count: 0,
    };
  },
  methods: {
    currentChange(e) {
      this.currentPage = e;
      this.getData();
    },
    goToCustomDetail(item) {
      let data = {
        cainIdJson: this.$path.Encrypt(item.cainId),
      };
      UpdateCaseInfoTotal(data)
        .then((res) => {
          this.$router.push({
            path: "/mobileCustomDetail",
            query: { cainId: item.cainId },
          });
        })
        .catch((err) => {});
    },
    getData() {
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
      };
      GetCaseInfo(params)
        .then((res) => {
          this.newsArr = res.list;
          this.count = res.count;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.mobileCustom {
  min-height: 100vh;
}
.header-bg {
  width: 100%;
  height: 200px;
  background: url(../assets/客户案例-banner.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-bg .p1 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 25px;
}
.header-bg .p2 {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}
.content {
  /* width: 100%; */
  /* padding: 0 10%; */
  padding: 0 16px;
}
.content .content-box {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.content .content-box .content-box-flex-box {
  width: 100%;
  margin-bottom: 16px;
}
.content .content-box .content-box-flex {
  width: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}
.content .content-box .content-box-flex img {
  width: 100%;
  height: 192px;
}
.content .content-box .content-box-flex .content-box-flex-bottom {
  width: calc(100% - 24px);
  padding: 20px 12px;
}
.content .content-box .content-box-flex .content-box-flex-bottom .p1 {
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.content .content-box .content-box-flex .content-box-flex-bottom .p2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin-top: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content
  .content-box
  .content-box-flex
  .content-box-flex-bottom
  .content-box-flex-bottom-bottom {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content
  .content-box
  .content-box-flex
  .content-box-flex-bottom
  .content-box-flex-bottom-bottom
  .p3 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}
.content
  .content-box
  .content-box-flex
  .content-box-flex-bottom
  .content-box-flex-bottom-bottom
  .content-box-flex-bottom-bottom-right {
  display: flex;
  align-items: center;
}
.content
  .content-box
  .content-box-flex
  .content-box-flex-bottom
  .content-box-flex-bottom-bottom
  .content-box-flex-bottom-bottom-right
  .p4 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(227, 25, 25, 1);
  margin-right: 8px;
}
.content
  .content-box
  .content-box-flex
  .content-box-flex-bottom
  .content-box-flex-bottom-bottom
  .content-box-flex-bottom-bottom-right
  img {
  width: 14px;
  height: 14px;
}
.pagination {
  margin: 0 auto;
  margin-top: 60px;
}
>>> .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
>>> .el-pager li {
  min-width: 0;
  width: 28px;
  color: #999;
}
>>> .el-pager li.active {
  color: #e31919;
  border: 1px solid #e31919;
  border-radius: 50%;
}
</style>
