<template>
  <div class="kaowuFuwu">
    <div class="content">
      <div class="content-title">技能人才评价考务服务</div>
      <div class="content-msg">
				为您提供技能人才评价全方位解决方案，我公司配备专业的项目管理师、计算机工程师和实施工程师，可在业务流程管理、信息化建设和团队建设上提供支持；公司与全国多所高校达成战略协同合作，可在题库建设、技能考评、技能培训等资源整合方面提供协助；公司作为高科技企业，拥有灵活的金融服务体系，可在费用支出、耗材采购等方面提供经费保障；公司作为民营企业，人员招聘、就业灵活，可在人力资源服务方面提供帮助。
			</div>
      <div class="img-box">
        <div class="img-box-inset">
          <img src="@/assets/人力资源.png" alt="" />
          <p>人力资源服务</p>
        </div>
        <div class="img-box-inset">
          <img src="@/assets/师资协调.png" alt="" />
          <p>师资协调服务</p>
        </div>
        <div class="img-box-inset">
          <img src="@/assets/经费保障.png" alt="" />
          <p>经费保障服务</p>
        </div>
      </div>
      <div class="img-box">
        <div class="img-box-inset">
          <img src="@/assets/耗材采购.png" alt="" />
          <p>耗材采购服务</p>
        </div>
        <div class="img-box-inset">
          <img src="@/assets/证书打印.png" alt="" />
          <p>证书打印服务</p>
        </div>
        <div class="img-box-inset">
          <img src="@/assets/项目管理.png" alt="" />
          <p>项目管理服务</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "KaowuFuwu",
};
</script>
<style scoped>
.content {
  /* padding: 0 10%; */
  width: 1280px;
  margin: 0 auto;
}
.content .content-title {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin: 60px 0;
}
.jianjie-title {
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: rgba(211, 26, 26, 1);
  margin: 60px 0;
}
.content .content-msg {
  text-indent: 2em;
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.content .img-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 60px 0;
}
.content .img-box .img-box-inset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content .img-box .img-box-inset img {
  width: 120px;
  height: 120px;
}
.content .img-box .img-box-inset p {
  font-size: 24px;
  line-height: 84px;
  color: rgba(51, 51, 51, 1);
}
</style>
